<c-modal-header>
  <h5 cModalTitle>
    Registration for {{workshopName ? workshopName : 'Workshop'}}</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <p>Enter the email address of the partner</p>
  <app-text-input [config]="inputEmailConfig" (onChange)="setInputValue($event)"
    (keyup.enter)="!inputEmailConfig.value ?  null : submit()">
  </app-text-input>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()" [disabled]="!inputEmailConfig.value">Submit</button>
</c-modal-footer>