import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { FormFieldModel, InputRadioModel } from 'src/app/_models/form-field.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-radio-input',
  standalone: true,
  imports: [FormsModule, FormModule, CommonModule],
  templateUrl: './radio-input.component.html',
  styleUrl: './radio-input.component.scss'
})
export class RadioInputComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  data!: InputRadioModel;

  ngOnInit(): void {
    this.data = this.config as InputRadioModel;
  }

  setNewValue(e: any) {
    this.onChange.emit({ formControlName: this.data.id, value: this.data.value });
  }
}
