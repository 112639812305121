<c-modal-header>
  <h5 cModalTitle>Submit Updates</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  This will cancel your previous submission (if there's any) and create a new approval request. Are you sure you want to
  continue? This action cannot be undone.

  <div class="form-group mt-3">
    <label cLabel for="changelog-input">Change logs</label>
    <textarea cFormControl id="changelog-input" rows="4" [(ngModel)]="changeLogs"></textarea>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()" [disabled]="!changeLogs">Yes, submit this content</button>
</c-modal-footer>