import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { RouterLink } from '@angular/router';
import { PaginationModule } from '@coreui/angular';

@Component({
  selector: 'app-list-pagination',
  standalone: true,
  imports: [PaginationModule, RouterLink, CommonModule],
  templateUrl: './list-pagination.component.html',
  styleUrl: './list-pagination.component.scss'
})

export class ListPaginationComponent implements OnChanges {
  @Input() currentPage !: number;
  @Input() totalPage !: number;
  @Output() onSetPage = new EventEmitter<number>();
  displayPage: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (changes) {
      this.displayPage = this.getPagination(this.totalPage, this.currentPage);
    }
  }

  setCurrentPage(page: number) {
    this.onSetPage.emit(page);
  }

  // Construct content pagination 
  getPagination(totalPage: number, currentPage: number): number[] {
    const range = 5;  // Number of pages to display around the current page
    let pages: number[] = [];
    let startPage: number;
    let endPage: number;

    // Show all pages if total pages <= range
    if (totalPage <= range) {
      return Array.from({ length: totalPage }, (_, i) => i + 1);
    }

    // Calculate start and end page
    startPage = Math.max(1, currentPage - Math.floor(range / 2));
    endPage = Math.min(totalPage, currentPage + Math.floor(range / 2));

    // Adjust start and end page if not enough space to display range
    if (endPage - startPage + 1 < range) {
      if (startPage === 1) {
        endPage = Math.min(totalPage, startPage + range - 1);
      } else if (endPage === totalPage) {
        startPage = Math.max(1, endPage - range + 1);
      }
    }

    // Add pages and ellipses
    if (startPage > 1) {
      pages.push(1, -1);
    }
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    if (endPage < totalPage) {
      pages.push(-1, totalPage);
    }

    return pages;
  }
}
