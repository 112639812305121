<c-modal-header>
  <h5 cModalTitle>{{data.title}}</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="my-3 p-4 bg-light border">
    <c-row class="align-items-center">
      <c-col [lg]="6">
        <div class="mb-3 mb-lg-0">
          <div class="data-label">No. of participants</div>
          <div class="data">{{dynamicTableBodyData.length}}
            {{data.participantLimit && data.participantLimit !== 0 ? "/"+data.participantLlimit:""}}
          </div>
        </div>
      </c-col>
      <c-col [lg]="6">
        <div class="text-lg-end"><button cButton color="primary" size="sm" class="m-1"
            (click)="exportWorkshopRegistrationList()">Export
            (.csv)</button>
          <button cButton color="primary" size="sm" class="m-1" (click)="addWorkshopParticipant()">Add
            participant</button>
        </div>

      </c-col>
    </c-row>
  </div>
  <app-list-table [tableSearch]="false" [tablePagination]="false" [tableHeadData]="tableHeadData"
    [tableBodyData]="dynamicTableBodyData"
    (resendWorkshopInvitationClick)="resendWorkshopInvitation($event)"></app-list-table>
</c-modal-body>