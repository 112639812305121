<c-modal-header>
  <h5 cModalTitle>Delete Partner</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  Are you sure you want to delete the following partner:

  <div class="my-3 p-4 bg-light border">
    <strong>{{first_name}} {{last_name}}</strong><br>
    <i class="text-break">{{email}}</i><br>
    <i>{{tier}}</i>
  </div>
  This action cannot be undone.
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="confirmDeleted()">Yes, delete this partner</button>
</c-modal-footer>