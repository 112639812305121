<c-modal-header>
  <h5 cModalTitle>
    Content Picker - {{getContentName(relationship.contentType)}}
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <!-- COMMENT FOR NOW -->
  <section class="section-search mb-4 pb-4 border-0 border-bottom border-light border-dashed">
    <c-input-group>
      <span cInputGroupText><svg cIcon class="me-1" name="cilMagnifyingGlass"></svg></span>
      <input cFormControl id="table-list-search-input" placeholder="Search" type="text" [formControl]="searchInput" />
    </c-input-group>
  </section>

  <!-- <section *ngIf="searchResults && searchInput.value"
    class="search-term mb-4 pb-4 border-0 border-bottom border-light border-dashed">
    <h6 class="mb-0 text-black">You searched for <strong>"{{searchInput.value}}"</strong></h6>
  </section> -->

  <!-- Notes: Hide filter if no search results -->
  <section class="filter mb-4 pb-4 border-0 border-bottom border-light border-dashed">
    <c-row class="justify-content-start justify-content-start align-items-end">
      <c-col xs="6">
        <app-select-menu-input [config]="inputLanguageConfig" (onChange)="setLanguage($event)">
        </app-select-menu-input>
      </c-col>
      <c-col *ngIf="adminAllowedLanguage.includes(1)" xs="6" class="text-end">
        <button cButton color="primary" (click)="onCreateContent()">
          <span class="icon-img me-1">
            <svg cIcon name="cilFileAdd"></svg>
          </span>
          New
        </button>
      </c-col>
    </c-row>
  </section>

  <!-- Loading Spinner -->
  <section *ngIf="isLoading" class="text-center">
    <span class="icon-img ">
      <icon-spinner></icon-spinner>
    </span>
  </section>

  <!-- Display if no results -->
  <section *ngIf="!isLoading && searchInput.value && dynamicTableBodyData.length === 0"
    class="search-term border-0 border-light border-dashed">
    <i>No results. Try adjust your search terms</i>
  </section>

  <section *ngIf="!isLoading && dynamicTableBodyData.length > 0" class="content-list">
    <app-list-table [tableSearch]="false" [tableHeadData]="tableHeadData" [tableBodyData]="dynamicTableBodyData"
      [totalAsset]="totalAsset" (onSelect)="setSelection($event)"
      (onUnselect)="setUnselection($event)"></app-list-table>
  </section>

  <div *ngIf="!isLoading && dynamicTableBodyData.length > 0" class="pagination d-block">
    <c-row>
      <c-col xl="8">
        <app-list-pagination [currentPage]="currentPage" [totalPage]="totalPage"
          (onSetPage)="setCurrentPage($event)"></app-list-pagination>
      </c-col>

      <c-col xl="4">
        <div class="form-group d-flex flex-wrap align-items-center justify-content-center justify-content-xl-end">
          <label cLabel for="list-filter-language" class="me-2 mb-0">Show</label>
          <select cSelect id="list-filter-language" [(ngModel)]="showAsset"
            (ngModelChange)="currentPage = 1; getOrSearch()" class="w-auto" aria-label="Language">
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="100">100</option>
          </select>
        </div>
      </c-col>
    </c-row>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" size="sm" class="btn-select-image me-2 mb-2" (click)="dismissModal()">
    Done
  </button>
</c-modal-footer>