import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { IconModule } from '@coreui/icons-angular';
import { GridModule, FormModule, TableModule, ButtonModule, PaginationModule } from '@coreui/angular';
import { ToastrService } from 'ngx-toastr';
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, distinctUntilChanged } from 'rxjs';

@Component({
  selector: 'app-list-table',
  standalone: true,
  imports: [GridModule, FormModule, FormsModule, TableModule, IconModule, ButtonModule, CommonModule, PaginationModule, RouterLink, ReactiveFormsModule],
  templateUrl: './list-table.component.html',
  styleUrl: './list-table.component.scss'
})
export class ListTableComponent implements OnInit {
  @Input() assetType!: string;
  @Input() searchTerms!: string;
  @Input() tableSearch!: boolean;
  @Input() tablePagination!: boolean;
  @Input() tableHeadData!: {
    value: string;
    class?: string
  }[];
  @Input() totalAsset !: number;
  @Output() onPagination = new EventEmitter<{ page: number, asset: number }>();
  @Output() onSelect = new EventEmitter<string>();
  @Output() onUnselect = new EventEmitter<string>();
  @Output() onSearch = new EventEmitter<string>();
  @Output() onResendInvitationClick = new EventEmitter<string>();

  currentPage: number = 1;
  selectedValue: string[] = [];
  showAsset: number = 15;
  totalPage: number = 1;
  searchInput = new FormControl('');

  constructor(
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.searchInput.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      this.onSearch.emit(value ?? '');
    });
  }

  @Input() tableBodyData!: TableBodyDataModel[][];

  @Output() viewWorkshopRegistrationClick: EventEmitter<any> = new EventEmitter();
  @Output() resendWorkshopInvitationClick: EventEmitter<any> = new EventEmitter();
  @Output() downloadBapTopicItemCompletionListClick: EventEmitter<any> = new EventEmitter();

  copyLink() {
    this.toastr.success('Link copied to clipboard');
  }

  onSelectClick(index: number) {
    this.selectedValue.push(this.tableBodyData[index][0].value ?? '');
    const actionObj = this.tableBodyData[index].find(data => data.type === 'ACTION_BUTTONS');
    const selectFileObj = actionObj?.buttons?.find(data => data.type === 'SELECT_FILE'); // Select file button
    if (selectFileObj) {
      if (this.assetType === 'IMAGE') {
        // Unselect all images
        this.tableBodyData.forEach((data: any) => {
          data[3].buttons.forEach((button: any) => {
            if (button.type === 'SELECT_FILE') {
              button.selected = false;
            }
          });
        })
      }
      selectFileObj.selected = true;
    }
    this.onSelect.emit(this.tableBodyData[index][0].identifier ?? this.tableBodyData[index][0].value);
  }

  onUnselectClick(index: number) {
    const actionObj = this.tableBodyData[index].find(data => data.type === 'ACTION_BUTTONS');
    const selectFileObj = actionObj?.buttons?.find(data => data.type === 'SELECT_FILE'); // Select file button
    if (selectFileObj) {
      selectFileObj.selected = false;
    }
    this.onUnselect.emit(this.tableBodyData[index][0].identifier ?? this.tableBodyData[index][0].value);
  }

  onResendAdminInvitationClick(index: number) {
    this.onResendInvitationClick.emit(this.tableBodyData[index][0].identifier);
  }

  onViewWorkshopRegistrationClick(index: number) {
    this.viewWorkshopRegistrationClick.emit(this.tableBodyData[index][0].identifier);
  }

  onResendWorkshopInvitationClick(index: number) {
    this.resendWorkshopInvitationClick.emit(index);
  }

  onDownloadBapTopicItemCompletionListClick() {
    this.downloadBapTopicItemCompletionListClick.emit();
  }
}
