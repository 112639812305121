import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class AssetService {
    private sortArr: { sort: string, order: string }[] = [
        {
            sort: 'created_at',
            order: 'desc'
        },
        {
            sort: 'created_at',
            order: 'asc'
        },
        {
            sort: 'name',
            order: 'asc'
        },
        {
            sort: 'name',
            order: 'desc'
        }
    ]

    constructor(
        private http: HttpClient
    ) { }

    // Create document
    addDocument(path: string = '/', file: File, tags: string): Observable<any> {
        let formData = new FormData();
        let queryParams = new HttpParams();
        formData.append('file', file);
        queryParams = queryParams.append('path', path ?? '/');
        if (tags) {
            tags.replace(/ /g, '').split(',').forEach(tag => {
                queryParams = queryParams.append('tags', tag);
            });
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/document`, formData, { params: queryParams }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Create Image
    addImage(path: string = '/', file: File, tags: string): Observable<any> {
        let formData = new FormData();
        let queryParams = new HttpParams();
        formData.append('file', file);
        queryParams = queryParams.append('path', path ?? '/');
        if (tags) {
            tags.replace(/ /g, '').split(',').forEach(tag => {
                queryParams = queryParams.append('tags', tag);
            });
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/image`, formData, { params: queryParams }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Create folder
    addFolder(folderName: string, folderPath: string = '/'): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            name: folderName,
            path: folderPath
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/folder`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Update document
    editDocument(id: string, name: string, tags: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            name: name,
            tags: tags.split(',').filter(item => item !== '')
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/document/${id}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Update image
    editImage(id: string, name: string, tags: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            name: name,
            tags: tags.split(',').filter(item => item !== '')
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/image/${id}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Update folder
    editFolder(id: string, name: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            name: name,
        }

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/folder/${id}`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Remove document/image/folder
    deleteAsset(id: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/${id}`, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Get assets
    getAssetDetail(path: string = '/', size: number, page: number, sorting: string, excludeFolder: boolean, fileType?: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        let queryParams = new HttpParams();
        queryParams = queryParams.append("path", path);
        queryParams = queryParams.append("size", size);
        queryParams = queryParams.append("page", page);
        queryParams = queryParams.append("sort", this.sortArr[Number.parseInt(sorting)].sort);
        queryParams = queryParams.append("order", this.sortArr[Number.parseInt(sorting)].order);
        fileType ? (queryParams = queryParams.append("fileType", fileType)) : null;
        queryParams = queryParams.append("excludeFolder", excludeFolder);

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}`, { headers: header, params: queryParams }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Get asset details by Id
    getAssetDetailById(id: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/${id}`, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Check asset usage by Id
    checkAssetUsageById(id: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/${id}/check-usage`, { headers: header }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Search assets
    searchAsset(keyword: string, size: number, page: number, sorting: string, fileType?: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        let queryParams = new HttpParams();
        queryParams = queryParams.append("keyword", keyword);
        queryParams = queryParams.append("size", size);
        queryParams = queryParams.append("page", page);
        queryParams = queryParams.append("sort", this.sortArr[Number.parseInt(sorting)].sort);
        queryParams = queryParams.append("order", this.sortArr[Number.parseInt(sorting)].order);
        fileType ? (queryParams = queryParams.append("fileType", fileType)) : null;

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.assetPath}/search`, { headers: header, params: queryParams }).pipe(
            map(res => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    return res;
                }
            })
        )
    }
}