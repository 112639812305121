import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-logout-confirmation-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule],
  templateUrl: './logout-confirmation-modal.component.html',
  styleUrl: './logout-confirmation-modal.component.scss'
})
export class LogoutConfirmationModalComponent {
  @Input() modal: any;

  constructor(
    private authService: AuthService
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  confirmLogout() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.authService.userServerLogout();
    });
  }
}
