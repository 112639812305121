import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-add-language-unsaved-changes-notice-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule],
  templateUrl: './add-language-unsaved-changes-notice-modal.component.html',
  styleUrl: './add-language-unsaved-changes-notice-modal.component.scss'
})
export class AddLanguageUnsavedChangesNoticeModalComponent {
  @Input() modal: any;

  constructor(
    private router: Router
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }
}
