<div class="d-flex justify-content-center justify-content-xl-start">
    <c-pagination role="button">
        <li cPageItem [disabled]="currentPage === 1"
            (click)="currentPage === 1 ? null : setCurrentPage(currentPage - 1)">
            <a cPageLink aria-label="Previous" [routerLink]><span aria-hidden="true">&laquo;</span></a>
        </li>
        <li *ngFor="let page of displayPage" cPageItem [active]="page === currentPage" [disabled]="page === -1"
            (click)="page === -1 || page === currentPage ? null : setCurrentPage(page)">
            <a cPageLink [routerLink]>{{page === -1 ? '...' : page}}</a>
        </li>
        <li cPageItem [disabled]="currentPage === totalPage"
            (click)="currentPage === totalPage ? null : setCurrentPage(currentPage + 1)">
            <a cPageLink aria-label="Next" [routerLink]><span aria-hidden="true">&raquo;</span></a>
        </li>
    </c-pagination>
</div>