<c-modal-header>
  <h5 cModalTitle>Create Event Video</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="mb-4">
    <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
  </div>
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Information</h3>
      <p class="mb-0">Set-up the information for this event video</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputContentIDConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="list-filter-active-period">Publish Period
          <div class="form-sublabel">
            Specify the active dates for this content. Content will only be published and visible during this period.
          </div>
        </label>
        <c-form-check>
          <input cFormCheckInput id="same-as-topic-checkbox" type="checkbox">
          <label cFormCheckLabel for="same-as-topic-checkbox">Same as parent content</label>
        </c-form-check>

        <app-datetimerangepicker-input [config]="inputPublishPeriodConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTopSubtitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputCoverImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputCoverDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-datetimerangepicker-input [config]="inputDateTimeConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputVideoURLConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputVideoDurationConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-person-selector [config]="inputAddPartnersConfig" (selectButtonClick)="selectPersonClick()"
          (createButtonClick)="createPersonClick()">
        </app-person-selector>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTagsConfig">
        </app-text-input>
      </div>
    </div>
  </div>

  <div class="card border-0 light-shadow">
    <div class="card-header bg-gray p-4">
      <h3>Bookmark information</h3>
      <p class="mb-0">Set-up bookmark information, this information will be shown in your bookmarks</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputBookmarkTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputBookmarkDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputBookmarkCoverImageConfig">
        </app-image-input>
      </div>
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="dismissModal()">Submit</button>
</c-modal-footer>