import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { TextInputComponent } from '../../forms';
import { InputTextModel } from 'src/app/_models/form-field.model';
import { InputType } from 'src/app/enum';
import { AssetService } from 'src/app/_services/asset.service';
import { ToastrService } from 'ngx-toastr';
import { ContentService } from 'src/app/_services/content.service';

@Component({
  selector: 'app-delete-folder-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, TextInputComponent],
  templateUrl: './delete-folder-modal.component.html',
  styleUrl: './delete-folder-modal.component.scss'
})
export class DeleteFolderModalComponent {
  @Input() modal: any;
  @Input() asset_id!: string;
  @Input() asset_in_use!: boolean;
  @Output() onRemoved = new EventEmitter();

  deleteString: string = '';
  showError: boolean = false;
  inputEmailConfig: InputTextModel = {
    id: 'delete-confirmation-input',
    name: 'delete-confirmation-input',
    label: '',
    placeholder: 'delete',
    max_length: 100,
    value: this.deleteString,
    disabled: false,
    type: InputType.Text,
    required: false
  };

  constructor(
    private assetService: AssetService,
    private toastr: ToastrService,
    private contentService: ContentService,
  ) { }

  confirmDeleteInUseAsset() {
    if (this.deleteString === 'delete') {
      this.confirmDeleted();
    } else {
      this.showError = true;
    }
  }

  confirmDeleted() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.contentService.openFullscreenSpinnerModal();
      this.assetService.deleteAsset(this.asset_id).subscribe({
        next: res => {
          this.contentService.spinnerModalRef.dismiss();
          this.toastr.success('Folder Deleted!');
          this.onRemoved.emit();
        }
      })
    });
  }

  dismissModal() {
    this.modal.dismiss();
  }

  setDeleteValue(val: any) {
    this.showError = false;
    this.deleteString = val.value;
  }
}
