<div class="date-range-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}}<span class="text-danger" *ngIf="data.required">*</span>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>

  <div class="input-wrapper position-relative">
    <input [id]="data.id" [(ngModel)]="data.value" (ngModelChange)="data.error = []; setNewValue($event)" cFormControl
      type="text" id="list-filter-active-period" [owlDateTimeTrigger]="pickerTemplate" [owlDateTime]="pickerTemplate"
      [selectMode]="data.picker_mode_single ? 'single' : 'range'" [placeholder]="data.placeholder" autocomplete="off"
      [disabled]="data.disabled === undefined ? false : data.disabled"
      [ngClass]="{'border-danger': data.error !== undefined && data.error.length > 0 && data.error[0] !== ''}" />
    <button *ngIf="data.value.length > 0 && !data.disabled" cButton color="danger"
      class="btn-xs btn-circle btn-clear-input" (click)="data.value = []; data.error = []; setNewValue($event)"><i
        class="cil-x"></i></button>
  </div>

  <owl-date-time #pickerTemplate [hour12Timer]="true" [disabled]="data.disabled"
    [pickerType]="data.calendar_only ? 'calendar':'both'"></owl-date-time>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
  <div *ngIf="data.error && data.error.includes('range')" class="form-error mt-2">{{data.error_message}}</div>
  <div *ngIf="data.error && data.error.includes('arrayLengthInvalid')" class="form-error mt-2">From and To datetime are
    both required, please choose again.
  </div>

</div>