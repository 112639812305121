<div class="left-panel">
    <ng-container *ngFor="let item of data">
        <div *ngIf="item.showSection !== false" class="card border-0 light-shadow mb-4">
            <div *ngIf="item.header.length > 0" class="card-header bg-gray p-4">
                <div *ngFor="let headerItem of item.header">
                    <app-line-divider *ngIf="headerItem.type === 'LINEDIVIDER' && headerItem.showField !== false"
                        [config]="headerItem.data!!"></app-line-divider>
                </div>
            </div>

            <div *ngIf="item.body.length > 0" class="card-body p-4">
                <ng-container *ngFor="let bodyItem of item.body">
                    <div *ngIf="bodyItem.showField !== false" class="form-group">

                        <!-- Text Input -->
                        <app-text-input *ngIf="bodyItem.type === 'TEXT'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)">
                        </app-text-input>

                        <!-- Text with Button Input (Static) -->
                        <app-text-input-with-button *ngIf="bodyItem.type === 'TEXTBUTTON'"
                            [config]="bodyItem.data!!"></app-text-input-with-button>

                        <!-- Textarea Input -->
                        <app-textarea-input *ngIf="bodyItem.type === 'TEXTAREA'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)">
                        </app-textarea-input>

                        <!-- Text Editor Input --> <!-- COME BACK AGAIN, NOT DONE YET -->
                        <app-text-editor *ngIf="bodyItem.type === 'TEXTEDITOR'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)"></app-text-editor>

                        <!-- Radio Input -->
                        <app-radio-input *ngIf="bodyItem.type === 'RADIO'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)">
                        </app-radio-input>

                        <!-- Image Input --> <!-- COME BACK AGAIN, NOT DONE YET -->
                        <app-image-input *ngIf="bodyItem.type === 'IMAGE'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)">
                        </app-image-input>

                        <!-- Checkbox Input -->
                        <app-checkbox-input *ngIf="bodyItem.type === 'CHECKBOX'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)"></app-checkbox-input>

                        <!-- Select Input -->
                        <app-select-menu-input *ngIf="bodyItem.type === 'SELECT'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)"></app-select-menu-input>

                        <!-- Select with Search Input -->
                        <app-select-menu-with-search-input *ngIf="bodyItem.type === 'SELECTSEARCH'"
                            [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)"></app-select-menu-with-search-input>

                        <!-- DateTime Input -->
                        <app-datetimerangepicker-input *ngIf="bodyItem.type === 'DATETIME'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)">
                        </app-datetimerangepicker-input>

                        <!-- Relationship::Content Input -->
                        <app-content-selector *ngIf="bodyItem.type === 'RELATIONSHIP' && bodyItem.content === 'CONTENT'"
                            [config]="bodyItem.data!!" (onChange)="setFormValue($event)">
                        </app-content-selector>

                        <!-- Relationship::Person Input -->
                        <app-person-selector *ngIf="bodyItem.type === 'RELATIONSHIP' && bodyItem.content === 'PERSON'"
                            [config]="bodyItem.data!!" (onChange)="setFormValue($event)">
                        </app-person-selector>

                        <!-- Relationship::Single Content Input -->
                        <app-single-content-selector
                            *ngIf="bodyItem.type === 'RELATIONSHIP' && bodyItem.content === 'SINGLECONTENT'"
                            [config]="bodyItem.data!!" (onChange)="setFormValue($event)">
                        </app-single-content-selector>

                        <!-- Relationship::Document Input -->
                        <app-document-selector
                            *ngIf="bodyItem.type === 'RELATIONSHIP' && bodyItem.content === 'DOCUMENT'"
                            [config]="bodyItem.data!!" (onChange)="setFormValue($event)">
                        </app-document-selector>

                        <!-- Video Input -->
                        <app-video-input *ngIf="bodyItem.type === 'VIDEO'" [config]="bodyItem.data!!"
                            (onChange)="setFormValue($event)"></app-video-input>
                    </div>
                </ng-container>
            </div>
        </div>
    </ng-container>

</div>