<c-modal-header>
    <h5 cModalTitle>Create {{contentTypeName}}</h5>
    <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
    <div class="mb-4">
        <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
    </div>

    <app-form *ngIf="this.firstFormArray.length > 0" [data]="this.firstFormArray"
        (onChange)="setMainFormValue($event)"></app-form>

    <app-form *ngIf="this.secondFormArray.length > 0" [data]="this.secondFormArray"
        (onChange)="setSidePanelFormValue($event)"></app-form>

</c-modal-body>
<c-modal-footer>
    <button cButton color="primary" (click)="saveDraft()">Submit</button>
</c-modal-footer>