import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-cancel-updates-confirmation-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule],
  templateUrl: './cancel-updates-confirmation-modal.component.html',
  styleUrl: './cancel-updates-confirmation-modal.component.scss'
})
export class CancelUpdatesConfirmationModalComponent {
  @Input() modal: any;
  @Input() event_session!: string;
  @Output() onConfirm = new EventEmitter<void>();

  dismissModal() {
    this.modal.dismiss();
  }

  confirmCancellation() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit();
    });
  }
}
