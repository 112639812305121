<c-modal-header>
  <h5 cModalTitle>
    {{hasPublished? 'Cancel Update Submission': 'Cancel Submission'}}
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  This will convert your {{hasPublished ? 'update submission': 'submission'}} back to draft mode, are you sure you want
  to continue?
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="confirmCancellation()">Yes, cancel this
    {{hasPublished ? 'update submission': 'submission'}}</button>
</c-modal-footer>