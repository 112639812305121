import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { CreateWorkshopModalComponent, FullscreenSpinnerComponent, SelectContentModalComponent } from '../';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent, TextareaInputComponent, ImageInputComponent, RadioInputComponent, SelectMenuWithSearchInputComponent, TextEditorComponent, DatetimerangepickerInputComponent, ContentSelectorComponent } from "../../../components/forms";

@Component({
  selector: 'app-create-bap-topic-item-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, FormModule, TextInputComponent, TextareaInputComponent, ImageInputComponent, RadioInputComponent, SelectMenuWithSearchInputComponent, TextEditorComponent, DatetimerangepickerInputComponent, ContentSelectorComponent],
  templateUrl: './create-bap-topic-item-modal.component.html',
  styleUrl: './create-bap-topic-item-modal.component.scss'
})
export class CreateBapTopicItemModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  // Form input - Content ID
  inputContentIDConfig = {
    id: 'content-id-input',
    name: 'content-id-input',
    label: 'Content ID',
    sublabel: 'Create a unique ID for this content. Max. 50 characters. E.g. coffee-stories-championship-day-1',
    placeholder: 'Enter here',
    max_length: 150,
    value: 'learning-lesson-xxx',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputPublishPeriodConfig = {
    id: 'publish-period-input',
    name: 'publish-period-input',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Type
  inputTypeConfig = {
    name: 'type-input',
    label: 'Type',
    sublabel: 'Type of the content. Select one.',
    inline_options: true,
    value: '',
    disabled: false,
    error_message: '',
    options: [
      { id: 'type-article', label: 'Article' },
      { id: 'type-flash-card', label: 'Flash card' },
      { id: 'type-workshop', label: 'Workshop' }
    ]
  };

  // Form input - Link Learning topic
  inputLinkTopicConfig = {
    id: 'link-topic-input',
    name: 'link-topic-input',
    label: 'BAP Topic',
    sublabel: 'Link to a BAP topic',
    placeholder: 'Select a topic',
    disabled: false,
    error_message: '',
    options: [
      { _id: 'topic-1', value: 'Brew and Craft' },
      { _id: 'topic-2', value: 'Roast and Blend' }
    ]
  };

  // Form input -Title
  inputTitleConfig = {
    id: 'title-input',
    name: 'title-input',
    label: 'Title',
    sublabel: 'Title of the content. Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: 'Please complete this information'
  };

  // Form input - Description
  inputDescriptionConfig = {
    id: 'description-input',
    name: 'description-input',
    label: 'Description',
    sublabel: 'A short paragraph as the introduction. Max 150 characters',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Cover image
  inputCoverImageConfig = {
    type: 'COVER_IMAGE',
    id: 'cover-image-input',
    name: 'cover-image-input',
    label: 'Cover image',
    sublabel: 'Image displayed in the listing page',
    value: '',
    disabled: false
  };

  // Form input - Mobile Banner image
  inputMobileBannerImageConfig = {
    type: 'BANNER_IMAGE_MOBILE',
    id: 'banner-image-input',
    name: 'banner-image-input',
    label: 'Banner (Mobile screen, optional)',
    sublabel: 'Select an image for the banner in mobile screen sizes (Screen size <768px).',
    value: '',
    disabled: false
  };

  // Form input - Mobile Banner image
  inputLargeBannerImageConfig = {
    type: 'BANNER_IMAGE_LARGE',
    id: 'banner-image-input',
    name: 'banner-image-input',
    label: 'Banner (Large screen)',
    sublabel: 'Select an image for the banner in large screen sizes (Screen size >768px).',
    value: '',
    disabled: false
  };

  // Form input - Modal image - mobile
  inputCardMobileBannerImageConfig = {
    type: 'MODAL_IMAGE_MOBILE',
    id: 'mobile-image-input',
    name: 'mobile-image-input',
    label: 'Image (Mobile screen, optional)',
    sublabel: 'Select an image for the flash card in mobile screen sizes (Screen size <768px).',
    value: '',
    disabled: false
  };

  // Form input - Modal image
  inputCardLargeBannerImageConfig = {
    type: 'MODAL_IMAGE_LARGE',
    id: 'image-input',
    name: 'image-input',
    label: 'Image',
    sublabel: 'Select an image for the flash card in large screen sizes (Screen size >768px).',
    value: '',
    disabled: false
  };

  // Form input - Article Body content
  inputArticleBodyContentConfig = {
    id: 'article-body-content-input',
    name: 'article-body-content-input',
    label: 'Body content',
    sublabel: 'Create the body contents of the article',
    disabled: false,
    error_message: ''
  };

  // Form input - Flash card Body content
  inputFlashCardBodyContentConfig = {
    id: 'flash-card-body-content-input',
    name: 'flash-card-body-content-input',
    label: 'Body content',
    sublabel: 'Create the body contents of the flash card',
    disabled: false,
    error_message: ''
  };

  // Form input - Tags
  inputTagsConfig = {
    id: 'tags-input',
    name: 'tags-input',
    label: 'Tags',
    sublabel: 'Add tagging for the content, may enter multiple. Separate each tag with comma. E.g. Coffee, Cold Brew, Frappuccino',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  selectedWorkshop = [
    {
      id: 'workshop-1',
      name: 'Workshop name',
      tags: 'Workshop, 2024, BAP',
      img_url: '/assets/images/placeholder/img-placeholder-image-16by9.webp',
      status: 'PENDING_APPROVAL'
    }
  ];

  // Form add workshop
  inputAddWorkshopConfig = {
    id: 'add-workshop-input',
    label: 'Add / create workshop',
    sublabel: 'Add workshop from the list, or create a new workshop',
    disabled: false,
    error_message: '',
    select_button_label: 'Select workshop',
    create_button_label: 'Create workshop',
    selected_data: this.selectedWorkshop
  };

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }

  handleSelectButtonClick() {
    this.openSelectContentModal('SELECT_CONTENT');
  }

  handleCreateButtonClick() {
    this.openCreateLearningLessonModal('CREATE_WORKSHOP');
  }

  openCreateLearningLessonModal(event_session: string) {
    const modalRef = this.modalService.open(CreateWorkshopModalComponent, {
      centered: true,
      windowClass: 'create-content-modal',
      modalDialogClass: 'modal-fullscreen-md-down',
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
  }

  openSelectContentModal(event_session: string) {
    const modalRef = this.modalService.open(SelectContentModalComponent, {
      centered: true,
      windowClass: 'select-content-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
  }
}
