import { Component, Input, OnInit } from '@angular/core';
import { ModalModule, ButtonModule, GridModule } from '@coreui/angular';
import { AddWorkshopParticipantModalComponent, FullscreenSpinnerComponent } from '../';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListTableComponent } from "../../list-table/list-table.component";
import { ToastrService } from 'ngx-toastr';
import { ContentService } from 'src/app/_services/content.service';
import { ErrorService } from 'src/app/_services/error.service';
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { PartnerService } from 'src/app/_services/partner.service';
import { ReportService } from 'src/app/_services/report.service';

@Component({
  selector: 'app-view-workshop-registration-modal',
  standalone: true,
  templateUrl: './view-workshop-registration-modal.component.html',
  styleUrl: './view-workshop-registration-modal.component.scss',
  imports: [ModalModule, ButtonModule, CommonModule, ListTableComponent, GridModule]
})
export class ViewWorkshopRegistrationModalComponent implements OnInit {
  @Input() modal: any;
  @Input() data!: any;

  tableHeadData = [
    {
      'value': 'Name',
      'class': 'col-wide-md sticky'
    },
    {
      'value': 'Email',
      'class': 'col-wide-md'
    },
    {
      'value': 'Date & time registered',
      'class': 'col-wide-lg'
    },
    {
      'value': 'Actions',
      'class': 'col-wide-md'
    }
  ];

  workshopRegisteredData: any = [];
  dynamicTableBodyData: TableBodyDataModel[][] = [];

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private contentService: ContentService,
    private partnerService: PartnerService,
    private errorService: ErrorService,
    private reportService: ReportService
  ) { }

  ngOnInit(): void {
    this.partnerService.getWorkshopRegistration(this.data.identifier).subscribe({
      next: res => {
        if (!res.data) {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          return;
        }

        this.workshopRegisteredData = res.data;
        res.data.forEach((person: any) => {
          this.dynamicTableBodyData.push([
            {
              'type': 'TEXT',
              'value': person.name,
              'class': 'sticky'
            },
            {
              'type': 'TEXT',
              'value': person.email
            },
            {
              'type': 'TEXT',
              'value': this.contentService.formatDateTime(person.registration_date)
            },
            {
              'type': 'ACTION_BUTTONS',
              'buttons': [
                {
                  'type': 'RESEND_WORKSHOP_REGISTRATION',
                  // 'url': '#'
                }
              ]
            }
          ],)
        });
      }
    })
  }

  dismissModal() {
    this.modal.dismiss();
  }

  resendWorkshopInvitation(index: string) {
    this.contentService.openFullscreenSpinnerModal();
    this.partnerService.resendWorkshopRegistrationEmail(this.data.identifier, this.workshopRegisteredData[index].email).subscribe({
      next: res => {
        this.contentService.spinnerModalRef.dismiss();
        if (!res.data) {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          return;
        }
        this.toastr.success('Email sent');
      }
    });
  }

  exportWorkshopRegistrationList() {
    this.contentService.openFullscreenSpinnerModal();
    this.reportService.getWorkshopRegistration(this.data.identifier, this.data.title).subscribe({
      next: res => {
        this.contentService.spinnerModalRef.dismiss();
      }
    })
  }

  addWorkshopParticipant() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.openAddWorkshopParticipantModal();
    });
  }

  openAddWorkshopParticipantModal() {
    const modalRef = this.modalService.open(AddWorkshopParticipantModalComponent, {
      centered: true,
      windowClass: 'add-workshop-participant-modal',
      scrollable: true
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.workshopName = this.data.title;
    modalRef.componentInstance.onAdd.subscribe((val: string) => {
      this.contentService.openFullscreenSpinnerModal();
      this.partnerService.addWorkshopParticipant(val, this.data.identifier).subscribe({
        next: res => {
          this.contentService.spinnerModalRef.dismiss();
          if (!res.data) {
            this.errorService.openGenericErrorModal(res.error, res.error_code);
            return;
          }
          this.toastr.success('New workshop participant added');
        }
      })
    })
  }
}
