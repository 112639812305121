import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormFieldModel, LineDividerModel } from 'src/app/_models/form-field.model';

@Component({
  selector: 'app-line-divider',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './line-divider.component.html',
  styleUrl: './line-divider.component.scss'
})
export class LineDividerComponent implements OnInit {
  @Input() config!: FormFieldModel;
  data!: LineDividerModel

  ngOnInit(): void {
    this.data = this.config as LineDividerModel;
  }
}
