import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-generic-error-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule],
  templateUrl: './generic-error-modal.component.html',
  styleUrl: './generic-error-modal.component.scss'
})
export class GenericErrorModalComponent {

  @Input() modal: any;
  @Input() errorCode!: string;
  @Input() errorMessage!: string;

  constructor() { }

  dismissModal() {
    this.modal.dismiss();
  }
}
