import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-delete-content-confirmation-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule],
  templateUrl: './delete-content-confirmation-modal.component.html',
  styleUrl: './delete-content-confirmation-modal.component.scss'
})
export class DeleteContentConfirmationModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;
  @Output() onConfirm = new EventEmitter<void>();

  dismissModal() {
    this.modal.dismiss();
  }

  confirmDelete() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit();
    });
  }
}
