import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule, CardModule } from '@coreui/angular';
import { IconModule } from '@coreui/icons-angular';
import { fadeInOut } from '../../../../animations';

@Component({
  selector: 'app-upload-image-process',
  standalone: true,
  imports: [ModalModule, ButtonModule, CardModule, IconModule],
  templateUrl: './upload-image-process.component.html',
  styleUrl: './upload-image-process.component.scss',
  animations: [fadeInOut]
})
export class UploadImageProcessComponent {
  @Input() imageToBeUploaded!: string;
  @Input() imageFilename!: string;

  @Output() dismissModalClick = new EventEmitter<void>();
  @Output() goToProcessImageClick = new EventEmitter<void>();

  dismissModal() {
    this.dismissModalClick.emit();
  }
}
