import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-delete-partner-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule],
  templateUrl: './delete-partner-modal.component.html',
  styleUrl: './delete-partner-modal.component.scss'
})
export class DeletePartnerModalComponent {
  @Input() modal: any;
  @Input() first_name!: string;
  @Input() last_name!: string;
  @Input() email !: string;
  @Input() tier!: string;
  @Output() onConfirm = new EventEmitter<void>();

  dismissModal() {
    this.modal.dismiss();
  }

  confirmDeleted() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit();
    });
  }
}
