import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environment/environment";
import { Observable, map } from "rxjs";
import { ToastrService } from "ngx-toastr";
import { ContentService } from "./content.service";
import { ErrorService } from "./error.service";

@Injectable({
    providedIn: 'root',
})
export class ReportService {
    private csvHeader = {
        headers: new HttpHeaders({
            'Accept': 'text/csv'
        }),
        responseType: 'blob' as 'json'
    };

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private contentService: ContentService,
        private errorService: ErrorService
    ) { }

    // Download .csv
    downloadCSVFile(blob: Blob, fileName: string, successMsg: string) {
        const contentType = blob.type;
        if (contentType.includes('text/csv') || contentType.includes('application/csv')) {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${fileName}.csv`;
            a.click();
            window.URL.revokeObjectURL(url);
            this.toastr.success(successMsg);
        } else {
            this.errorService.openGenericErrorModal('The response is not a CSV file.');
        };
    }

    // Get workshop listing
    getWorkshopList(search?: string): Observable<any> {
        let queryParams = new HttpParams();
        search ? queryParams = queryParams.append('query', search) : null;

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/workshop-list`, search ? { params: queryParams } : undefined).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get video listing (Competition, event, learnings, workshop )
    getVideoList(search?: string): Observable<any> {
        let queryParams = new HttpParams();
        search ? queryParams = queryParams.append('query', search) : null;

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/video-list`, search ? { params: queryParams } : undefined).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get workshop listing
    getWorkshopRegistration(id: string, fileName: string): Observable<any> {
        return this.http.get<Blob>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/workshop-registration/${id}`, this.csvHeader).pipe(
            map(res => {
                this.downloadCSVFile(res, `${fileName} Registration List`, 'Report generated');
            })
        );
    }

    // Get partner retention (Blob)
    getPartnerRetention(type: string, rank: string, date: Date[]): Observable<any> {
        let body = {
            type: type,
            start_date: date[0],
            end_date: date[1],
            ...(rank !== 'ALL' && { rank })
        };

        return this.http.post<Blob>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/partner-retention`, body, this.csvHeader).pipe(
            map(res => {
                this.downloadCSVFile(res, `${rank} ${type} Retention Report`, 'Report generated');
            })
        );
    }

    // Get partner data (Blob)
    getPartnerData(rank: string, date: Date[]): Observable<any> {
        let body = {
            start_date: date[0],
            end_date: date[1],
            ...(rank !== 'ALL' && { rank })
        };

        return this.http.post<Blob>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/partner-data`, body, this.csvHeader).pipe(
            map(res => {
                this.downloadCSVFile(res, `${rank} Partner Data Report`, 'Report generated');
            })
        );
    }

    // Get content engagement rate (Blob)
    getContentEngagementRate(languageId: string, date: Date[]): Observable<any> {
        let body = {
            start_date: date[0],
            end_date: date[1],
            ...(languageId !== 'ALL' && { cms_language_id: languageId })
        };

        return this.http.post<Blob>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/content-engagement-rate`, body, this.csvHeader).pipe(
            map(res => {
                this.downloadCSVFile(res, `(${languageId === 'ALL' ? 'ALL' : this.contentService.getLanguageName(Number.parseInt(languageId))})Content Engagement Rate Report`, 'Report generated');
            })
        );
    }

    // Get video engagement rate (Blob)
    getVideoEngagementRate(id: string, fileName: string, languageId: string, date: Date[]): Observable<any> {
        let body = {
            content_id: id,
            ...(languageId !== 'ALL' && { cms_language_id: languageId }),
            start_date: date[0],
            end_date: date[1]
        };

        return this.http.post<Blob>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.reportPath}/video-engagement-rate`, body, this.csvHeader).pipe(
            map(res => {
                this.downloadCSVFile(res, `(${languageId === 'ALL' ? 'ALL' : this.contentService.getLanguageName(Number.parseInt(languageId))})${fileName} Video Engagement Rate Report`, 'Report generated');
            })
        );
    }


}