import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { FormFieldModel, InputTextModel } from 'src/app/_models/form-field.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-textarea-input',
  standalone: true,
  imports: [FormsModule, FormModule, CommonModule],
  templateUrl: './textarea-input.component.html',
  styleUrl: './textarea-input.component.scss'
})
export class TextareaInputComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  data!: InputTextModel;
  textareaValue!: string;

  ngOnInit(): void {
    this.data = this.config as InputTextModel;
  }

  setNewValue(value: string) {
    this.onChange.emit({ formControlName: this.data.id, value: value });
  }
}
