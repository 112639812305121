<c-modal-header>
  <h5 cModalTitle>Create Event Schedule</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="mb-4">
    <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
  </div>
  <div class="card border-0 mb-4">
    <div class="card-body p-0">
      <div class="form-group">
        <app-text-input [config]="inputContentIDConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="list-filter-active-period">Publish Period
          <div class="form-sublabel">
            Specify the active dates for this content. Content will only be published and visible during this period.
          </div>
        </label>
        <c-form-check>
          <input cFormCheckInput id="same-as-topic-checkbox" type="checkbox">
          <label cFormCheckLabel for="same-as-topic-checkbox">Same as parent content</label>
        </c-form-check>

        <app-datetimerangepicker-input [config]="inputPublishPeriodConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputNameConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-datetimerangepicker-input [config]="inputDateTimeConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputLinkConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputRecapLinkConfig">
        </app-text-input>
      </div>
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="dismissModal()">Submit</button>
</c-modal-footer>