import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent, TextEditorComponent, DatetimerangepickerInputComponent, ContentSelectorComponent, ImageInputComponent, RadioInputComponent, SelectMenuWithSearchInputComponent, PersonSelectorComponent, DocumentSelectorComponent, TextareaInputComponent } from "../../../components/forms";
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker'; // https://github.com/danielmoncada/date-time-picker
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { FullscreenSpinnerComponent } from '..';

const dateTimeFormat = {
  parseInput: 'DD MMM YYYY, h:mm A',
  fullPickerInput: 'DD MMM YYYY, h:mm A',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'h:mm a',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-create-workshop-modal',
  standalone: true,
  imports: [FormModule, ModalModule, ButtonModule, CommonModule, TextInputComponent, TextEditorComponent, DatetimerangepickerInputComponent, ContentSelectorComponent, ImageInputComponent, RadioInputComponent, SelectMenuWithSearchInputComponent, PersonSelectorComponent, DocumentSelectorComponent, TextareaInputComponent, OwlDateTimeModule, OwlNativeDateTimeModule],
  templateUrl: './create-workshop-modal.component.html',
  styleUrl: './create-workshop-modal.component.scss',
  providers: [{
    provide: OWL_DATE_TIME_FORMATS,
    useValue: dateTimeFormat
  }]
})
export class CreateWorkshopModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  minDate: Date | null = null;

  // Form input - Content ID
  inputContentIDConfig = {
    id: 'content-id-input',
    name: 'content-id-input',
    label: 'Content ID',
    sublabel: 'Create a unique ID for this content. Max. 50 characters. E.g. coffee-stories-championship-day-1',
    placeholder: 'Enter here',
    max_length: 150,
    value: 'learning-lesson-xxx',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputPublishPeriodConfig = {
    id: 'publish-period-input',
    name: 'publish-period-input',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Type
  inputTypeConfig = {
    name: 'type-input',
    label: 'Type',
    sublabel: 'Specify the workshop type',
    inline_options: true,
    value: '',
    disabled: false,
    error_message: '',
    options: [
      { id: 'type-online-workshop', label: 'Online Workshop' },
      { id: 'type-offline-workshop', label: 'Offline Workshop' }
    ]
  };

  // Form input - Title
  inputTitleConfig = {
    id: 'title-input',
    name: 'title-input',
    label: 'Title',
    sublabel: 'Title of the content. Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Description
  inputDescriptionConfig = {
    id: 'description-input',
    name: 'description-input',
    label: 'Description',
    sublabel: 'A short paragraph as the introduction. Max 150 characters',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Cover image
  inputCoverImageConfig = {
    type: 'COVER_IMAGE',
    id: 'cover-image-input',
    name: 'cover-image-input',
    label: 'Cover image',
    sublabel: 'Image displayed in the listing page',
    value: '',
    disabled: false
  };

  // Form input - Mobile Banner image
  inputMobileBannerImageConfig = {
    type: 'BANNER_IMAGE_MOBILE',
    id: 'banner-image-input',
    name: 'banner-image-input',
    label: 'Banner (Mobile screen, optional)',
    sublabel: 'Select an image for the banner in mobile screen sizes (Screen size <768px).',
    value: '',
    disabled: false
  };

  // Form input - Mobile Banner image
  inputLargeBannerImageConfig = {
    type: 'BANNER_IMAGE_LARGE',
    id: 'banner-image-input',
    name: 'banner-image-input',
    label: 'Banner (Large screen)',
    sublabel: 'Select an image for the banner in large screen sizes (Screen size >768px).',
    value: '',
    disabled: false
  };

  // Form input - Tags
  inputTagsConfig = {
    id: 'tags-input',
    name: 'tags-input',
    label: 'Tags',
    sublabel: 'Add tagging for the content, may enter multiple. Separate each tag with comma. E.g. Coffee, Cold Brew, Frappuccino',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input -Body content
  inputWorkshopContentConfig = {
    id: 'workshop-content-input',
    name: 'workshop-content-input',
    label: 'Body content',
    sublabel: 'Create the body contents of the workshop',
    disabled: false,
    error_message: ''
  };

  // Form input - Video conference link
  inputVideoConferenceConfig = {
    id: 'video-conference-link-input',
    name: 'video-conference-link-input',
    label: 'Teams / Zoom',
    sublabel: 'Teams / Zoom video conference link',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Event location
  inputEventLocationConfig = {
    id: 'event-location-input',
    name: 'event-location-input',
    label: 'Event location',
    sublabel: 'Enter the event location address',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Participant Limit
  inputParticipantLimitConfig = {
    id: 'participant-limit-input',
    name: 'participant-limit-input',
    label: 'Participant limit',
    sublabel: 'Set the max. participant limit for this event. Leave blank if unlimited',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.openFullscreenSpinnerModal('CREATE_WORKSHOP');
    });
  }

  openFullscreenSpinnerModal(spinner_session: string) {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }
}
