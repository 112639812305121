import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { TextInputComponent } from "../../forms";
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { PartnerService } from 'src/app/_services/partner.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ContentService } from 'src/app/_services/content.service';

@Component({
  selector: 'app-bulk-update-partners-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, FormsModule, TextInputComponent, CommonModule],
  templateUrl: './bulk-update-partners-modal.component.html',
  styleUrl: './bulk-update-partners-modal.component.scss'
})
export class BulkUpdatePartnersModalComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Input() modal: any;
  @Input() state!: string;


  formError: boolean = false;
  uploadedFile!: File | undefined;

  constructor(
    private router: Router,
    private partnerService: PartnerService,
    private errorService: ErrorService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    // Subscribe to the modal's hidden event to navigate when it's dismissed
    this.modal.hidden.subscribe(() => {
      this.router.navigateByUrl('/manage-partners/all-partners');
    });
  }

  downloadBulkUpdateSample() {
    this.contentService.openFullscreenSpinnerModal();
    this.partnerService.getBulkUpdateSample().subscribe({
      next: res => {
        this.contentService.spinnerModalRef.dismiss();
      }
    })
  }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.contentService.openFullscreenSpinnerModal();
    this.partnerService.bulkUpdatePartnerDetails(this.uploadedFile!).subscribe({
      next: res => {
        this.contentService.spinnerModalRef.dismiss();
        if (!res.data) {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          return;
        }
        this.state = 'POST_UPLOAD'
      }
    });
  }

  viewPartners() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.router.navigateByUrl('/manage-partners/all-partners');
    });
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    // No file selected
    if (!file) {
      this.fileInput.nativeElement.value = '';
      this.uploadedFile = undefined;
      return;
    }
    if (file.size > 2 * 1024 * 1024) { // 2MB
      alert('File is too large!');
      this.uploadedFile = undefined;
      this.formError = true;
      return;
    } else {
      this.formError = false;
      this.uploadedFile = file;
    }
  }
}
