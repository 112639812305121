<c-modal-header>
  <h5 cModalTitle>
    Bulk Update Partners Rank</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <ng-container *ngIf="state === 'INITIATE'">
    <p class="mb-1">File format support: CSV</p>
    <p class="mb-2">Max. file size: 2MB</p>
    <p class="mb-4">
      <button cButton type="button" size="sm" variant="outline" href="#" rel="external nofollow noopener"
        target="_blank" (click)="downloadBulkUpdateSample()">
        <i class="cil-cloud-download me-2"></i>
        Download sample
      </button>
    </p>

    <div class="form-group mb-3 pt-4 border-0 border-top border-dashed">
      <label cLabel for="pin-input">
        Upload file
      </label>
      <c-input-group class="mb-2">
        <input type="file" #fileInput cFormControl accept=".csv" (change)="onFileSelected($event)" />
        <button cButton color="primary" (click)="fileInput.click()">Select file</button>
      </c-input-group>
      <div *ngIf="formError" class="form-error mt-2">File size too big / File format not supported</div>
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'POST_UPLOAD'">
    Partners updated successfully
  </ng-container>
</c-modal-body>
<c-modal-footer>
  <ng-container *ngIf="state === 'INITIATE'">
    <button cButton color="primary" (click)="submit()" [disabled]="formError || !uploadedFile">Upload</button>
  </ng-container>
  <ng-container *ngIf="state === 'POST_UPLOAD'">
    <button cButton color="primary" (click)="viewPartners()">View partners</button>
  </ng-container>
</c-modal-footer>