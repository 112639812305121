<c-modal-header>
  <h5 cModalTitle>Ops… There's An Error</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <span *ngIf="errorCode" class="text-danger">Error {{errorCode}}<br></span>
  <pre class="custom-pre">{{errorMessage}}</pre>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="dismissModal()">Close</button>
</c-modal-footer>