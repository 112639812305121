import { Component, Input, AfterViewInit, ViewChild } from '@angular/core';
import { ModalModule, ButtonModule, GridModule, CardComponent, FormModule } from '@coreui/angular';
import { FullscreenSpinnerComponent } from '../';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent, TextareaInputComponent, ImageInputComponent, ContentSelectorComponent, RadioInputComponent, SelectMenuWithSearchInputComponent, TextEditorComponent, PersonSelectorComponent, DocumentSelectorComponent, DatetimerangepickerInputComponent } from "../../../components/forms";
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS } from '@danielmoncada/angular-datetime-picker';
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';

const dateTimeFormat = {
  parseInput: 'DD MMM YYYY, h:mm A',
  fullPickerInput: 'DD MMM YYYY, h:mm A',
  datePickerInput: 'DD MMM YYYY',
  timePickerInput: 'h:mm a',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-create-learning-lesson-modal',
  standalone: true,
  templateUrl: './create-learning-lesson-modal.component.html',
  styleUrl: './create-learning-lesson-modal.component.scss',
  providers: [{
    provide: OWL_DATE_TIME_FORMATS,
    useValue: dateTimeFormat
  }],
  imports: [ModalModule, ButtonModule, CommonModule, GridModule, CardComponent, IconModule, TextInputComponent, ImageInputComponent, TextareaInputComponent, ContentSelectorComponent, RadioInputComponent, SelectMenuWithSearchInputComponent, TextEditorComponent, PersonSelectorComponent, DocumentSelectorComponent, OwlDateTimeModule, OwlNativeDateTimeModule, OwlMomentDateTimeModule, FormModule, DatetimerangepickerInputComponent]
})
export class CreateLearningLessonModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  minDate: Date | null = null;

  constructor(
    private modalService: NgbModal
  ) { }

  selectedHost = [
    {
      id: 'jon-appleseed-hk',
      name: 'Jon Appleseed'
    },
    {
      id: 'john-doe-hk',
      name: 'John Doe Jr'
    },
    {
      id: 'selina-teoh-my',
      name: 'Selina Teoh'
    }
  ];

  selectedDocument = [
    {
      id: 'lesson-lesson-document-01',
      name: 'lesson-lesson-document.pdf'
    },
    {
      id: 'lesson-lesson-spac-sheet',
      name: 'lesson-lesson-spec-sheet.xls'
    }
  ];

  // Form input - Content ID
  inputContentIDConfig = {
    id: 'content-id-input',
    name: 'content-id-input',
    label: 'Content ID',
    sublabel: 'Create a unique ID for this content. Max. 50 characters. E.g. coffee-stories-championship-day-1',
    placeholder: 'Enter here',
    max_length: 150,
    value: 'learning-lesson-xxx',
    disabled: false,
    error_message: ''
  };

  // Form input - Type
  inputTypeConfig = {
    name: 'type-input',
    label: 'Type',
    sublabel: 'Type of the content. Select one.',
    inline_options: true,
    value: '',
    disabled: false,
    error_message: '',
    options: [
      { id: 'lesson-type-article', label: 'Article' },
      { id: 'lesson-type-video', label: 'Video' }
    ]
  };

  // Form input - Link Learning topic
  inputLinkLearningTopicConfig = {
    id: 'link-learning-topic-input',
    name: 'link-learning-topic-input',
    label: 'Learning topic',
    sublabel: 'Link to a learning topic',
    placeholder: 'Select a topic',
    disabled: false,
    error_message: '',
    options: [
      { _id: 'category-coffee-origin', value: 'Coffee Origin' },
      { _id: 'category-sustainability', value: 'Sustainability' },
      { _id: 'category-ethical-courcing', value: 'Ethical Sourcing' }
    ]
  };

  // Form input -Title
  inputTitleConfig = {
    id: 'title-input',
    name: 'title-input',
    label: 'Title',
    sublabel: 'Title of the content. Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: 'Please complete this information'
  };

  // Form input - Description
  inputDescriptionConfig = {
    id: 'description-input',
    name: 'description-input',
    label: 'Description',
    sublabel: 'A short paragraph as the introduction. Max 150 characters',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Cover image
  inputCoverImageConfig = {
    type: 'COVER_IMAGE',
    id: 'cover-image-input',
    name: 'cover-image-input',
    label: 'Cover image',
    sublabel: 'Image displayed in the listing page',
    value: '',
    disabled: false
  };

  // Form input - Mobile Banner image
  inputMobileBannerImageConfig = {
    type: 'BANNER_IMAGE_MOBILE',
    id: 'banner-image-input',
    name: 'banner-image-input',
    label: 'Hero banner (Mobile screen, optional)',
    sublabel: 'Select an image for the hero banner in mobile screen sizes (Screen size <768px).',
    value: '',
    disabled: false
  };

  // Form input - Mobile Banner image
  inputLargeBannerImageConfig = {
    type: 'BANNER_IMAGE_LARGE',
    id: 'banner-image-input',
    name: 'banner-image-input',
    label: 'Hero banner (Large screen)',
    sublabel: 'Select an image for the hero banner in large screen sizes (Screen size >768px).',
    value: '',
    disabled: false
  };

  // Form input -Body content
  inputBodyContentConfig = {
    id: 'body-content-input',
    name: 'body-content-input',
    label: 'Body content',
    sublabel: 'Create the body contents of the information page',
    disabled: false,
    error_message: ''
  };

  // Form input - Tags
  inputTagsConfig = {
    id: 'tags-input',
    name: 'tags-input',
    label: 'Tags',
    sublabel: 'Add tagging for the content, may enter multiple. Separate each tag with comma. E.g. Coffee, Cold Brew, Frappuccino',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Bookmark title
  inputBookmarkTitleConfig = {
    id: 'bookmark-title-input',
    name: 'title-input',
    label: 'Bookmark title (optional)',
    sublabel: 'Title to display on bookmark. Default to content title. Max. 100 characters.',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Bookmart description
  inputBookmarkDescriptionConfig = {
    id: 'bookmark-description-input',
    name: 'description-input',
    label: 'Bookmark description (optional)',
    sublabel: 'Description to display on bookmark. Default to content description. Max. 150 characters.',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Cover image
  inputBookmarkCoverImageConfig = {
    type: 'COVER_IMAGE',
    id: 'bookmark-cover-image-input',
    name: 'cover-image-input',
    label: 'Bookmark cover image (optional)',
    sublabel: 'Cover image to display on bookmark. Default to content cover image.',
    value: '',
    disabled: false
  };

  // Form input - Video title
  inputVideoTitleConfig = {
    id: 'video-title-input',
    name: 'video-title-input',
    label: 'Video title',
    placeholder: 'Enter here',
    max_length: 200,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Video title
  inputvideoTopSubtitleConfig = {
    id: 'video-top-subtitle-input',
    name: 'video-top-subtitle-input',
    label: 'Video top subtitle (optional)',
    placeholder: 'Enter here',
    max_length: 200,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Video title
  inputvideoURLConfig = {
    id: 'video-url-input',
    name: 'video-url-input',
    label: 'Video URL',
    placeholder: 'Enter here',
    max_length: 200,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Video description
  inputVideoDescriptionConfig = {
    id: 'video-description-input',
    name: 'video-description-input',
    label: 'Video description (optional)',
    placeholder: 'Enter here',
    max_length: 300,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form add lesson
  inputAddHostConfig = {
    id: 'add-lesson-input',
    label: 'Host / Speaker',
    sublabel: 'Add host / speaker from the list, or create a new person',
    disabled: false,
    error_message: '',
    select_button_label: 'Select person',
    create_button_label: 'Create person',
    selected_data: this.selectedHost
  };

  // Form add lesson
  inputAddDocumentConfig = {
    id: 'add-document-input',
    label: 'Document (optional)',
    sublabel: 'Add document from the list, or upload new document',
    disabled: false,
    error_message: '',
    select_button_label: 'Select document',
    create_button_label: 'Upload document',
    selected_data: this.selectedDocument
  };

  // Form input - Date time
  inputPublishPeriodConfig = {
    id: 'publish-period-input',
    name: 'publish-period-input',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Video duration
  inputVideoDurationConfig = {
    id: 'video-duration-input',
    name: 'video-duration-input',
    label: 'Video duration (seconds)',
    sublabel: 'Enter the duration of the video.',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false
  };

  // Form input - Content duration
  inputContentDurationConfig = {
    id: 'content-duration-input',
    name: 'content-duration-input',
    label: 'Content duration (seconds)',
    sublabel: 'Estimated reading time for this article.',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false
  };

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.openFullscreenSpinnerModal();
    });
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }
}
