import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UploadImagePreviewComponent } from "./upload-image-preview/upload-image-preview.component";
import { UploadImageProcessComponent } from "./upload-image-process/upload-image-process.component";
import { fadeInOut } from '../../../animations';

@Component({
  selector: 'app-upload-image',
  standalone: true,
  templateUrl: './upload-image.component.html',
  styleUrl: './upload-image.component.scss',
  imports: [CommonModule, UploadImagePreviewComponent, UploadImageProcessComponent],
  animations: [fadeInOut]
})
export class UploadImageComponent {
  @Input() modal: any;
  @Input() imageToBeUploaded!: string;
  @Input() imageFilename!: string;

  constructor(
    private modalService: NgbModal
  ) { }

  currentStep: string = 'PREVIEW_IMAGE';

  dismissModal() {
    this.modal.dismiss();
  }

  goToProcessImage() {
    this.currentStep = 'PROCESS_IMAGE'
  }
}
