import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { TextInputComponent } from "../../forms";
import { ValidationService } from 'src/app/_services/validation.service';
import { InputTextModel } from 'src/app/_models/form-field.model';
import { ErrorService } from 'src/app/_services/error.service';

@Component({
  selector: 'app-add-workshop-participant-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, FormsModule, TextInputComponent],
  templateUrl: './add-workshop-participant-modal.component.html',
  styleUrl: './add-workshop-participant-modal.component.scss'
})
export class AddWorkshopParticipantModalComponent {
  @Input() modal: any;
  @Input() workshopName!: string;
  @Output() onAdd = new EventEmitter<string>();

  inputEmailConfig: InputTextModel = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email',
    placeholder: '',
    max_length: 100,
    required: true,
    value: ''
  };

  constructor(
    private validationService: ValidationService,
    private errorService: ErrorService
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    if (this.validationService.validateEmail(this.inputEmailConfig.value ?? '')) {
      this.modal.dismiss();
      this.modal.hidden.subscribe(() => {
        this.onAdd.emit(this.inputEmailConfig.value);
      });
    } else {
      this.inputEmailConfig.error_message = this.errorService.getFrontendErrorMapping('5002');
      this.inputEmailConfig.error = ['format'];
    }
  }

  setInputValue(data: { formControlName: string, value: string }) {
    if (data.formControlName.toUpperCase() === 'EMAIL-INPUT') {
      this.inputEmailConfig.value = data.value;
    }
  }
}
