<c-modal-header>
  <h5 cModalTitle>
    Reset Password For Partner</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  Are you sure you want to reset password for this partner?

  <app-text-input [config]="inputEmailConfig">
  </app-text-input>
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="submit()">Yes, reset partner's password</button>
</c-modal-footer>