<c-modal-header>
  <h5 cModalTitle>
    Upload Completion List</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <ng-container *ngIf="state === 'INITIATE'">
    <p class="mb-1">File format support: CSV</p>
    <p>Max. file size: 2MB</p>

    <div class="mb-4 pb-4 border-0 border-dashed border-bottom">
      <button cButton size="sm" class="btn-link" (click)="downloadTemplate()">
        <span class="icon-img me-1">
          <svg cIcon name="cilCloudDownload"></svg>
        </span>
        Download CSV Template</button>
    </div>

    <div class="form-group mb-3">
      <label cLabel for="pin-input">
        Upload file
      </label>
      <c-input-group class="mb-2">
        <input type="file" #fileInput cFormControl accept=".csv" (change)="onFileSelected($event)" />
        <button cButton color="primary" (click)="fileInput.click()">Select file</button>
      </c-input-group>
      <div *ngIf="formError" class="form-error mt-2">File size too big / File format not supported</div>
    </div>
  </ng-container>

  <ng-container *ngIf="state === 'POST_UPLOAD'">
    File upload successfully.
  </ng-container>
</c-modal-body>
<c-modal-footer>
  <ng-container *ngIf="state === 'INITIATE'">
    <button cButton color="primary" (click)="submit()" [disabled]="formError">Upload</button>
  </ng-container>
  <ng-container *ngIf="state === 'POST_UPLOAD'">
    <button cButton color="primary" (click)="viewBapTopicItemCompletionList()">View BAP Topic Item Completion
      List</button>
  </ng-container>
</c-modal-footer>