<c-modal-header>
  <h5 cModalTitle>Create Person</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="mb-4">
    <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
  </div>
  <div class="card mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Information</h3>
      <p class="mb-0">Set-up the information for this learning lesson</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputContentIDConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="list-filter-active-period">Publish Period
          <div class="form-sublabel">
            Specify the active dates for this content. Content will only be published and visible during this period.
          </div>
        </label>
        <c-form-check>
          <input cFormCheckInput id="same-as-topic-checkbox" type="checkbox">
          <label cFormCheckLabel for="same-as-topic-checkbox">Same as parent content</label>
        </c-form-check>

        <app-datetimerangepicker-input [config]="inputPublishPeriodConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputNameConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-select-menu-with-search-input [config]="inputPersonMarketConfig">
        </app-select-menu-with-search-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputPersonTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputProfileImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTagsConfig">
        </app-text-input>
      </div>
    </div>
  </div>

  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Bio</h3>
      <p class="mb-0">Introduction of the steering committee</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-radio-input [config]="inputTypeConfig">
        </app-radio-input>
      </div>

      <!-- If bio type is 'Text' -->
      <div class="form-group">
        <app-text-editor [config]="inputBioContentConfig">
        </app-text-editor>
      </div>
      <!-- /If bio type is 'Text' -->

      <!-- If bio type is 'Video' -->
      <div class="form-group">
        <app-text-input [config]="inputBioVideoConfig">
        </app-text-input>
      </div>
      <!-- /If bio type is 'Video' -->
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="dismissModal()">Submit</button>
</c-modal-footer>