import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { FormFieldModel, InputSelectModel } from 'src/app/_models/form-field.model';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-select-menu-input',
  standalone: true,
  imports: [FormsModule, FormModule, CommonModule],
  templateUrl: './select-menu-input.component.html',
  styleUrl: './select-menu-input.component.scss'
})
export class SelectMenuInputComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  data!: InputSelectModel;

  ngOnInit(): void {
    this.data = this.config as InputSelectModel;
  }

  setNewValue(e: any) {
    this.onChange.emit({ formControlName: this.data.id, value: e });
  }
}
