export enum CMSContentType {
    PUBLIC_HOME_BANNER = 'SbuxPublicHomeBanner',
    PUBLIC_HOME_ARTICLE = 'SbuxPublicHomeArticle',
    PARTNER_HOME_BANNER = 'SbuxHomeBanner',
    PARTNER_HOME_RECOMMENDED_LIST_ITEM = 'SbuxHomeRecommendedList',
    PARTNER_HOME_EXPLORE_LIST_ITEM = 'SbuxHomeExploreList',
    PARTNER_HOME_SPECIAL_TOPIC = 'SbuxHomeSpecialTopic',
    PARTNER_HOME_NOTICE = 'SbuxHomeNotice',
    PARTNER_HOME_NOTIFICATION = 'SbuxHomeNotification',
    EVENT = 'SbuxEvent',
    EVENT_ACTIVITY = 'SbuxEventActivity',
    EVENT_VIDEO = 'SbuxEventVideo',
    EVENT_BANNER = 'SbuxEventBanner',
    FEATURED_EVENT = 'SbuxFeaturedEvent',
    EVENT_HIGHLIGHT = 'SbuxEventHighlight',
    EVENT_ARCHIVE = 'SbuxEventArchive',
    EVENT_SCHEDULE = 'SbuxEventSchedule',
    LEARNING_TOPIC = 'SbuxLearningTopic',
    LEARNING_LESSON = 'SbuxLearningLesson',
    LEARNING_FILTER = 'SbuxLearningFilter',
    COMPETITION = 'SbuxCompetition',
    COMPETITION_INFORMATION = 'SbuxCompetitionInformation',
    COMPETITION_VIDEO = 'SbuxCompetitionVideo',
    COMPETITION_STEERING_COMMITTEE = 'SbuxCompetitionSteeringCommittee',
    COMPETITION_YEAR_FILTER = 'SbuxCompetitionYearFilter',
    BAP_TOPIC = 'SbuxBapTopic',
    BAP_TOPIC_ITEM = 'SbuxBapTopicItem',
    WORKSHOP = 'SbuxWorkshop',
    WORKSHOP_BANNER = 'SbuxWorkshopBanner',
    WORKSHOP_ARCHIVE = 'SbuxWorkshopArchive',
    PERSON = 'SbuxPerson'
}

export enum CMSContentName {
    SBUXPUBLICHOMEBANNER = 'Public Home Banner',
    SBUXPUBLICHOMEARTICLE = 'Public Home Article',
    SBUXHOMEBANNER = 'Home Banner',
    SBUXHOMERECOMMENDEDLIST = 'Home Recommended List',
    SBUXHOMEEXPLORELIST = 'Home Explore List',
    SBUXHOMESPECIALTOPIC = 'Home Special Topic',
    SBUXHOMENOTICE = 'Home Notice',
    SBUXHOMENOTIFICATION = 'Home Notification',
    SBUXEVENT = 'Event',
    SBUXEVENTACTIVITY = 'Event Activity',
    SBUXEVENTVIDEO = 'Event Video',
    SBUXEVENTBANNER = 'Event Banner',
    SBUXFEATUREDEVENT = 'Featured Event',
    SBUXEVENTHIGHLIGHT = 'Event Highlight',
    SBUXEVENTARCHIVE = 'Event Archive',
    SBUXEVENTSCHEDULE = 'Event Schedule',
    SBUXLEARNINGTOPIC = 'Learning Topic',
    SBUXLEARNINGLESSON = 'Learning Lesson',
    SBUXLEARNINGFILTER = 'Learning Filter',
    SBUXCOMPETITION = 'Competition',
    SBUXCOMPETITIONINFORMATION = 'Competition Information',
    SBUXCOMPETITIONVIDEO = 'Competition Video',
    SBUXCOMPETITIONSTEERINGCOMMITTEE = 'Competition Steering Committee',
    SBUXCOMPETITIONYEARFILTER = 'Competition Year Filter',
    SBUXBAPTOPIC = 'Bap Topic',
    SBUXBAPTOPICITEM = 'Bap Topic Item',
    SBUXWORKSHOP = 'Workshop',
    SBUXWORKSHOPBANNER = 'Workshop Banner',
    SBUXWORKSHOPARCHIVE = 'Workshop Archive',
    SBUXPERSON = 'Person',
    // Content Order
    SBUXPUBLICHOMEARTICLECONTENTORDER = 'Public Home Article Order Setting',
    SBUXHOMEBANNERCONTENTORDER = 'Home Banner Order Setting',
    SBUXHOMERECOMMENDEDLISTCONTENTORDER = 'Home Recommended List Order Setting',
    SBUXHOMEEXPLORELISTCONTENTORDER = 'Home Explore List Order Setting',
    SBUXHOMESPECIALTOPICCONTENTORDER = 'Home Special Topic Order Setting',
    SBUXHOMENOTICECONTENTORDER = 'Home Notice Order Setting',
    SBUXHOMENOTIFICATIONCONTENTORDER = 'Home Notification Order Setting',
    SBUXEVENTCONTENTORDER = 'Event Order Setting',
    SBUXEVENTACTIVITYCONTENTORDER = 'Event Activity Order Setting',
    SBUXEVENTBANNERCONTENTORDER = 'Event Banner Order Setting',
    SBUXFEATUREDEVENTCONTENTORDER = 'Featured Event Order Setting',
    SBUXEVENTHIGHLIGHTCONTENTORDER = 'Event Highlight Order Setting',
    SBUXEVENTARCHIVECONTENTORDER = 'Event Archive Order Setting',
    SBUXLEARNINGTOPICCONTENTORDER = 'Learning Topic Order Setting',
    SBUXLEARNINGFILTERCONTENTORDER = 'Learning Filter Order Setting',
    SBUXCOMPETITIONCONTENTORDER = 'Competition Order Setting',
    SBUXCOMPETITIONINFORMATIONCONTENTORDER = 'Competition Information Order Setting',
    SBUXCOMPETITIONSTEERINGCOMMITTEECONTENTORDER = 'Competition Steering Committee Order Setting',
    SBUXCOMPETITIONYEARFILTERCONTENTORDER = 'Competition Year Filter Order Setting',
    SBUXBAPTOPICCONTENTORDER = 'Bap Topic Order Setting',
    SBUXWORKSHOPCONTENTORDER = 'Workshop Order Setting',
    SBUXWORKSHOPBANNERCONTENTORDER = 'Workshop Banner Order Setting',
    SBUXWORKSHOPARCHIVECONTENTORDER = 'Workshop Archive Order Setting'
}

export enum CMSContentPath {
    SBUXPUBLICHOMEBANNER = '/public-home/public-home-banner',
    SBUXPUBLICHOMEARTICLE = '/public-home/public-home-article',
    SBUXHOMEBANNER = '/partner-home/partner-home-banner',
    SBUXHOMERECOMMENDEDLIST = '/partner-home/partner-home-recommended-list-item',
    SBUXHOMEEXPLORELIST = '/partner-home/partner-home-explore-list-item',
    SBUXHOMESPECIALTOPIC = '/partner-home/partner-home-special-topic',
    SBUXHOMENOTICE = '/partner-home/partner-home-notice',
    SBUXHOMENOTIFICATION = '/partner-home/partner-home-notification',
    SBUXEVENT = '/event/event',
    SBUXEVENTACTIVITY = '/event/event-activity',
    SBUXEVENTVIDEO = '/event/event-video',
    SBUXEVENTBANNER = '/event/event-banner',
    SBUXFEATUREDEVENT = '/event/featured-event',
    SBUXEVENTHIGHLIGHT = '/event/event-highlight',
    SBUXEVENTARCHIVE = '/event/event-archive',
    SBUXEVENTSCHEDULE = '/event/event-schedule',
    SBUXLEARNINGTOPIC = '/learning-centre/learning-topic',
    SBUXLEARNINGLESSON = '/learning-centre/learning-lesson',
    SBUXLEARNINGFILTER = '/learning-centre/learning-filter',
    SBUXCOMPETITION = '/competition/competition',
    SBUXCOMPETITIONINFORMATION = '/competition/competition-information',
    SBUXCOMPETITIONVIDEO = '/competition/competition-video',
    SBUXCOMPETITIONSTEERINGCOMMITTEE = '/competition/competition-steering-committee',
    SBUXCOMPETITIONYEARFILTER = '/competition/competition-year-filter',
    SBUXBAPTOPIC = '/bap/bap-topic',
    SBUXBAPTOPICITEM = '/bap/bap-topic-item',
    SBUXWORKSHOP = '/workshop/workshop',
    SBUXWORKSHOPBANNER = '/workshop/workshop-banner',
    SBUXWORKSHOPARCHIVE = '/workshop/workshop-archive',
    SBUXPERSON = '/person',
    // Content Order
    SBUXPUBLICHOMEARTICLECONTENTORDER = '/public-home/public-home-article/order',
    SBUXHOMEBANNERCONTENTORDER = '/partner-home/partner-home-banner/order',
    SBUXHOMERECOMMENDEDLISTCONTENTORDER = '/partner-home/partner-home-recommended-list-item/order',
    SBUXHOMEEXPLORELISTCONTENTORDER = '/partner-home/partner-home-explore-list-item/order',
    SBUXHOMESPECIALTOPICCONTENTORDER = '/partner-home/partner-home-special-topic/order',
    SBUXHOMENOTICECONTENTORDER = '/partner-home/partner-home-notice/order',
    SBUXHOMENOTIFICATIONCONTENTORDER = '/partner-home/partner-home-notification/order',
    SBUXEVENTCONTENTORDER = '/event/event/order',
    SBUXEVENTACTIVITYCONTENTORDER = '/event/event-activity/order',
    SBUXEVENTBANNERCONTENTORDER = '/event/event-banner/order',
    SBUXFEATUREDEVENTCONTENTORDER = '/event/featured-event/order',
    SBUXEVENTHIGHLIGHTCONTENTORDER = '/event/event-highlight/order',
    SBUXEVENTARCHIVECONTENTORDER = '/event/event-archive/order',
    SBUXLEARNINGTOPICCONTENTORDER = '/learning-centre/learning-topic/order',
    SBUXLEARNINGFILTERCONTENTORDER = '/learning-centre/learning-filter/order',
    SBUXCOMPETITIONCONTENTORDER = '/competition/competition/order',
    SBUXCOMPETITIONINFORMATIONCONTENTORDER = '/competition/competition-information/order',
    SBUXCOMPETITIONSTEERINGCOMMITTEECONTENTORDER = '/competition/competition-steering-committee/order',
    SBUXCOMPETITIONYEARFILTERCONTENTORDER = '/competition/competition-year-filter/order',
    SBUXBAPTOPICCONTENTORDER = '/bap/bap-topic/order',
    SBUXWORKSHOPCONTENTORDER = '/workshop/workshop/order',
    SBUXWORKSHOPBANNERCONTENTORDER = '/workshop/workshop-banner/order',
    SBUXWORKSHOPARCHIVECONTENTORDER = '/workshop/workshop-archive/order'
}

export const CMSPreviewContentPath = { // [path, require identifier, multiple type]
    SBUXPUBLICHOMEBANNER: ['/public', false, false],
    SBUXPUBLICHOMEARTICLE: ['/public', false, false],
    SBUXHOMEBANNER: ['/home', false, false],
    SBUXHOMERECOMMENDEDLIST: ['/home', false, false],
    SBUXHOMEEXPLORELIST: ['/home', false, false],
    SBUXHOMESPECIALTOPIC: ['/home', false, false],
    SBUXHOMENOTICE: ['/home', false, false],
    SBUXHOMENOTIFICATION: ['/home', false, false],
    SBUXEVENT: ['/events', true, false],
    SBUXEVENTACTIVITY: ['/events/activities', true, false],
    SBUXEVENTVIDEO: ['/events/videos', true, false],
    SBUXEVENTBANNER: ['/events', false, false],
    SBUXFEATUREDEVENT: ['/events', false, false],
    SBUXEVENTHIGHLIGHT: ['/events', false, false],
    SBUXEVENTARCHIVE: ['/events', false, false],
    SBUXEVENTSCHEDULE: ['/events/event-schedule', false, false],
    SBUXLEARNINGTOPIC: ['/learn', true, false],
    SBUXLEARNINGLESSON: ['/learn', true, true], // cater for ARTICLE, VIDEO
    SBUXLEARNINGFILTER: ['/learn', false, false],
    SBUXCOMPETITION: ['/competitions', true, false],
    SBUXCOMPETITIONINFORMATION: ['/competitions', false, false],
    SBUXCOMPETITIONVIDEO: ['/competitions/videos', true, false],
    SBUXCOMPETITIONSTEERINGCOMMITTEE: ['/competitions/competition-steering-committee', false, false],
    SBUXCOMPETITIONYEARFILTER: ['/competitions', false, false],
    SBUXBAPTOPIC: ['/bap-actual', true, false], // change later
    SBUXBAPTOPICITEM: ['/bap-actual', true, true], // change later , cater for WORKSHOP, ARTICLE, FLASH CARD
    SBUXWORKSHOP: ['/workshops/articles', true, false],
    SBUXWORKSHOPBANNER: ['/workshops', false, false],
    SBUXWORKSHOPARCHIVE: ['/workshops/videos', true, false],
    SBUXPERSON: ['/person', false, false],
    // Content Order Preview
    SBUXPUBLICHOMEARTICLECONTENTORDER: ['/public', false, false],
    SBUXHOMEBANNERCONTENTORDER: ['/home', false, false],
    SBUXHOMERECOMMENDEDLISTCONTENTORDER: ['/home', false, false],
    SBUXHOMEEXPLORELISTCONTENTORDER: ['/home', false, false],
    SBUXHOMESPECIALTOPICCONTENTORDER: ['/home', false, false],
    SBUXHOMENOTICECONTENTORDER: ['/home', false, false],
    SBUXHOMENOTIFICATIONCONTENTORDER: ['/home', false, false],
    SBUXEVENTCONTENTORDER: ['/events', false, false],
    SBUXEVENTACTIVITYCONTENTORDER: ['/events', false, false],
    SBUXEVENTBANNERCONTENTORDER: ['/events', false, false],
    SBUXFEATUREDEVENTCONTENTORDER: ['/events', false, false],
    SBUXEVENTHIGHLIGHTCONTENTORDER: ['/events', false, false],
    SBUXEVENTARCHIVECONTENTORDER: ['/events', false, false],
    SBUXLEARNINGTOPICCONTENTORDER: ['/learn', false, false],
    SBUXLEARNINGFILTERCONTENTORDER: ['/learn', false, false],
    SBUXCOMPETITIONCONTENTORDER: ['/competitions', false, false],
    SBUXCOMPETITIONINFORMATIONCONTENTORDER: ['/competitions', false, false],
    SBUXCOMPETITIONSTEERINGCOMMITTEECONTENTORDER: ['/competitions/competition-steering-committee', false, false],
    SBUXCOMPETITIONYEARFILTERCONTENTORDER: ['/competitions', false, false],
    SBUXBAPTOPICCONTENTORDER: ['/bap-actual', false, false], // change later
    SBUXWORKSHOPCONTENTORDER: ['/workshops', false, false],
    SBUXWORKSHOPBANNERCONTENTORDER: ['/workshops', false, false],
    SBUXWORKSHOPARCHIVECONTENTORDER: ['/workshops', false, false],
}

export enum ManageContent {
    NEW = 'Create',
    EDIT = 'Edit',
    CHECK = 'Check'
}