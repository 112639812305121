<div class="select-menu-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}}<span class="text-danger" *ngIf="data.required">*</span>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <select [id]="data.id" [(ngModel)]="data.value" cSelect [disabled]="data.disabled"
    (ngModelChange)="data.error = []; setNewValue($event)"
    [ngClass]="{'border-danger': data.error !== undefined && data.error.length > 0 && data.error[0] !== ''}">
    <option value="" disabled>Select One</option>
    <option *ngFor="let option of data.options" [value]="option.value" [disabled]="option.disabled">{{ option.label }}
    </option>
  </select>
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">{{data.error_message}}</div>
</div>