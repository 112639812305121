import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { FormsModule } from '@angular/forms';
import { FormFieldModel, InputSelectModel } from 'src/app/_models/form-field.model';

@Component({
  selector: 'app-select-menu-with-search-input',
  standalone: true,
  imports: [FormModule, CommonModule, SelectDropDownModule, FormsModule],
  templateUrl: './select-menu-with-search-input.component.html',
  styleUrl: './select-menu-with-search-input.component.scss'
})
export class SelectMenuWithSearchInputComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  @Output() onSearch = new EventEmitter<string>();
  data!: InputSelectModel;
  singleSelect: any = [];
  select_menu_config = {
    displayKey: "value", // if objects array passed which key to be displayed defaults to description
    search: true,
    limitTo: 0,
    height: "300px",
    enableSelectAll: true,
    placeholder: 'Select one',
    noResultsFound: "No results found!"
  };

  ngOnInit(): void {
    this.data = this.config as InputSelectModel;
    this.select_menu_config.placeholder = this.data.placeholder || 'Select one';
    this.singleSelect = this.data.value;
  }

  setNewValue(e: any) {
    this.onChange.emit({ formControlName: this.data.id, value: this.singleSelect.value ?? '' });
  }

  searchList(event: any) {
    this.onSearch.emit(event);
  }
}
