<div *ngIf="data" class="text-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}}<span class="text-danger" *ngIf="data.required">*</span>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <input cFormControl [type]="data.type || 'text'" [attr.id]="data.id ? data.id : null"
    [attr.name]="data.name ? data.name : null" [attr.maxlength]="data.max_length ? data.max_length : null"
    [placeholder]="data.placeholder ? data.placeholder : 'Enter here'" [value]="data.value"
    [disabled]="data.disabled === undefined ? false : data.disabled" [(ngModel)]="data.value"
    (ngModelChange)="data.error = []; setNewValue($event)" [required]="data.required"
    [ngClass]="{'border-danger': data.error !== undefined && data.error.length > 0 && data.error[0] !== ''}"
    [autocomplete]="autoComplete ?? 'off'" />
  <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2 flex-100">{{data.error_message}}
  </div>
  <div *ngIf="data.error && data.error.includes('pattern')" class="form-error mt-2 flex-100">{{data.name}} has exceed
    the
    character limit.</div>
  <div *ngIf="data.error && data.error.includes('format')" class="form-error mt-2 flex-100">{{data.error_message}}</div>
  <div *ngIf="data.show_password_tooltips && data.password_tooltips_error_list"
    class="d-flex align-items-center justify-content-center px-2">
    <button type="button" *ngIf="data.show_password_tooltips && data.password_tooltips_error_list"
      #tooltipButton="ngbTooltip" class="btn btn-circle btn-password-tooltips"
      [ngClass]="{'danger':data.password_tooltips_error_list.length > 0, 'success':data.password_tooltips_error_list.length === 0 }"
      placement="auto" [ngbTooltip]="passwordTooltip" tooltipClass="password-tooltips">
      <span *ngIf="data.password_tooltips_error_list.length > 0" class="icon-img">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
          <path d="M440-400v-360h80v360h-80Zm0 200v-80h80v80h-80Z" />
        </svg>
      </span>
      <span *ngIf="data.password_tooltips_error_list.length === 0" class="icon-img">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960">
          <path d="M382-240 154-468l57-57 171 171 367-367 57 57-424 424Z" />
        </svg>
      </span>
    </button>
    <ng-template #passwordTooltip>
      <strong>Password should:</strong>
      <ul class="list-unstyled" *ngIf="data.password_tooltips_error_list">
        <li [ngClass]="{
          'text-danger': data.password_tooltips_error_list.includes('invalidCharLength'),
          'text-success': !data.password_tooltips_error_list.includes('invalidCharLength')
        }">
          Be 8 to 25 characters long
        </li>
        <li [ngClass]="{'text-danger':data.password_tooltips_error_list.includes('invalidLowercase'),
         'text-success': !data.password_tooltips_error_list.includes('invalidLowercase')}">
          Have at least one lower case letter (e.g. a-z)
        </li>
        <li
          [ngClass]="{'text-danger':data.password_tooltips_error_list.includes('invalidUppercase'), 'text-success': !data.password_tooltips_error_list.includes('invalidUppercase')}">
          Have at least one upper case letter (e.g. A-Z)
        </li>
        <li
          [ngClass]="{'text-danger':data.password_tooltips_error_list.includes('invalidNumber'), 'text-success': !data.password_tooltips_error_list.includes('invalidNumber')}">
          Have at least one number (e.g. 0-9)
        </li>
        <li
          [ngClass]="{'text-danger':data.password_tooltips_error_list.includes('invalidSpecialChar'), 'text-success': !data.password_tooltips_error_list.includes('invalidSpecialChar')}">
          Have at least one special character (e.g. !&#64;#$%^&*)
        </li>
      </ul>

      <strong>Tips + Tricks:</strong>
      <ul class="list-unstyled mb-0">
        <li>Try not to use consecutive letters or numbers</li>
        <li>Don't use your username</li>
        <li>Avoid common English words</li>
      </ul>
    </ng-template>
  </div>

</div>