import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { FullscreenSpinnerComponent } from '../';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-rename-folder-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, FormsModule],
  templateUrl: './rename-folder-modal.component.html',
  styleUrl: './rename-folder-modal.component.scss'
})
export class RenameFolderModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  folderNameValue: string = 'Latte Art Competition';

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.openFullscreenSpinnerModal();
    });
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }
}
