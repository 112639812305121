import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, map } from "rxjs";
import { environment } from "src/environment/environment";
import { FormFieldModel, formField, formObjectModel } from "../_models/form-field.model";
import { FormService } from "./form.service";
import { InputField } from "../enum/input-field.enum";
import { DatePipe } from "@angular/common";
import { isArray } from "lodash-es";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { FullscreenSpinnerComponent } from "../components/modal";
import moment from "moment";

@Injectable({
    providedIn: 'root',
})

export class ContentService {
    private languageCode: { [key: string]: string } = {
        "1": "EN", // English
        "4852764": "ZH", // Chinese
        "4938438": "TH", // Thai
        "5017431": "JA", // Japanese
        "4561158": "ID", // Indonesian
        "5028697": "KO", // Korean
        "5146990": "VI" // Vietnamese
    }

    private languageName: { [key: number]: string } = {
        1: "English", // English
        4852764: "Traditional Chinese", // Chinese
        4938438: "Thai", // Thai
        5017431: "Japanese", // Japanese
        4561158: "Indonesia", // Indonesian
        5028697: "Korean", // Korean
        5146990: "Vietnamese" // Vietnamese
    }
    public spinnerModalRef!: any;

    constructor(
        private http: HttpClient,
        private formService: FormService,
        private datePipe: DatePipe,
        private modalService: NgbModal
    ) { }

    /* =========================================================================================================================== */
    /* ======================================================== GENERAL ========================================================== */
    /* =========================================================================================================================== */

    // Sanitise video url
    formatVideoUrl(videoUrl: string): string | boolean {
        let VID_REGEX = /(?:youtube(?:-nocookie)?\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)|l(?:ive)?)\/|\S*?[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
        const cleanUrl = videoUrl.match(VID_REGEX);
        return cleanUrl ? `https://www.youtube.com/embed/${cleanUrl![1]}` : false; // Check is Youtube Link
    }

    // Format DateTime (Eg: 9 Aug 2024, 3:45 PM (UTC))
    formatDateTime(dateTime: string | Date): string {
        return this.datePipe.transform(dateTime, 'd MMM y, h:mm a (z)') || '';
    }

    // Format DateTime (Eg: 9/8/2024 3:45PM)
    formatDateTimeSlash(dateTime: string | Date): string {
        return this.datePipe.transform(dateTime, 'd/M/y h:mma') || '';
    }

    // Format DateTime (Eg: 9/8/2024 3:45PM - 13/10/2024 10:45PM)
    formatDateTimeRange(startDate: string | Date, endDate: string | Date): string {
        const formattedStartDate = this.formatDateTimeSlash(startDate);
        const formattedEndDate = this.formatDateTimeSlash(endDate);
        return `${formattedStartDate} - ${formattedEndDate}`;
    }

    // Get language name
    getLanguageName(id: number): string {
        return this.languageName[id];
    }

    // Get language code
    getLanguageCode(id: number): string {
        return this.languageCode[id];
    }

    // Get CMS Content Fields
    getContentField(contentTypeId: string, isExisting: boolean, isCheck: boolean, cmsData: { [key: string]: string } = {}, languageId: number = 1): Observable<any> {
        let formArray: formObjectModel<FormFieldModel>[] = []
        let sidePanelArray: formObjectModel<FormFieldModel>[] = [{
            header: [],
            body: [],
            containTrigger: [],
            containTriggerBy: []
        }];

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentTypePath}/details/${contentTypeId}`).pipe(
            map(res => {
                res.data.fields.forEach((field: any) => {
                    const fieldType2 = field.fieldVariables.find((variable: any) => variable.key === 'type');
                    let obj: formField<FormFieldModel> = {
                        type: fieldType2?.value,
                    }

                    // Skip field
                    if (field.variable === 'approvalStatus') {
                        return;
                    }

                    // Side panel
                    if (field.variable === 'publishStartDate' || field.variable === 'publishEndDate' || field.variable === 'blackApronExclusive') {
                        this.formService.constructFormArraySwitchCase(field, fieldType2, obj, sidePanelArray, isCheck, isExisting && cmsData ? (typeof cmsData[field.variable] === 'object' && !isArray(cmsData[field.variable]) ? [cmsData[field.variable]] : cmsData[field.variable]) : undefined, languageId);
                        return;
                    }

                    // Initialize formArray(Main form)
                    if ((obj.type !== undefined) && (obj.type === InputField.LINEDIVIDER || formArray.length === 0)) {
                        formArray.push({
                            header: [],
                            body: [],
                            containTrigger: [],
                            containTriggerBy: []
                        });
                    }

                    // formArray(Main form)
                    this.formService.constructFormArraySwitchCase(field, fieldType2, obj, formArray, isCheck, isExisting && cmsData ? (typeof cmsData[field.variable] === 'object' && !isArray(cmsData[field.variable]) ? [cmsData[field.variable]] : cmsData[field.variable]) : undefined, languageId);
                });
                return { formArray: formArray, sidePanelArray: sidePanelArray, contentTypeName: res.data.name, fetched: true };
            })
        )
    }

    // Get content listing (Content Listing Page)
    getContentListing(languageId: number, page: number, size: number, depth: number = 1, status: string, contentType: string, search: string = '', activePeriod: Date[] = []): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("language", languageId);
        page !== -1 ? queryParams = queryParams.append("page", page > 0 ? page - 1 : 0) : null;
        size !== 0 ? queryParams = queryParams.append("size", size) : null;
        queryParams = queryParams.append("type", contentType);
        queryParams = queryParams.append("depth", depth);
        search !== '' ? queryParams = queryParams.append("search", search) : null;
        status !== 'ALL' ? queryParams = queryParams.append("status", status) : null;
        if (activePeriod.length === 2) {
            activePeriod[0] ? queryParams = queryParams.append("publishStartAt", `${moment(activePeriod[0]).format("YYYYMMDD")}000000`) : null;
            activePeriod[1] ? queryParams = queryParams.append("publishEndAt", `${moment(activePeriod[1]).format("YYYYMMDD")}235959`) : null;
        };

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/list`, { params: queryParams }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // COME BACK LATER
    // Get content listing (Pending Approval Page)
    getContentListingWithPendingApproval(languageId: number, page: number, size: number, contentType: string, search: string = ''): Observable<any> {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("language", languageId);
        queryParams = queryParams.append("page", page > 0 ? page - 1 : 0);
        queryParams = queryParams.append("size", size);
        contentType !== 'ALL' ? queryParams = queryParams.append("type", contentType) : null;
        search !== '' ? queryParams = queryParams.append("search", search) : null;

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/list/pending-approval`, { params: queryParams }).pipe(
            map(res => {
                return res;
            })
        )
    }

    // Loading overlay
    openFullscreenSpinnerModal() {
        this.spinnerModalRef = this.modalService.open(FullscreenSpinnerComponent, {
            centered: true,
            windowClass: 'fullscreen-spinner-modal',
            scrollable: true,
            fullscreen: true,
            keyboard: false,
            backdrop: 'static'
        });
        this.spinnerModalRef.componentInstance.modal = this.spinnerModalRef;
    }

    /* =========================================================================================================================== */
    /* ======================================================== CHECKER ========================================================== */
    /* =========================================================================================================================== */

    // Approve content
    approveContent(inode: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {};

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/approve`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Reject content
    rejectContent(inode: string, remark: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            cms_data: {},
            remarks: remark
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/reject`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    /* =========================================================================================================================== */
    /* ========================================================= MAKER =========================================================== */
    /* =========================================================================================================================== */

    // Cancel submission for approval
    cancelSubmitContent(inode: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/cancel-submission`, {}, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Delete draft
    deleteDraftContent(inode: string) {
        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/delete-draft`).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Delete content (languageId = 1 will delete ALL languages)
    deleteContent(inode: string, languageId: number) {
        let queryParams = new HttpParams();
        queryParams = queryParams.append("language", languageId);

        return this.http.delete<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/delete`, { params: queryParams }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Save draft
    draftContent(contentType: string, data: {}): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        let body = {
            "contentType": contentType,
            ...data,
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/create`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Save draft for new language (Except EN)
    draftDiffLanguageContent(contentType: string, data: any): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body = {
            "contentType": contentType,
            ...data
        };

        let queryParams = new HttpParams();
        queryParams = queryParams.append("id", data.identifier);

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/create-for-language`, body, { headers: header, params: queryParams }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get ONE content details based on inode
    getContent(inode: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/details`, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get ONE content details based on identifier
    getContentByIdentifier(identifier: string, language: number): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/id/${identifier}/${language}/details`, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Get version history for content
    getVersionHistory(inode: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });

        return this.http.get<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/version-history`, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Update draft
    updateDraftContent(contentType: string, data: {}, inode: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        let body = {
            "cms_data": {
                "contentType": contentType,
                ...data,
                "inode": inode,
            }
        };

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/update`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }

    // Submit for approval
    submitContent(inode: string, contentType: string, data: any, remark?: string): Observable<any> {
        const header = new HttpHeaders({
            'Content-Type': 'application/JSON'
        });
        const body: { cms_data: {}, remarks?: string } = {
            "cms_data": {
                "contentType": contentType,
                "inode": inode,
                ...data
            }
        };
        remark ? (body.remarks = remark) : null;

        return this.http.post<any>(`${environment.domain}${environment.prefixPath}${environment.adminPath}${environment.contentPath}/${inode}/submit-draft`, body, { headers: header }).pipe(
            map(res => {
                return res;
            })
        );
    }
}