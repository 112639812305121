<div [@fadeInOut]>
  <c-modal-header>
    <h5 cModalTitle>Upload Image</h5>
    <button cButtonClose (click)="dismissModal()"></button>
  </c-modal-header>
  <c-modal-body>
    <c-card>
      <c-card-body>
        <div class="d-flex flex-wrap align-items-center">
          <figure class="image-preview">
            <img [src]="imageToBeUploaded">
          </figure>
          <div class="flex-1 ps-3">
            <div class="mb-2">{{imageFilename}}</div>
            <app-text-input [config]="inputTagsConfig">
            </app-text-input>
          </div>
        </div>
      </c-card-body>
    </c-card>

    <!-- EXAMPLE: If have error, Tags input will be hidden -->
    <c-card>
      <c-card-body>
        <div class="d-flex flex-wrap align-items-center">
          <figure class="image-preview">
            <img [src]="imageToBeUploaded">
          </figure>
          <div class="flex-1 ps-3">
            <div class="mb-2">{{imageFilename}}</div>
            <div class="text-danger fs-small">Error message goes here</div>
          </div>
        </div>
      </c-card-body>
    </c-card>
    <!-- /EXAMPLE: If have error, Tags input will be hidden -->

  </c-modal-body>
  <c-modal-footer>
    <button cButton color="secondary" (click)="dismissModal()">Cancel</button>
    <button cButton color="primary" (click)="goToProcessImage()">Continue</button>
  </c-modal-footer>
</div>