<c-modal-header>
  <h5 cModalTitle>
    Cancel Updates Submission</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  This will cancel your current updates approval request and the content will be reverted to the last approved version,
  are you sure you want to continue?
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="confirmCancellation()">Yes, cancel this submission</button>
</c-modal-footer>