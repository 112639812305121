import { Component, Input, OnInit } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { FullscreenSpinnerComponent } from '../';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent } from "../../forms";
import { Router } from '@angular/router';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-upload-bap-topic-item-completion-list-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, FormsModule, TextInputComponent, CommonModule, IconModule],
  templateUrl: './upload-bap-topic-item-completion-list-modal.component.html',
  styleUrl: './upload-bap-topic-item-completion-list-modal.component.scss'
})
export class UploadBapTopicItemCompletionListModalComponent implements OnInit {
  @Input() modal: any;
  @Input() state!: string;

  formError: boolean = false;

  inputEmailConfig = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email',
    placeholder: '',
    disabled: true,
    maxLength: 100,
    value: 'john.doe@starbucks.com'
  };

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
  }


  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.openFullscreenSpinnerModal('UPLOAD_BAP_TOPIC_ITEM_COMPLETION_LIST');
    });

  }

  viewBapTopicItemCompletionList() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.router.navigateByUrl('/bap/bap-topic-item/completion-listing');
    });
  }

  downloadTemplate() {
    this.toastr.success('Document downloaded.');
  }

  openFullscreenSpinnerModal(event_session: string) {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = event_session;
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file.size > 2 * 1024 * 1024) { // 2MB
      alert('File is too large!');
      this.formError = true;
      return;
    } else {
      this.formError = false;
    }
  }
}
