import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-delete-admin-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule],
  templateUrl: './delete-admin-modal.component.html',
  styleUrl: './delete-admin-modal.component.scss'
})
export class DeleteAdminModalComponent {
  @Input() modal: any;
  @Input() name?: string;
  @Input() role?: string;
  @Input() email?: string;
  @Output() onConfirm = new EventEmitter<void>();

  dismissModal() {
    this.modal.dismiss();
  }

  confirmDeleted() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit();
    });
  }
}
