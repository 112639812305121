export const environment = {
    production: false,

    domain: "https://scc-dev-api.shoppertise.com",
    partnerDomain: "https://scc-dev-partner.shoppertise.com",
    clientAuthEnabled: true,
    clientApiKey: "c2J1eC1hZG1pbi13ZWI6MXFhelpBUSE=",

    prefixPath: "/api/v1",
    previewPath: "/preview",
    auditPath: "/audit",
    authPath: "/auth",
    assetPath: "/asset",
    adminPath: "/admin",
    accountPath: "/account",
    contentPath: "/content",
    contentTypePath: "/content-type",
    cmsPath: "/cms",
    partnerPath: "/partner",
    reportPath: "/report",
    userPath: "/user",
    workshopPath: "/workshop"
}