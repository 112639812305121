import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { FormComponent } from '../../forms/form/form.component';
import { FormFieldModel, InputRelationshipModel, formObjectModel } from 'src/app/_models/form-field.model';
import { AbstractControl, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FormService } from 'src/app/_services/form.service';
import { ToastrService } from 'ngx-toastr';
import { ContentService } from 'src/app/_services/content.service';
import { ErrorService } from 'src/app/_services/error.service';

@Component({
  selector: 'app-create-modal',
  standalone: true,
  templateUrl: './create-modal.component.html',
  styleUrl: './create-modal.component.scss',
  imports: [ModalModule, ButtonModule, FormComponent, CommonModule]
})

export class CreateModalComponent implements OnInit {
  @Input() modal: any;
  @Input() cms_content_type!: string;
  @Input() selectedData!: any;
  @Input() onComplete = new EventEmitter<any>();

  minDate: Date | null = null;

  // Form & Side Panel
  contentTypeName!: string;
  formControlError: boolean = false;
  firstFormGroup!: FormGroup;
  firstFormArray: formObjectModel<FormFieldModel>[] = [{ header: [], body: [] }];
  secondFormGroup!: FormGroup;
  secondFormArray: formObjectModel<FormFieldModel>[] = [{ header: [], body: [] }];

  constructor(
    private formService: FormService,
    private toastr: ToastrService,
    private contentService: ContentService,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.contentService.getContentField(this.cms_content_type, false, false).subscribe({
      next: res => {
        this.firstFormArray = res.formArray;
        this.secondFormArray = res.sidePanelArray;
        this.contentTypeName = res.contentTypeName.split(' ').filter((item: any) => item !== 'SBUX').join(' ');

        // Main Form
        this.firstFormGroup = new FormGroup({});
        this.firstFormArray.forEach((section: any) => {
          section.body.forEach((field: any) => {
            let validators = [];
            if (field.data.required) { // CMS Required
              validators.push(Validators.required);
            }
            if (field.data.regex) { // CMS RegEx Validation
              validators.push(Validators.pattern(field.data.regex));
            }
            this.addFormControlSwitchCase(this.firstFormGroup, field, validators);
          });
        });

        // Side Panel
        this.secondFormGroup = new FormGroup({});
        this.secondFormArray.forEach((section: any) => {
          section.body.forEach((field: any) => {
            let validators = [];
            if (field.data.required) { // CMS Required
              validators.push(Validators.required);
            }
            if (field.data.regex) { // CMS RegEx Validation
              validators.push(Validators.pattern(field.data.regex));
            }
            this.addFormControlSwitchCase(this.secondFormGroup, field, validators);
          });
        })
      }
    });
  }

  addFormControlSwitchCase(formGroup: FormGroup, field: any, validators: any) {
    switch (field.type) {
      case 'IMAGE':
        formGroup.addControl(
          field.data.id, new FormControl(
            field.data.value.url ?? '', validators
          )
        );
        break;
      case 'DATETIME':
        if (!field.data.picker_mode_single) {
          const arrayLengthValidator = (singlePicker: boolean): ValidatorFn => {
            return (control: AbstractControl): { [key: string]: any } | null => {
              const controlValue = control.value;
              if (Array.isArray(controlValue) && !singlePicker && (controlValue.length === 1 || controlValue.includes(null))) {
                return { 'arrayLengthInvalid': true };
              }
              return null;
            };
          };
          validators.push(arrayLengthValidator(field.data.picker_mode_single));
        }
        formGroup.addControl(
          field.data.id, new FormControl(
            field.data.value ?? this.getFormControlDefaultValue(field.data, field.type), validators
          )
        );
        break;
      default:
        formGroup.addControl(
          field.data.id, new FormControl(
            field.data.value ?? this.getFormControlDefaultValue(field.data, field.type), validators
          )
        );
        break;
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }

  getFormControlDefaultValue(data: any, type: string) {
    switch (type) {
      case 'RELATIONSHIP':
        if (data.selected_data.length > 0) {
          return data.selected_data;
        } else {
          return [];
        }
      case 'DATETIME':
        return [];
      default:
        return '';
    }
  }

  getFormField(array: formObjectModel<FormFieldModel>[], controlName: string) {
    for (let data of array) {
      const foundObject = data.body.find(item => (item.data as any).id === controlName);
      if (foundObject) {
        return foundObject;
      }
    }
    return undefined;
  }


  saveDraft() { // Save Draft btn
    this.formControlError = false;

    let errorPromise = new Promise<{ hasError: boolean, mainFormErrorDesc: string, secondFormErrorDesc: string }>((resolve, reject) => {
      let mainFormErrorList = '';
      let secondFormErrorList = '';
      // Main form
      Object.keys(this.firstFormGroup.controls).forEach(controlName => {
        const control = this.firstFormGroup.get(controlName);
        if (control && control.errors) {
          const errorList = Object.keys(control.errors);
          const controlObj = this.getFormField(this.firstFormArray, controlName);
          if (controlObj) {
            this.formControlError = true;
            mainFormErrorList += `\n- ${(controlObj.data as any).name}`;
            (controlObj.data as any).error = errorList;
          }
        }
      });

      // Side Panel form
      Object.keys(this.secondFormGroup.controls).forEach(controlName => {
        const control = this.secondFormGroup.get(controlName);
        if (control && control.errors) {
          const errorList = Object.keys(control.errors);
          const controlObj = this.getFormField(this.secondFormArray, controlName);
          if (controlObj) {
            this.formControlError = true;
            secondFormErrorList += `\n- ${(controlObj.data as any).name}`;
            (controlObj.data as any).error = errorList;
          }
        }
      });
      resolve({ hasError: this.formControlError, mainFormErrorDesc: mainFormErrorList, secondFormErrorDesc: secondFormErrorList });
    });

    errorPromise.then((data) => {
      if (data.hasError) {
        const errorMessage = data.mainFormErrorDesc === '' && data.secondFormErrorDesc === '' ? '' : `Error field:${data.mainFormErrorDesc}${data.secondFormErrorDesc}`;
        this.errorService.openGenericErrorModal(errorMessage);

      } else {
        this.contentService.openFullscreenSpinnerModal();
        const mainFormJSON = this.formService.formatFormData(this.firstFormArray);
        const sidePanelJSON = this.formService.formatFormData(this.secondFormArray);

        this.contentService.draftContent(this.cms_content_type, { ...mainFormJSON, ...sidePanelJSON }).subscribe({
          next: res => {
            this.contentService.spinnerModalRef.dismiss();
            if (!res.data) {
              this.errorService.openGenericErrorModal(res.error, res.error_code);
              return;
            }

            this.contentService.getContent(res.data).subscribe({
              next: res => {
                this.onComplete.emit({ formControlName: '', value: res });
              }
            })
            this.toastr.success('Record saved!');
          },
          error: err => {
            console.log(err);
            this.errorService.openGenericErrorModal('Record failed to save!');
            this.contentService.spinnerModalRef.dismiss();
          },
          complete: () => {
            this.modal.dismiss();
          }
        });
      }
    })
  }

  emptyValueSwitchCase(field: string) {
    switch (field.toUpperCase()) {
      case 'TEXT':
      case 'TEXETAREA':
      case 'IMAGE':
      default:
        return ''
      case 'DATETIME':
        return []
      case 'VIDEO':
        return { url: '', duration: 0 };
      case 'TEXTEDITOR':
        return { content: '', duration: 0 };
    }
  }

  setMainFormValue(data: { formControlName: string, value: string | Array<Date | string> | InputRelationshipModel["selected_data"] | { url: string, duration: number } | { content: string, duration: number } }) {
    this.firstFormGroup.controls[data.formControlName].setValue(data.value);

    // Check current updated field is triggerer
    const triggerMainSection = this.firstFormArray.filter(section => section.containTrigger!.includes(data.formControlName));
    if (triggerMainSection.length > 0) {
      triggerMainSection.forEach((section: any) => {
        section.body.forEach((field: any) => {
          if (field.trigger && field.data.value !== "") {
            ({ firstFormArray: this.firstFormArray, secondFormArray: this.secondFormArray } = this.formService.setShowOrHideField(field.data.id, field.data.value, this.firstFormArray, this.secondFormArray));
          }
        });
      })
    }
  }

  setSidePanelFormValue(data: { formControlName: string, value: string | Array<Date | string> | InputRelationshipModel["selected_data"] | { url: string, duration: number } | { content: string, duration: number } }) {
    this.secondFormGroup.controls[data.formControlName].setValue(data.value);
  }
}
