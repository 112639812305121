import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule, GridModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { IconModule } from '@coreui/icons-angular';
import { RouterLink } from '@angular/router';
import { CMSContentPath } from 'src/app/enum/content.enum';

@Component({
  selector: 'app-view-asset-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, IconModule, GridModule, RouterLink],
  templateUrl: './view-asset-modal.component.html',
  styleUrl: './view-asset-modal.component.scss'
})
export class ViewAssetModalComponent {
  @Input() modal: any;
  @Input() asset_data!: any;
  @Output() onRemoveSelected = new EventEmitter<string>();

  constructor(
    private toastr: ToastrService
  ) { }

  contentPath(contentType: string, inode: string) {
    const contentPath = CMSContentPath[contentType.toUpperCase() as keyof typeof CMSContentPath];
    return `${contentPath}/${inode}`;
  }

  copyLink(url: string) {
    navigator.clipboard.writeText(url);
    this.toastr.success('Link copied to clipboard');
  }

  downloadAsset(url: string) {
    document.getElementById('link')!.click();
  }

  dismissModal() {
    this.modal.dismiss();
  }

  deleteAsset(id: string) {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onRemoveSelected.emit(id);
    });
  }

  formatDateTime(dateStr: string): string {
    const date = new Date(dateStr);
    const timeOptions: Intl.DateTimeFormatOptions = {
      timeZoneName: 'short',
      hour: 'numeric',
      minute: 'numeric',
      hour12: false
    };
    const dateOptions: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    };
    const userTimeZone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Create a formatter for time
    const timeFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
      ...timeOptions,
      timeZone: userTimeZone
    });

    // Create a formatter for date
    const dateFormatter: Intl.DateTimeFormat = new Intl.DateTimeFormat('en-US', {
      ...dateOptions,
      timeZone: userTimeZone
    });

    // Format the time and date separately
    const formattedTime: string = timeFormatter.format(date);
    const formattedDate: string = dateFormatter.format(date);
    return `${formattedTime}, ${formattedDate}`;
  }

  fileSizeConversion(bytes: number): string {
    if (bytes >= 1024 * 1024) {
      const megabytes = bytes / (1024 * 1024);
      return `${megabytes.toFixed(2)} MB`;
    } else if (bytes >= 1024) {
      const kilobytes = bytes / 1024;
      return `${kilobytes.toFixed(2)} KB`;
    } else {
      return `${bytes} B`;
    }
  }
}
