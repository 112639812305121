<div class="text-input">
  <label *ngIf="data.label" cLabel [for]="data.id">
    {{data.label}}
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>
  <c-input-group class="mb-3">
    <input cFormControl [type]="data.type || 'text'" [attr.id]="data.id ? data.id : null"
      [attr.name]="data.name ? data.name : null" [attr.maxlength]="data.max_length ? data.max_length : null"
      [placeholder]="data.placeholder ? data.placeholder : 'Enter here'" [value]="data.value"
      [disabled]="data.disabled === undefined ? false : data.disabled" />
    <button class="btn" [class.btn-primary]="!data.button.outline_button"
      [class.btn-outline-primary]="data.button.outline_button" (click)="onButtonClick()"
      [disabled]="data.button.disabled">{{data.button.label}}</button>
  </c-input-group>

  <div *ngIf="data.error_message" class="form-error mt-2">{{data.error_message}}</div>
</div>