<c-modal-header>
  <h5 cModalTitle>Manage {{config.content_name}} Order</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="d-flex align-items-center fst-italic gray-600">
    Click and hold the handler <span class="d-flex mx-2"><svg cIcon size="lg" name="cilHamburgerMenu"></svg></span> to
    drag
    and
    re-order the content.
  </div>
  <div *ngIf="config.selected_data" class="content-list mt-3">
    <ul class="list-unstyled draggable-list" cdkDropList (cdkDropListDropped)="contentListCardDrop($event)">

      <li class="draggable-item mw-100" *ngFor="let item of config.selected_data" cdkDrag cdkDragLockAxis="y">
        <div class="draggable-placeholder mw-100" *cdkDragPlaceholder>
          <span></span>
        </div>
        <div class="card bg-gray-light">
          <div class="card-body d-flex flex-wrap position-relative">

            <div class="content-wrapper">
              <figure *ngIf="item.img_url" class="image-preview">
                <img [src]="item.img_url">
              </figure>
              <div class="info" [class.ps-0]="!item.img_url">
                <div *ngIf="item.name" class="name"><strong>{{ item.name }}</strong></div>
                <div *ngIf="item.id" class="id fs-smaller gray-500">{{ item.id }}</div>
                <div *ngIf="item.active_period" class="active-period fs-small gray-800 mt-2">
                  {{item.active_period}}
                </div>

              </div>
            </div>

            <div *ngIf="config.disabled === false" class="handler">
              <button cButton variant="outline" class="btn-draggable-handler btn-square" cdkDragHandle>
                <svg cIcon size="lg" name="cilHamburgerMenu"></svg>
              </button>
            </div>

          </div>
        </div>
      </li>
    </ul>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton variant="ghost">Reset to default order</button>
  <button cButton color="primary" (click)="submit()">Submit</button>
</c-modal-footer>