export enum InputField {
    ROW = 'ROW',
    COLUMN = 'COLUMN',
    TEXT = 'TEXT',
    TEXTAREA = 'TEXTAREA',
    TEXTEDITOR = 'TEXTEDITOR',
    TEXTBUTTON = 'TEXTBUTTON',
    LINEDIVIDER = 'LINEDIVIDER',
    RADIO = 'RADIO',
    IMAGE = 'IMAGE',
    DATE = 'DATE',
    DATETIME = 'DATETIME',
    CHECKBOX = 'CHECKBOX',
    SELECT = 'SELECT',
    RELATIONSHIP = 'RELATIONSHIP',
    SELECTSEARCH = 'SELECTSEARCH',
    VIDEO = 'VIDEO'
}