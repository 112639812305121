import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ButtonModule, FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker'; // https://github.com/danielmoncada/date-time-picker
import { OwlMomentDateTimeModule } from '@danielmoncada/angular-datetime-picker-moment-adapter';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormFieldModel, InputDateTimeModel } from 'src/app/_models/form-field.model';

const MY_MOMENT_FORMATS = {
  parseInput: 'D/M/YYYY h:mmA',
  fullPickerInput: 'D/M/YYYY h:mmA',
  datePickerInput: 'D/M/YYYY',
  timePickerInput: 'HH:mm:ss',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY',
};

@Component({
  selector: 'app-datetimerangepicker-input',
  standalone: true,
  imports: [OwlDateTimeModule, OwlNativeDateTimeModule, FormModule, CommonModule, OwlMomentDateTimeModule, ReactiveFormsModule, FormsModule, ButtonModule],
  templateUrl: './datetimerangepicker-input.component.html',
  styleUrl: './datetimerangepicker-input.component.scss',
  providers: [
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_MOMENT_FORMATS }
  ]
})
export class DatetimerangepickerInputComponent {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string[] }>();
  data: InputDateTimeModel = {
    id: '',
    required: false,
    value: []
  };

  ngOnInit() {
    this.data = this.config as InputDateTimeModel;
  }

  setNewValue(e: any) {
    const dateTimeArr = this.getAllDateTimeValue(e);

    // Check To & From for range
    if (!this.data.picker_mode_single && (dateTimeArr[0] === null || dateTimeArr[1] === null)) {
      this.data.error = ['arrayLengthInvalid'];
      this.data.value = [];
      return;
    }

    // Check max range
    if (this.data.max_date && dateTimeArr.length === 2 && dateTimeArr[0] !== null && dateTimeArr[1] !== null && dateTimeArr[1].diff(dateTimeArr[0], this.data.max_date.unit, true) > this.data.max_date.count) {
      this.data.error = ['range'];
      this.data.error_message = `The select date range exceed the maximum of ${this.data.max_date.count} ${this.data.max_date.unit}, please choose again.`;
      this.data.value = [];
      return;
    }
    this.onChange.emit({ formControlName: this.data.id, value: dateTimeArr });
  }

  getAllDateTimeValue(data: any) {
    if (Array.isArray(data) && data.length && data.length === 2) {
      return data.map((dateTime: any) => dateTime);
    }
    return [data];
  }
}
