import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'truncateMiddle'
})
export class TruncateMiddlePipe implements PipeTransform {
  transform(value: string, maxLength: number = 30): string {
    if (!value) {
      return '';
    }

    if (value.length <= maxLength) {
      return value;
    }

    const fileName = value.substring(0, value.lastIndexOf('.')); // Extract the file name (without extension)
    const extension = value.substring(value.lastIndexOf('.')); // Extract the file extension

    const visibleLength = maxLength - 3; // Reserve space for '...'
    const startLength = Math.ceil(visibleLength / 2); // Calculate the number of characters to show at the start
    const endLength = Math.floor(visibleLength / 2); // Calculate the number of characters to show at the end

    const truncated =
      fileName.substring(0, startLength) + ' ... ' + fileName.substring(fileName.length - endLength) + extension;

    return truncated;
  }
}
