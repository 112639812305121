<c-modal-header>
  <h5 cModalTitle>Create Workshop</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="mb-4">
    <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
  </div>
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Information</h3>
      <p class="mb-0">Set-up the information for this learning lesson</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputContentIDConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="list-filter-active-period">Publish Period
          <div class="form-sublabel">
            Specify the active dates for this content. Content will only be published and visible during this period.
          </div>
        </label>
        <c-form-check>
          <input cFormCheckInput id="same-as-topic-checkbox" type="checkbox">
          <label cFormCheckLabel for="same-as-topic-checkbox">Same as parent content</label>
        </c-form-check>

        <app-datetimerangepicker-input [config]="inputPublishPeriodConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-radio-input [config]="inputTypeConfig">
        </app-radio-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="partner-data-report-date-range-input">
          Date & time
          <div class="form-sublabel">
            Set up the workshop date period
          </div>
        </label>
        <input cFormControl type="text" id="partner-data-report-date-range-input"
          [owlDateTimeTrigger]="eventPeriodDatePicker" [owlDateTime]="eventPeriodDatePicker" [selectMode]="'range'"
          [min]="minDate" placeholder="Select date period" autocomplete="off" />
        <owl-date-time #eventPeriodDatePicker [hour12Timer]="true"></owl-date-time>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputCoverImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputLargeBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputMobileBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTagsConfig">
        </app-text-input>
      </div>
    </div>
  </div>

  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Workshop details</h3>
      <p class="mb-0">Set-up the workshop details</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-editor [config]="inputWorkshopContentConfig">
        </app-text-editor>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputVideoConferenceConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputEventLocationConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <div class="mw-md-50">
          <app-text-input [config]="inputParticipantLimitConfig">
          </app-text-input>
        </div>
      </div>
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()">Submit</button>
</c-modal-footer>