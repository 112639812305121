<c-modal-header>
  <h5 cModalTitle>
    {{hasPublished ? 'Reject Update':'Reject Content'}}
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  Are you sure you want to reject this {{hasPublished ? 'update':'content'}}?

  <div class="form-group mt-3">
    <label cLabel for="changelog-input">Reason</label>
    <textarea cFormControl id="changelog-input" rows="4" [(ngModel)]="changeLogs"></textarea>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="danger" (click)="submit()" [disabled]="!changeLogs">Yes, reject this
    {{hasPublished ? 'update':'content'}}</button>
</c-modal-footer>