import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GenericErrorModalComponent } from '../components/modal';

@Injectable({
    providedIn: 'root'
})
export class ErrorService {

    constructor(
        private modalService: NgbModal
    ) { }

    public getFrontendErrorMapping(id: string): string {
        switch (id) {
            case "5000":
                return "Your password doesn't match one of the requirement, please try a new one.";
            case "5001":
                return "Password does not match. Please try again.";
            case "5002":
                return "Your email might be incorrect. Double-check the format (e.g., email@starbucks.com) and try again.";
            default:
                return "Error";
        }
    };

    openGenericErrorModal(message: string, code?: string) {
        const modalRef = this.modalService.open(GenericErrorModalComponent, {
            centered: true,
            windowClass: 'discard-draft-confirmation-modal',
            scrollable: true,
            size: 'lg'
        });
        modalRef.componentInstance.modal = modalRef;
        modalRef.componentInstance.errorMessage = message;
        modalRef.componentInstance.errorCode = code;
    }
}
