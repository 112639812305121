<c-modal-header>
  <h5 cModalTitle>
    {{hasPublished? 'Approve Update': 'Approve Content'}}
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  Are you sure you want to approve this {{isContentOrder ? 'content order' : (hasPublished ? 'update' : 'content')}}?
  This {{isContentOrder ? 'content order' : (hasPublished ? 'update' : 'content')}} will be
  {{isContentOrder ? 'applied automatically to the current published content.' : 'published automatically based on the preset active period.'}}
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()">Yes, approve this
    {{isContentOrder ? 'content order' : (hasPublished ? 'update' : 'content')}}</button>
</c-modal-footer>