import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormModule, ButtonModule, GridModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { FormFieldModel, InputRelationshipModel } from 'src/app/_models/form-field.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CreateModalComponent, SelectContentModalComponent } from '../../modal';
import { AuthService } from 'src/app/_services/auth.service';
import { ContentService } from 'src/app/_services/content.service';
import { RouterLink } from '@angular/router';

interface selectedPerson {
  id: string;
  name: string;
  market?: string;
  img_url?: string;
  status?: string
}

@Component({
  selector: 'app-person-selector',
  standalone: true,
  imports: [FormModule, CommonModule, ButtonModule, DragDropModule, IconModule, GridModule, RouterLink],
  templateUrl: './person-selector.component.html',
  styleUrl: './person-selector.component.scss'
})
export class PersonSelectorComponent implements OnInit {
  @Input() config!: FormFieldModel;
  data!: InputRelationshipModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: InputRelationshipModel["selected_data"] }>();

  adminAllowedLanguage !: number[];

  constructor(
    private modalService: NgbModal,
    private authService: AuthService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.adminAllowedLanguage = this.authService.getUserAllowedLanguage();

    this.data = this.config as InputRelationshipModel;

    let tempData = this.config as InputRelationshipModel;
    tempData.selected_data.forEach((data: any, index) => {
      this.contentService.getContent(data.id).subscribe({
        next: res => {
          if (res.data) {
            // Set hasPublish
            this.data.selected_data[index].hasPublished = res.data.api_data.has_published;
          } else {
            // Remove person if not found
            this.data.selected_data.splice(index, 1);
          }
        }
      })
    });
  }

  contentListCardDrop(event: CdkDragDrop<selectedPerson[]>) {
    if (this.data.selected_data) {
      moveItemInArray(this.data.selected_data, event.previousIndex, event.currentIndex);
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
    }
  }

  // @Output() selectButtonClick: EventEmitter<any> = new EventEmitter();
  @Output() createButtonClick: EventEmitter<any> = new EventEmitter();


  onSelectButtonClick() {
    // this.selectButtonClick.emit();
    this.openSelectContentModal('SELECT_CONTENT');
  }

  onCreateButtonClick() {
    // this.createButtonClick.emit();
    this.openCreateContentModal('CREATE_PERSON');
  }

  onRemoveButtonClick(id: string) {
    const index = this.data.selected_data.findIndex(data => data.id === id);
    this.data.selected_data.splice(index, 1);
    this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
    // this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data.map(item => item.id) });
  }

  openSelectContentModal(event_session: string) {
    const modalRef = this.modalService.open(SelectContentModalComponent, {
      centered: true,
      windowClass: 'select-content-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
    modalRef.componentInstance.relationship = { cardinality: this.data.related!.cardinality, contentType: this.data.related!.contentType, formControlName: this.data.id };
    modalRef.componentInstance.selectedData = this.data.selected_data;
    modalRef.componentInstance.onChange.subscribe((value: any) => {
      const cmsData = value.value.contentData.cms_data;
      const apiData = value.value.contentData.api_data;

      this.data.selected_data.push({
        id: cmsData.inode,
        identifier: cmsData.identifier,
        name: cmsData.title,
        tags: cmsData.tags,
        url: cmsData.coverImage || cmsData.profileImage || '',
        status: cmsData.approvalStatus,
        hasPublished: apiData.has_published
      })
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
      // this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data.map(item => item.id) });
    });
    modalRef.componentInstance.onRemove.subscribe((value: any) => {
      this.onRemoveButtonClick(value);
    });
  }
  openCreateContentModal(event_session: string) {
    const modalRef = this.modalService.open(CreateModalComponent, {
      centered: true,
      windowClass: 'create-content-modal',
      modalDialogClass: 'modal-fullscreen-md-down',
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
    modalRef.componentInstance.cms_content_type = this.data.related?.contentType;
    modalRef.componentInstance.onComplete.subscribe((value: any) => {
      const data = value.value.data.cms_data;

      this.data.selected_data.push({
        id: data.inode,
        identifier: data.identifier,
        name: data.title,
        tags: data.tags,
        url: data.coverImage || data.profileImage || '',
        status: data.approvalStatus,
        hasPublished: false
      })
      this.onChange.emit({ formControlName: this.data.id, value: this.data.selected_data });
    });
  }
}
