import { Component, Input, Output, EventEmitter, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormModule, GridModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { SelectAssetComponent, AddAssetsComponent } from '../../modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldModel, InputImageModel } from 'src/app/_models/form-field.model';
import { UploadAssetModel } from 'src/app/_models/asset.model';

@Component({
  selector: 'app-image-input',
  standalone: true,
  imports: [FormModule, CommonModule, GridModule, IconModule, ButtonModule],
  templateUrl: './image-input.component.html',
  styleUrl: './image-input.component.scss'
})

export class ImageInputComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  @Output() selectedProfileImage = new EventEmitter<string>();
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  data!: InputImageModel;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit() {
    this.data = this.config as InputImageModel;
  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    const assetsData: UploadAssetModel[] = [];
    const maxFileSize = 10 * 1024 * 1024; // 10MB
    const reader = new FileReader();

    reader.onload = (e: any) => {
      const imageUrl = e.target.result;
      const fileName = file.name;
      const fileSize = file.size;
      assetsData.push({ file: file, asset_url: imageUrl, file_name: fileName, file_type: 'IMAGE', tags: '', exceedSizeLimit: fileSize > maxFileSize, unsupportedFileType: false });
      if (imageUrl !== null) {
        this.openUploadImageModal(assetsData);
      }
    };
    reader.readAsDataURL(file);
  }

  openUploadImageModal(assetsData: UploadAssetModel[]) {
    const modalRef = this.modalService.open(AddAssetsComponent, {
      centered: true,
      windowClass: 'add-assets-modal',
      scrollable: true,
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.assetsToBeUploaded = assetsData;
    modalRef.componentInstance.onUploaded.subscribe((value: any) => {
      this.data.value.url = value.url;
      this.data.value.id = value.id;
      this.onChange.emit({ formControlName: this.data.id, value: value.url });
    })
    modalRef.componentInstance.onDismissed.subscribe(() => {
      this.fileInput.nativeElement.value = "";
    })
  }

  openSelectAssetModal() {
    const modalRef = this.modalService.open(SelectAssetComponent, {
      centered: true,
      windowClass: 'select-asset-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.asset_type = 'IMAGE';
    modalRef.componentInstance.selected_data = this.data.value.id ? [this.data.value] : [];
    modalRef.componentInstance.onChange.subscribe((value: any) => {
      this.data.value.url = value.url;
      this.data.value.id = value.id;
      this.onChange.emit({ formControlName: this.data.id, value: value.url });
    });
    modalRef.componentInstance.onRemove.subscribe((value: string) => {
      this.onRemoveButtonClick();
    })
  }

  onRemoveButtonClick() {
    this.data.value.url = '';
    this.data.value.id = '';
    this.onChange.emit({ formControlName: this.data.id, value: '' });
  }
}
