import { Component, Input, EventEmitter, Output, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormModule, ButtonModule, GridModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { TextInputComponent } from "../text-input/text-input.component";
import { AddAssetsComponent, SelectAssetComponent } from '../../modal';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormFieldModel, InputRelationshipModel, InputTextModel } from 'src/app/_models/form-field.model';
import { UploadAssetModel } from 'src/app/_models/asset.model';
import { AssetService } from 'src/app/_services/asset.service';
import { RouterLink } from '@angular/router';
import { firstValueFrom } from 'rxjs';

interface selectedDocument {
  id: string;
  name: string;
  display_name?: string;
}

@Component({
  selector: 'app-document-selector',
  standalone: true,
  templateUrl: './document-selector.component.html',
  styleUrl: './document-selector.component.scss',
  imports: [FormModule, CommonModule, ButtonModule, DragDropModule, IconModule, GridModule, TextInputComponent, RouterLink]
})
export class DocumentSelectorComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: InputRelationshipModel["selected_data"] }>();
  data?: InputRelationshipModel;
  inputDisplayNameConfigArr: InputTextModel[] = [];
  selectedDataLength: number = 0;
  index: number = 0;
  indexToBeRemove: number[] = [];

  constructor(
    private modalService: NgbModal,
    private assetService: AssetService
  ) { }

  async ngOnInit(): Promise<void> {
    try {
      let tempData = this.config as InputRelationshipModel;

      // construct inputDisplaynameConfigArr
      for (let document of tempData.selected_data) {
        await this.checkDocumentServerName(document);
      }

      // Remove document that does not have serverName && ID can't be found.
      this.indexToBeRemove.forEach((index: number) => {
        (this.config as InputRelationshipModel).selected_data.splice(index, 1);
        this.inputDisplayNameConfigArr.splice(index, 1);
      })

      this.data = this.config as InputRelationshipModel;

    } catch (error) {
      console.log('There is an issue with Document selector');
    }
  }

  async checkDocumentServerName(document: any) {

    if (!document.name) { // No serverName in CMS
      const dataRes = await firstValueFrom(this.assetService.getAssetDetailById(document.id));

      if (dataRes.data) { // Found data
        const v = dataRes.data;

        // Set name for selected_data
        document.name = v.name;

        // Display name configuration
        this.inputDisplayNameConfigArr[this.index] = {
          id: document.id,
          name: v.name,
          placeholder: 'Display name',
          max_length: 100,
          value: document.displayName,
          disabled: document.disabled,
          required: false
        }
      } else {

        // Mark index of document to be remove
        this.indexToBeRemove.push(this.index);
      }

    } else {

      // Display name configuration
      this.inputDisplayNameConfigArr[this.index] = {
        id: document.id,
        name: document.name,
        placeholder: 'Display name',
        max_length: 100,
        value: document.displayName,
        disabled: (this.config as InputRelationshipModel).disabled,
        required: false
      }
    }
    this.index++;
  }

  contentListCardDrop(event: CdkDragDrop<selectedDocument[]>) {
    if (this.data!.selected_data) {
      moveItemInArray(this.data!.selected_data, event.previousIndex, event.currentIndex);
      this.onChange.emit({ formControlName: this.data!.id, value: this.data!.selected_data });
    }
  }

  getFileTypeFromExtension(fileName: string): string | null {
    const extension = fileName.split('.').pop()?.toLowerCase(); // Get the file extension (in lowercase)

    switch (extension) {
      case 'doc':
      case 'docx':
        return 'DOC';
      case 'xls':
      case 'xlsx':
        return 'XLS';
      case 'csv':
        return 'CSV';
      case 'zip':
        return 'ZIP';
      case 'txt':
        return 'TXT';
      case 'pdf':
        return 'PDF';
      default:
        return null; // Unsupported file type
    }
  }

  onFileSelected(event: any) {
    const files: FileList = event.target.files;
    const maxFileSize = 10 * 1024 * 1024; // 10MB

    const assetsData: UploadAssetModel[] = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const fileType = this.getFileTypeFromExtension(file.name);
      const reader = new FileReader();

      reader.onload = (e: any) => {
        // const asset_url = e.target.result;
        const fileName = file.name;
        const fileSize = file.size;
        assetsData.push({ file: file, file_name: fileName, file_type: fileType ?? undefined, tags: '', exceedSizeLimit: fileSize > maxFileSize, unsupportedFileType: fileType === null });

        // If all files are processed, open the modal with the collected assets data
        if (assetsData.length === files.length) {
          this.openAddAssetsModal(assetsData);
          console.log(assetsData)
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onRemoveButtonClick(id: string) {
    const index = this.data!.selected_data.findIndex(data => data.id === id);
    this.data!.selected_data.splice(index, 1);
    this.inputDisplayNameConfigArr.splice(index, 1);
    this.onChange.emit({ formControlName: this.data!.id, value: this.data!.selected_data });
  }

  openSelectAssetModal() {
    const modalRef = this.modalService.open(SelectAssetComponent, {
      centered: true,
      windowClass: 'select-asset-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.asset_type = 'DOCUMENT';
    modalRef.componentInstance.selected_data = this.data!.selected_data;
    modalRef.componentInstance.onChange.subscribe((value: any) => {
      this.data!.selected_data.push({
        contentLink: value.id,
        id: value.id,
        name: value.name,
        displayName: '',
        url: value.url
      })

      this.inputDisplayNameConfigArr.push({
        id: value.id,
        name: value.name,
        placeholder: 'Display name',
        max_length: 100,
        value: '',
        disabled: this.data!.disabled ?? false,
        required: false
      })

      this.onChange.emit({ formControlName: this.data!.id, value: this.data!.selected_data });
    });
    modalRef.componentInstance.onRemove.subscribe((value: string) => {
      this.onRemoveButtonClick(value);
    })
  }

  openAddAssetsModal(assetsData: UploadAssetModel[]) {
    const modalRef = this.modalService.open(AddAssetsComponent, {
      centered: true,
      windowClass: 'add-assets-modal',
      scrollable: true,
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.assetsToBeUploaded = assetsData;
    modalRef.componentInstance.onUploaded.subscribe((value: any) => {
      // Add into Selected Data Array
      this.data!.selected_data.push({
        contentLink: value.id,
        id: value.id,
        name: value.name,
        displayName: '',
        url: value.url
      })

      // Create Display Name text field
      this.inputDisplayNameConfigArr.push({
        id: value.id,
        name: value.name,
        placeholder: 'Display name',
        max_length: 100,
        value: '',
        disabled: this.data!.disabled ?? false,
        required: false
      })

      this.onChange.emit({ formControlName: this.data!.id, value: this.data!.selected_data });
    })
    modalRef.componentInstance.onDismissed.subscribe(() => {
      this.fileInput.nativeElement.value = "";
    })
  }

  setFormValue(data: { formControlName: string, value: string }, index: number) {
    this.data!.selected_data[index].displayName = data.value;
    this.onChange.emit({ formControlName: this.data!.id, value: this.data!.selected_data });
  }
}
