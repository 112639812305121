<c-modal-header>
  <h5 cModalTitle>
    Rename Folder</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="form-group">
    <label cLabel for="folder-name-input">Folder name. Max 50 characters</label>
    <input cFormControl id="folder-name-input" maxlength="50" [(ngModel)]="folderNameValue">
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()" [disabled]="!folderNameValue">Submit</button>
</c-modal-footer>