<c-modal-header>
  <h5 cModalTitle>
    Add New Language</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="form-group mb-4">
    <app-select-menu-input [config]="inputLanguageConfig">
    </app-select-menu-input>
  </div>
  <div class="form-group">
    <app-radio-input [config]="inputOptionsConfig">
    </app-radio-input>
  </div>

</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" [disabled]="inputLanguageConfig.value === '' || inputOptionsConfig.value === ''"
    (click)="confirmAddLanguage()">Confirm</button>
</c-modal-footer>