import { Component, Input } from '@angular/core';
import { ModalModule } from '@coreui/angular';
@Component({
  selector: 'app-fullscreen-spinner',
  standalone: true,
  imports: [ModalModule],
  templateUrl: './fullscreen-spinner.component.html',
  styleUrl: './fullscreen-spinner.component.scss'
})
export class FullscreenSpinnerComponent {
  @Input() modal: any;

  dismissModal() {
    this.modal.dismiss();
  }
}
