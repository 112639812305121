import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-reject-content-confirmation-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, FormsModule],
  templateUrl: './reject-content-confirmation-modal.component.html',
  styleUrl: './reject-content-confirmation-modal.component.scss'
})
export class RejectContentConfirmationModalComponent {
  @Input() modal: any;
  @Input() hasPublished!: string;
  @Output() onConfirm = new EventEmitter<string>();
  changeLogs: string = '';

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit(this.changeLogs);
    });
  }
}
