import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalModule, ButtonModule, FormModule, GridModule } from '@coreui/angular';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { TruncateMiddlePipe } from 'src/app/pipe/truncate-middle.pipe';
import { IconModule } from '@coreui/icons-angular';
import { ListTableComponent } from "../../../components/list-table/list-table.component";
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { AssetService } from 'src/app/_services/asset.service';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AssetModel } from 'src/app/_models/asset.model';
import { debounceTime, distinctUntilChanged } from 'rxjs';
import { ErrorService } from 'src/app/_services/error.service';
import { SpinnerIcon } from 'src/app/icons/icon-spinner';
import { ListPaginationComponent } from '../../list-pagination/list-pagination.component';

@Component({
  selector: 'app-select-asset',
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, ModalModule, ButtonModule, FormModule, GridModule, CommonModule, IconModule, RouterLink, ListTableComponent, SpinnerIcon, ListPaginationComponent],
  templateUrl: './select-asset.component.html',
  styleUrl: './select-asset.component.scss',
  providers: [TruncateMiddlePipe]
})

export class SelectAssetComponent implements OnInit {
  @Input() asset_type!: string;
  @Input() modal!: any;
  @Input() selected_data!: string[];
  @Output() onChange = new EventEmitter<AssetModel>();
  @Output() onRemove = new EventEmitter<string>();

  constructor(
    private truncateMiddlePipe: TruncateMiddlePipe,
    private assetService: AssetService,
    private errorService: ErrorService
  ) { }

  contentData: any[] = [];
  currentPage: number = 1;
  dynamicAssetData: AssetModel[] = [];
  dynamicTableBodyData: TableBodyDataModel[][] = [];
  folderPath: boolean = true;
  isLoading: boolean = false;
  pathArr: string[] = [''];
  searchResults: boolean = false;
  searchInput = new FormControl('');
  selectedDataId: string[] = [];
  showAsset: number = 15;
  sortAsset: string = '0';
  totalAsset: number = 0;
  totalPage: number = 1;
  tableHeadData = [
    {
      'value': 'File',
      'class': 'col-wide-md asset-file'
    },
    {
      'value': 'Tags',
      'class': 'col-wide-md'
    },
    {
      'value': 'Location'
    },
    {
      'value': 'Actions',
      'class': 'col-wide-sm actions'
    }
  ];


  ngOnInit(): void {
    this.searchInput.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged()
    ).subscribe(value => {
      this.currentPage = 1;
      this.executeSearch(value);
    });
    this.getAssetsData();

    if (this.selected_data && this.selected_data.length > 0) {
      this.selectedDataId = this.selected_data.map((data: any) => data.id);
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }

  executeSearch(searchTerm: string | null): void {
    if (searchTerm) {
      this.isLoading = true;
      this.assetService.searchAsset(searchTerm, this.showAsset, this.currentPage, this.sortAsset, this.asset_type ?? undefined).subscribe({
        next: res => {
          const tableBodyData: TableBodyDataModel[][] = [];
          this.contentData = res.data.content;
          this.isLoading = false;

          // No data
          if (res.data.content.length === 0) {
            this.dynamicTableBodyData = [];
            this.totalPage = 1;
            return;
          }

          // Set pagination data
          this.totalPage = res.data.total_pages;
          this.totalAsset = res.data.total_elements;

          // List table body data
          res.data.content.forEach((asset: any) => {
            const selectedObj = this.selectedDataId.find((obj: any) => obj === asset.id);
            tableBodyData.push([
              {
                'type': 'TEXT_WITH_THUMBNAIL',
                'value': asset.id,
                'name': asset.name,
                'img_url': asset.image_thumbnail,
                'class': 'asset-file',
                'asset_type': asset.asset_type,
                'file_type': asset.asset_type === 'DOCUMENT' ? asset.file_type.toUpperCase() : undefined
              },
              {
                'type': 'TAGS',
                'tags': asset.tags,
              },
              {
                'type': 'TEXT',
                'value': 'Asset Library' + asset.path
              },
              {
                'type': 'ACTION_BUTTONS',
                'buttons': [
                  {
                    'type': 'SELECT_FILE',
                    'selected': selectedObj ? true : false
                  }
                ]
              }]
            )
          });
          this.dynamicTableBodyData = tableBodyData;
        },
        error: err => {
          console.log(err);
        }
      });
      this.searchResults = true;

    } else {
      this.currentPage = 1;
      this.showAsset = 15;
      this.getAssetsData();
      this.dynamicTableBodyData = [];
      this.searchResults = false;
    }
  }

  formatAssetData(assetData: any) {
    const data = assetData;
    this.dynamicAssetData = [];
    data.content.forEach((content: any) => {
      switch (content.type) {
        case 'FILE':
          switch (content.asset_type) {
            case 'IMAGE':
              this.dynamicAssetData.push({
                type: 'IMAGE',
                name: content.name,
                url: content.image_thumbnail,
                id: content.id
              })
              break;

            case 'DOCUMENT':
              this.dynamicAssetData.push({
                type: 'DOCUMENT',
                name: content.name,
                file_type: content.file_type.toUpperCase(),
                id: content.id,
                url: content.url
              })
              break;

            default:
              break;
          }
          break;

        case 'FOLDER':
          this.dynamicAssetData.push({
            type: 'FOLDER',
            name: content.name,
            link: content.path,
            id: content.id
          });
          break;

        default:
          break;
      }
    });

    this.totalPage = data.total_pages;
    this.totalAsset = data.total_elements;
  }

  getAssetsData() {
    this.isLoading = true;
    this.assetService.getAssetDetail(this.pathArr.length === 1 ? '/' : this.pathArr.join('/'), this.showAsset, this.currentPage, this.sortAsset, false, this.asset_type ?? undefined).subscribe({
      next: res => {
        this.isLoading = false;
        if (res.data) {
          this.contentData = res.data.content;
          this.formatAssetData(res.data);
        } else {
          this.errorService.openGenericErrorModal(res.error, res.error_code);
          console.log(res.error);
        }
      },
      error: err => {
        console.log(err);

      }
    });
  }

  getOrSearch() {
    if (this.searchInput.value || this.searchResults) {
      this.executeSearch(this.searchInput.value);
    } else {
      this.getAssetsData();
    }
  }

  goToFolder(path: string) {
    this.currentPage = 1;
    this.pathArr.push(path);
    this.getAssetsData();
  }

  onSelectFileClick(id: string) {
    const item = this.contentData.find((data: any) => data.id === id);
    if (item) {
      this.onChange.emit(item);
      if (this.asset_type === 'IMAGE') {
        this.selectedDataId = [item.id];
        this.modal.dismiss();
      }
      this.selectedDataId = this.selected_data.map((data: any) => data.id);
    }
  }

  onSelectedClick(data: string) {
    this.onRemove.emit(data);
    if (this.asset_type === 'IMAGE') {
      this.selectedDataId = [];
    } else {
      this.selectedDataId = this.selected_data.map((data: any) => data.id);
    }
  }

  returnToFolder(index: number) {
    this.pathArr.splice(index + 1, this.pathArr.length - 1);
    this.getAssetsData();
  }

  setCurrentPage(page: number) {
    this.currentPage = page;
    this.getOrSearch();
  }

  truncateName(name: string, maxLength: number): string {
    return this.truncateMiddlePipe.transform(name, maxLength);
  }
}
