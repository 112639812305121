import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormModule, ButtonModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormFieldModel, InputTextModel } from 'src/app/_models/form-field.model';
import { NgbTooltip, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { IconModule } from '@coreui/icons-angular';

@Component({
  selector: 'app-text-input',
  standalone: true,
  imports: [FormModule, CommonModule, FormsModule, ButtonModule, NgbTooltipModule, IconModule],
  templateUrl: './text-input.component.html',
  styleUrl: './text-input.component.scss'
})
export class TextInputComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Input() autoComplete?: string;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  @ViewChild('tooltipButton') tooltipEl!: NgbTooltip;
  data!: InputTextModel;

  ngOnInit(): void {
    this.data = this.config as InputTextModel;

    // open password tooltip (Onboarding)
    window.addEventListener('message', (event) => {
      if (event.data === 'invalidPassword' && this.tooltipEl) {
        this.tooltipEl.open();
      }
    })
  }

  setNewValue(value: string) {
    this.onChange.emit({ formControlName: this.data.id, value: value });
  }
}
