import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalModule, ButtonModule, GridModule, FormModule } from '@coreui/angular';
import { FormGroup, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { IconModule } from '@coreui/icons-angular';
import { AssetService } from 'src/app/_services/asset.service';
import { ErrorService } from 'src/app/_services/error.service';
import { ContentService } from 'src/app/_services/content.service';

@Component({
  selector: 'app-edit-asset-modal',
  standalone: true,
  imports: [FormModule, ModalModule, ButtonModule, CommonModule, IconModule, GridModule, FormsModule],
  templateUrl: './edit-asset-modal.component.html',
  styleUrl: './edit-asset-modal.component.scss'
})
export class EditAssetModalComponent implements OnInit {
  @Input() modal: any;
  @Input() asset_data!: any;
  @Output() onCompleted = new EventEmitter();

  editAssetForm!: FormGroup;
  fileNameValue: string = '';

  constructor(
    private toastr: ToastrService,
    private assetService: AssetService,
    private errorService: ErrorService,
    private contentService: ContentService
  ) { }

  ngOnInit(): void {
    this.fileNameValue = this.asset_data.name;
  }

  fileSizeConversion(bytes: number): string {
    if (bytes >= 1024 * 1024) {
      const megabytes = bytes / (1024 * 1024);
      return `${megabytes.toFixed(2)} MB`;
    } else if (bytes >= 1024) {
      const kilobytes = bytes / 1024;
      return `${kilobytes.toFixed(2)} KB`;
    } else {
      return `${bytes} B`;
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.contentService.openFullscreenSpinnerModal();

      switch (this.asset_data.asset_type) {
        case 'DOCUMENT':
          this.assetService.editDocument(this.asset_data.id, this.asset_data.name, this.asset_data.tags ?? '').subscribe({
            next: res => {
              this.contentService.spinnerModalRef.dismiss();
              if (!res.data) {
                this.errorService.openGenericErrorModal(res.error, res.error_code);
              } else {
                this.toastr.success('Document Updated !');
              }
              this.onCompleted.emit();
            }
          })
          break;
        case 'IMAGE':
          this.assetService.editImage(this.asset_data.id, this.asset_data.name, this.asset_data.tags ?? '').subscribe({
            next: res => {
              this.contentService.spinnerModalRef.dismiss();
              if (!res.data) {
                this.errorService.openGenericErrorModal(res.error, res.error_code);
              } else {
                this.toastr.success('Image Updated !');
              }
              this.onCompleted.emit();
            }
          })
          break;
        default:
          break;
      }
    });
  }
}
