import { Component, Input, OnInit } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { ListTableComponent } from '../../list-table/list-table.component';
import { TableBodyDataModel } from 'src/app/_models/select-content-modal.model';
import { ContentService } from 'src/app/_services/content.service';

@Component({
  selector: 'app-version-history-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, ListTableComponent],
  templateUrl: './version-history-modal.component.html',
  styleUrl: './version-history-modal.component.scss'
})
export class VersionHistoryModalComponent implements OnInit {
  @Input() modal: any;
  @Input() data!: any;
  @Input() standardUrlPath!: any;

  tableHeadData = [
    {
      'value': 'Date & time',
      'class': 'col-wide-md'
    },
    {
      'value': 'Language'
    },
    {
      'value': 'Edited by',
      'class': 'col-wide-sm'
    },
    {
      'value': 'Approved by',
      'class': 'col-wide-sm'
    },
    {
      'value': 'Actions',
      'class': 'col-wide-sm'
    }
  ];

  dynamicTableBodyData: TableBodyDataModel[][] = [];

  constructor(
    private contentService: ContentService
  ) { }


  ngOnInit(): void {
    if (this.data.length > 0) {
      this.data.forEach((val: any) => {
        this.dynamicTableBodyData.push([
          {
            'type': 'TEXT',
            'value': this.contentService.formatDateTime(val.approved_at)
          },
          {
            'type': 'TEXT',
            'value': val.language_code.toUpperCase()
          },
          {
            'type': 'TEXT',
            'value': val.last_submitted_by
          },
          {
            'type': 'TEXT',
            'value': val.approved_by
          },
          {
            'type': 'ACTION_BUTTONS',
            'buttons': [
              {
                'type': 'PREVIEW',
                'url': `${this.standardUrlPath}/${val.cms_inode}`
              }
            ]
          }
        ],)
      });
    }
  }

  dismissModal() {
    this.modal.dismiss();
  }
}
