<c-modal-header>
  <h5 cModalTitle>Submit Content</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  This content will be submitted for approval. Are you sure you want to
  continue?
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()">Yes, submit this content</button>
</c-modal-footer>