<c-modal-header>
  <h5 cModalTitle>Create Learning Lesson</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="mb-4">
    <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
  </div>
  <div class="card mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Information</h3>
      <p class="mb-0">Set-up the information for this learning lesson</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputContentIDConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="list-filter-active-period">Publish Period
          <div class="form-sublabel">
            Specify the active dates for this content. Content will only be published and visible during this period.
          </div>
        </label>
        <c-form-check>
          <input cFormCheckInput id="same-as-topic-checkbox" type="checkbox">
          <label cFormCheckLabel for="same-as-topic-checkbox">Same as topic</label>
        </c-form-check>

        <app-datetimerangepicker-input [config]="inputPublishPeriodConfig">
        </app-datetimerangepicker-input>
        <!-- HAVE ERROR -->
        <!-- <input cFormControl type="text" id="partner-retention-date-range-input"
            [owlDateTimeTrigger]="publishPeriodRangePicker" [owlDateTime]="publishPeriodRangePicker"
            [selectMode]="'range'" [min]="minDate" placeholder="Select date period" autocomplete="off" />
          <owl-date-time #publishPeriodRangePicker [hour12Timer]="true"></owl-date-time> -->
      </div>

      <div class="form-group">
        <app-radio-input [config]="inputTypeConfig">
        </app-radio-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <div class="mw-md-50">
          <app-select-menu-with-search-input [config]="inputLinkLearningTopicConfig">
          </app-select-menu-with-search-input>
        </div>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputCoverImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputLargeBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputMobileBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTagsConfig">
        </app-text-input>
      </div>
    </div>
  </div>

  <!-- If type is Article -->
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Contents</h3>
      <p class="mb-0">Set-up the main contents for this lesson</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-editor [config]="inputBodyContentConfig">
        </app-text-editor>
      </div>

      <div class="mb-2">
        <app-text-input [config]="inputContentDurationConfig">
        </app-text-input>
      </div>
    </div>
  </div>
  <!-- /If type is Article -->

  <!-- If type is Video -->
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Video</h3>
      <p class="mb-0">Set-up the video information for this lesson</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <div class="mb-4">
          <app-text-input [config]="inputVideoTitleConfig">
          </app-text-input>
        </div>
        <div class="mb-4">
          <app-text-input [config]="inputvideoTopSubtitleConfig">
          </app-text-input>
        </div>
        <div class="mb-4">
          <app-text-input [config]="inputvideoURLConfig">
          </app-text-input>
        </div>

        <div class="mb-4">
          <app-textarea-input [config]="inputVideoDescriptionConfig">
          </app-textarea-input>
        </div>

        <div>
          <app-text-input [config]="inputVideoDurationConfig">
          </app-text-input>
        </div>
      </div>

      <div class="form-group">
        <app-person-selector [config]="inputAddHostConfig">
        </app-person-selector>
      </div>

      <div class="form-group">
        <app-document-selector [config]="inputAddDocumentConfig">
        </app-document-selector>
      </div>

    </div>
  </div>
  <!-- /If type is Video -->

  <div class="card border-0 light-shadow">
    <div class="card-header bg-gray p-4">
      <h3>Bookmark information</h3>
      <p class="mb-0">Set-up bookmark information, this information will be shown in your bookmarks</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputBookmarkTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputBookmarkDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputBookmarkCoverImageConfig">
        </app-image-input>
      </div>
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()">Submit</button>
</c-modal-footer>