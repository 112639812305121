<c-modal-header>
  <h5 *ngIf="!asset_in_use" cModalTitle>Delete Folder</h5>
  <h5 *ngIf="asset_in_use" cModalTitle>Unable to delete folder</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <ng-container *ngIf="!asset_in_use">
    Are you sure you want to delete this folder? All the assets inside this folder will be deleted. This action cannot
    be undone.
  </ng-container>
  <ng-container *ngIf="asset_in_use">
    <p>You are about to delete a folder that has assets currently in use. This action is irreversible.</p>
    <p>Please type <strong class="text-danger">"delete"</strong> to confirm.</p>
    <app-text-input [config]="inputEmailConfig" (onChange)="setDeleteValue($event)"></app-text-input>
    <div *ngIf="showError" class="form-error">
      Please type the word "delete" to confirm.
    </div>
  </ng-container>
</c-modal-body>

<c-modal-footer>
  <button cButton color="danger" (click)="asset_in_use ? confirmDeleteInUseAsset(): confirmDeleted()"
    [disabled]="asset_in_use && deleteString === ''">Yes, delete this
    folder</button>
</c-modal-footer>