import { Component, Input, EventEmitter, Output, OnInit } from '@angular/core';
import { FormModule, ButtonModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormFieldModel, InputTextWithButtonModel } from 'src/app/_models/form-field.model';

@Component({
  selector: 'app-text-input-with-button',
  standalone: true,
  imports: [FormModule, CommonModule, FormsModule, ButtonModule],
  templateUrl: './text-input-with-button.component.html',
  styleUrl: './text-input-with-button.component.scss'
})
export class TextInputWithButtonComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() buttonClick: EventEmitter<any> = new EventEmitter();
  data!: InputTextWithButtonModel;

  ngOnInit(): void {
    this.data = this.config as InputTextWithButtonModel;
  }

  onButtonClick() {
    this.buttonClick.emit();
  }

}
