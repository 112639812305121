import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';

@Component({
  selector: 'app-confirm-submission-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule],
  templateUrl: './confirm-submission-modal.component.html',
  styleUrl: './confirm-submission-modal.component.scss'
})
export class ConfirmSubmissionModalComponent {
  @Input() modal: any;
  @Output() onConfirm = new EventEmitter<void>();

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit();
    });
  }
}
