<div [@fadeInOut]>
  <c-modal-header>
    <h5 cModalTitle>Upload Image</h5>
  </c-modal-header>
  <c-modal-body>
    <div class="text-warning mb-4">Uploading file, do not close or refresh this window.</div>

    <!-- PROCESSING -->
    <c-card>
      <c-card-body>
        <div class="d-flex flex-wrap align-items-center">
          <figure class="image-preview">
            <img [src]="imageToBeUploaded">
          </figure>
          <div class="flex-1 ps-3">
            <div class="mb-2">{{imageFilename}}</div>
            <div class="tags-container">
              <div class="label">Coffee</div>
              <div class="label">2024</div>
              <div class="label">Competition</div>
            </div>
          </div>
          <div>
            <span class="icon-img icon-spin icon-spinner">
              <svg cIcon name="cilSync" size="xl"></svg>
            </span>
          </div>
        </div>
      </c-card-body>
    </c-card>
    <!-- /PROCESSING -->

    <!-- DONE -->
    <c-card>
      <c-card-body>
        <div class="d-flex flex-wrap align-items-center">
          <figure class="image-preview">
            <img [src]="imageToBeUploaded">
          </figure>
          <div class="flex-1 ps-3">
            <div class="mb-2">{{imageFilename}}</div>
            <div class="tags-container">
              <div class="label">Coffee</div>
              <div class="label">2024</div>
              <div class="label">Competition</div>
            </div>
          </div>
          <div>
            <span class="icon-img text-success">
              <svg cIcon name="cilCheckAlt" size="xl"></svg>
            </span>
          </div>
        </div>
      </c-card-body>
    </c-card>
    <!-- /DONE -->

    <!-- ERROR -->
    <c-card>
      <c-card-body>
        <div class="d-flex flex-wrap align-items-center">
          <figure class="image-preview">
            <img [src]="imageToBeUploaded">
          </figure>
          <div class="flex-1 ps-3">
            <div class="mb-2">{{imageFilename}}</div>
            <div class="tags-container">
              <div class="label">Coffee</div>
              <div class="label">2024</div>
              <div class="label">Competition</div>
            </div>
            <div class="text-danger mt-2 fs-small">Error message goes here</div>
          </div>
          <div>
            <span class="icon-img text-danger">
              <svg cIcon name="cilWarning" size="xl"></svg>
            </span>
          </div>
        </div>
      </c-card-body>
    </c-card>
    <!-- /ERROR -->

  </c-modal-body>

  <!-- Only available of state is not PROCESSING -->
  <c-modal-footer>
    <button cButton color="primary" (click)="dismissModal()">Close</button>
  </c-modal-footer>
</div>