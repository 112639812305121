<c-modal-header>
  <h5 cModalTitle>
    Rename Asset</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="form-group">
    <label cLabel for="file-name-input">File name. Max 50 characters</label>
    <input cFormControl id="file-name-input" maxlength="50" [(ngModel)]="fileNameValue">
  </div>
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="submit()" [disabled]="!fileNameValue">Submit</button>
</c-modal-footer>