<c-modal-header>
  <h5 cModalTitle>Create BAP Topic Item</h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <div class="mb-4">
    <i class="fs-small gray-400">All fields are compulsory unless otherwise stated.</i>
  </div>
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Information</h3>
      <p class="mb-0">Set-up the information for this learning lesson</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-text-input [config]="inputContentIDConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <label cLabel for="list-filter-active-period">Publish Period
          <div class="form-sublabel">
            Specify the active dates for this content. Content will only be published and visible during this period.
          </div>
        </label>
        <c-form-check>
          <input cFormCheckInput id="same-as-topic-checkbox" type="checkbox">
          <label cFormCheckLabel for="same-as-topic-checkbox">Same as parent content</label>
        </c-form-check>

        <app-datetimerangepicker-input [config]="inputPublishPeriodConfig">
        </app-datetimerangepicker-input>
      </div>

      <div class="form-group">
        <app-radio-input [config]="inputTypeConfig">
        </app-radio-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTitleConfig">
        </app-text-input>
      </div>

      <div class="form-group">
        <div class="mw-md-50">
          <app-select-menu-with-search-input [config]="inputLinkTopicConfig">
          </app-select-menu-with-search-input>
        </div>
      </div>

      <div class="form-group">
        <app-textarea-input [config]="inputDescriptionConfig">
        </app-textarea-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputCoverImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-text-input [config]="inputTagsConfig">
        </app-text-input>
      </div>
    </div>
  </div>

  <!-- If type is Article -->
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Article</h3>
      <p class="mb-0">Set-up the article details</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-image-input [config]="inputLargeBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputMobileBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-text-editor [config]="inputArticleBodyContentConfig">
        </app-text-editor>
      </div>
    </div>
  </div>
  <!-- /If type is Article -->

  <!-- If type is Flash card -->
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Flash card</h3>
      <p class="mb-0">Set-up the flash card details</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-image-input [config]="inputCardLargeBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-image-input [config]="inputCardMobileBannerImageConfig">
        </app-image-input>
      </div>

      <div class="form-group">
        <app-text-editor [config]="inputFlashCardBodyContentConfig">
        </app-text-editor>
      </div>
    </div>
  </div>
  <!-- /If type is Flash card -->

  <!-- If type is Workshop -->
  <div class="card border-0 light-shadow mb-4">
    <div class="card-header bg-gray p-4">
      <h3>Workshop</h3>
      <p class="mb-0">Set-up the wordshop details</p>
    </div>
    <div class="card-body p-4">
      <div class="form-group">
        <app-content-selector [config]="inputAddWorkshopConfig" (selectButtonClick)="handleSelectButtonClick()"
          (createButtonClick)="handleCreateButtonClick()">
        </app-content-selector>
      </div>
    </div>
  </div>
  <!-- /If type is Workshop -->
</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" (click)="dismissModal()">Submit</button>
</c-modal-footer>