import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { CreatePersonModalComponent, FullscreenSpinnerComponent, SelectContentModalComponent } from '../';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent, SelectMenuWithSearchInputComponent, DatetimerangepickerInputComponent } from "../../../components/forms";

@Component({
  selector: 'app-create-event-schedule-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, TextInputComponent, SelectMenuWithSearchInputComponent, DatetimerangepickerInputComponent],
  templateUrl: './create-event-schedule-modal.component.html',
  styleUrl: './create-event-schedule-modal.component.scss'
})
export class CreateEventScheduleModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  // Form input - Content ID
  inputContentIDConfig = {
    id: 'content-id-input',
    name: 'content-id-input',
    label: 'Content ID',
    sublabel: 'Create a unique ID for this content. Max. 50 characters. E.g. coffee-stories-championship-day-1',
    placeholder: 'Enter here',
    max_length: 150,
    value: 'learning-lesson-xxx',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputPublishPeriodConfig = {
    id: 'publish-period-input',
    name: 'publish-period-input',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Name
  inputNameConfig = {
    id: 'name-input',
    name: 'name-input',
    label: 'Name',
    sublabel: 'Name of the event. Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Bottom subtitle
  inputLinkConfig = {
    id: 'link-input',
    name: 'link-input',
    label: 'Link',
    sublabel: 'Specify the target link of the content',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputDateTimeConfig = {
    id: 'date-time-input',
    name: 'date-time-input',
    label: 'Date & time',
    sublabel: 'Set up the event date period',
    placeholder: 'Select date and time',
    value: '',
    disabled: false,
    error_message: '',
    min_date: new Date(),
    picker_mode_single: false
  };

  // Form input - Recap link subtitle
  inputRecapLinkConfig = {
    id: 'recap-link-input',
    name: 'recap-link-input',
    label: 'Recap link',
    sublabel: 'Specify the recap link of the past event',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }
}
