import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { FullscreenSpinnerComponent } from '../';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-competition-information-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule],
  templateUrl: './create-competition-information-modal.component.html',
  styleUrl: './create-competition-information-modal.component.scss'
})
export class CreateCompetitionInformationModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }
}
