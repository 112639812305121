<c-modal-header>
  <h5 cModalTitle>
    Select Asset From Library
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body #body>
  <section class="section-search mb-4 pb-4 border-0 border-bottom border-light border-dashed">
    <c-input-group>
      <span cInputGroupText><svg cIcon class="me-1" name="cilMagnifyingGlass"></svg></span>
      <input cFormControl id="table-list-search-input" placeholder="Search by file name, tags" type="text"
        [formControl]="searchInput" />
    </c-input-group>
  </section>

  <!-- <section *ngIf="searchResults && searchInput.value"
    class="search-term mb-4 pb-4 border-0 border-bottom border-light border-dashed">
    <h6 class="mb-0 text-black">You searched for <strong>"{{searchInput.value}}"</strong></h6>
  </section> -->

  <!-- Display if no results -->
  <section *ngIf="contentData.length === 0 && !isLoading"
    class="search-term mb-4 pb-4 border-0 border-bottom border-light border-dashed">
    <i>No results. Try adjust your search terms</i>
  </section>
  <!-- /Display if no results -->

  <!-- Notes: Hide filter if no search results -->
  <section *ngIf="contentData.length !== 0" class="filter mb-3">
    <c-row class="justify-content-center justify-content-lg-end">
      <c-col lg="6">
        <div class="form-group d-flex flex-wrap align-items-center mb-4">
          <label cLabel for="list-filter-sorting" class="me-2 mb-0">Sort</label>
          <select cSelect id="list-filter-sorting" [(ngModel)]="sortAsset"
            (ngModelChange)="currentPage = 1;getOrSearch()" class="flex-1 mb-0" aria-label="Sort">
            <option value="0" selected>Date added (Latest to oldest)</option>
            <option value="1">Date added (Oldest to latest)</option>
            <option value="2">File name (A-Z)</option>
            <option value="3">File name (Z-A)</option>
          </select>
        </div>
      </c-col>
    </c-row>
  </section>

  <div *ngIf="!isLoading && !searchResults" class=" d-flex justify-content-center justify-content-lg-start mb-2">
    <div class="data-count fs-small gray-700"><span>{{this.totalAsset}}</span> records</div>
  </div>

  <section *ngIf="isLoading" class="text-center">
    <span class="icon-img ">
      <icon-spinner></icon-spinner>
    </span>
  </section>

  <section *ngIf="!isLoading && !searchResults" class="card asset-card">
    <div class="card-header d-xl-flex flex-wrap align-items-center">
      <div *ngIf="folderPath"
        class="folder-path d-flex flex-wrap align-items-center justify-content-start justify-content-lg-start flex-1">
        <div *ngFor="let path of pathArr; let i = index" class="folder" [class.current]="!pathArr[i+1]">
          <a href="#" (click)="$event.preventDefault();returnToFolder(i)"><svg cIcon name="cilFolderOpen"
              class="me-1"></svg> {{i === 0 ? 'Asset Library': pathArr[i]}}</a>
        </div>
      </div>
    </div>
    <div class="card-body p-lg-4">
      <c-row class="asset-list">
        <c-col *ngFor="let asset of dynamicAssetData" xs="6" lg="3" class="mb-3">
          <div class="asset-list-item" [class.type-folder]="asset.type === 'FOLDER'"
            [class.type-image]="asset.type === 'IMAGE'" [class.type-document]="asset.type === 'DOCUMENT'">
            <a class="asset-list-item-link d-block" [class.selected]="selectedDataId.includes(asset.id)"
              *ngIf="asset.type === 'IMAGE'">
              <figure *ngIf="asset.type === 'IMAGE'">
                <img [src]="asset.url">
              </figure>
              <div class="action-wrapper">
                <button *ngIf="!selectedDataId.includes(asset.id)" cButton size="sm" class="btn-inverted-primary"
                  (click)="onSelectFileClick(asset.id)">Select this
                  file</button>
                <button *ngIf="selectedDataId.includes(asset.id)" cButton size="sm" color="info"
                  (click)="onSelectedClick(asset.id)"><i class="cis-check me-1"></i> Selected</button>
              </div>
            </a>

            <a class="asset-list-item-link d-block" [class.selected]="selectedDataId.includes(asset.id)"
              *ngIf="asset.type === 'DOCUMENT'">
              <figure *ngIf="asset.type === 'DOCUMENT'">
                <svg *ngIf="asset.file_type === 'PDF'" cIcon name="cilFilePdf"></svg>
                <svg *ngIf="asset.file_type === 'DOC' || asset.file_type === 'DOCX'" cIcon name="cilFileDoc"></svg>
                <svg *ngIf="asset.file_type === 'TXT'" cIcon name="cilDocument"></svg>
                <svg *ngIf="asset.file_type === 'ZIP'" cIcon name="cilFileArchive"></svg>
                <svg *ngIf="asset.file_type === 'XLS' || asset.file_type === 'XLSX'" cIcon name="cilFileXls"></svg>
                <svg *ngIf="asset.file_type === 'CSV'" cIcon name="cilSpreadsheet"></svg>
              </figure>
              <div class="action-wrapper">
                <button *ngIf="!selectedDataId.includes(asset.id)" cButton size="sm" class="btn-inverted-primary"
                  (click)="onSelectFileClick(asset.id)">Select this
                  file</button>
                <button *ngIf="selectedDataId.includes(asset.id)" cButton size="sm" color="info"
                  (click)="onSelectedClick(asset.id)"><i class="cis-check me-1"></i> Selected</button>
              </div>
            </a>

            <a class="asset-list-item-link d-block" *ngIf="asset.type === 'FOLDER'" (click)="goToFolder(asset.name)">
              <figure *ngIf="asset.type === 'FOLDER'">
                <svg cIcon name="cilFolder"></svg>
              </figure>
            </a>

            <div class="d-flex flex-wrap py-2">
              <div class="item-name truncate-2-lines">
                <ng-container *ngIf="asset.type === 'FOLDER'">
                  {{asset.name}}
                </ng-container>

                <ng-container *ngIf="asset.type === 'IMAGE' || asset.type === 'DOCUMENT'">
                  {{ truncateName(asset.name, 25) }}
                </ng-container>

              </div>
            </div>
          </div>
        </c-col>
      </c-row>
    </div>
  </section>

  <section *ngIf="!isLoading && searchResults && searchInput.value && dynamicTableBodyData.length > 0"
    class="search-results">
    <app-list-table [tableSearch]="false" [tableHeadData]="tableHeadData" [tableBodyData]="dynamicTableBodyData"
      [totalAsset]="totalAsset" [assetType]="asset_type" (onSelect)="onSelectFileClick($event)"
      (onUnselect)="onSelectedClick($event)"></app-list-table>
  </section>

  <div *ngIf="!isLoading && contentData.length !== 0" class="pagination d-block mt-4">
    <c-row>
      <c-col xl="8">
        <app-list-pagination [currentPage]="currentPage" [totalPage]="totalPage"
          (onSetPage)="setCurrentPage($event)"></app-list-pagination>
      </c-col>

      <c-col xl="4">
        <div class="form-group d-flex flex-wrap align-items-center justify-content-center justify-content-xl-end">
          <label cLabel for="list-filter-language" class="me-2 mb-0">Show</label>
          <select cSelect id="list-filter-language" [(ngModel)]="showAsset"
            (ngModelChange)="currentPage = 1; getOrSearch()" class="w-auto" aria-label="Language">
            <option value="15">15</option>
            <option value="30">30</option>
            <option value="100">100</option>
          </select>
        </div>
      </c-col>
    </c-row>
  </div>

</c-modal-body>
<c-modal-footer>
  <button cButton color="primary" size="sm" class="btn-select-image me-2 mb-2" (click)="dismissModal()">
    Done
  </button>
</c-modal-footer>