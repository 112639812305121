<c-modal-header>
  <h5 cModalTitle>
    {{asset_data.name}}
  </h5>
  <button cButtonClose (click)="dismissModal()"></button>
</c-modal-header>
<c-modal-body>
  <figure *ngIf="asset_data.asset_type === 'IMAGE'" class="image-preview">
    <img [src]="asset_data.url">
  </figure>

  <figure *ngIf="asset_data.asset_type === 'DOCUMENT'" class="document-preview">
    <svg *ngIf="asset_data.file_type.toUpperCase() === 'PDF'" cIcon name="cilFilePdf"></svg>
    <svg *ngIf="asset_data.file_type.toUpperCase() === 'DOC' || asset_data.file_type.toUpperCase() === 'DOCX'" cIcon
      name="cilFileDoc"></svg>
    <svg *ngIf="asset_data.file_type.toUpperCase() === 'TXT'" cIcon name="cilDocument"></svg>
    <svg *ngIf="asset_data.file_type.toUpperCase() === 'ZIP'" cIcon name="cilFileArchive"></svg>
    <svg *ngIf="asset_data.file_type.toUpperCase() === 'XLS' || asset_data.file_type.toUpperCase() === 'XLSX'" cIcon
      name="cilFileXls"></svg>
    <svg *ngIf="asset_data.file_type.toUpperCase() === 'CSV'" cIcon name="cilSpreadsheet"></svg>
  </figure>

  <div class="py-4 px-1 pb-0">
    <div class="information pb-3">
      <h5 class="mb-3">Information</h5>
      <c-row>
        <c-col sm="6" class="mb-3">
          <div class="data-label">
            <svg cIcon name="cilFile" class="me-1"></svg> File format
          </div>
          <div class="data">{{asset_data.file_type.toUpperCase()}}</div>
        </c-col>

        <c-col sm="6" class="mb-3" *ngIf="asset_data.asset_type === 'IMAGE'">
          <div class="data-label">
            <svg cIcon name="cilCrop" class="me-1"></svg> Image size
          </div>
          <div class="data">{{asset_data.image_width}}px x {{asset_data.image_height}}px</div>
        </c-col>

        <c-col sm="6" class="mb-3">
          <div class="data-label">
            <svg cIcon name="cilMemory" class="me-1"></svg> File size
          </div>
          <div class="data">{{fileSizeConversion(asset_data.size)}}</div>
        </c-col>

        <c-col sm="6" class="mb-3">
          <div class="data-label">
            <svg cIcon name="cilInboxIn" class="me-1"></svg> Date added
          </div>
          <!-- <div class="data">10:00 AM PST, 1 August 2024<br><i>by John Doe</i></div> -->
          <div class="data">{{formatDateTime(asset_data.created_at)}}
            <span *ngIf="asset_data.created_by">
              <br><i>by {{asset_data.created_by}}</i>
            </span>
          </div>
        </c-col>
      </c-row>
    </div>
    <div *ngIf="asset_data.tags" class="tags pb-3 border-top border-light">
      <h5 class="mt-4 mb-3">Tags</h5>
      <div class="d-flex flex-wrap mb-3">
        <div *ngFor="let tag of asset_data.tags.split(',')" class="label label-tag">{{tag}}</div>
      </div>
    </div>
    <div *ngIf="asset_data.usages.length > 0" class="usage border-top border-light">
      <h5 class="mt-4 mb-3">Usage</h5>
      <div class="d-flex flex-wrap mb-3">
        <a *ngFor="let usage of asset_data.usages" [routerLink]="contentPath(usage.content_type, usage.inode)"
          target="_blank" class="label label-usage text-decoration-none">
          {{usage.title}}
        </a>
      </div>
    </div>
  </div>
</c-modal-body>
<c-modal-footer>
  <div class="w-100 text-center order-1 order-md-0 w-md-auto me-md-auto">
    <button cButton color="danger" class="btn-delete-asset" (click)="deleteAsset(asset_data.id)">Delete this
      asset</button>
  </div>

  <button *ngIf="asset_data.url" cButton color="primary" class="btn-download-asset"
    (click)="downloadAsset(asset_data.url)">Download</button>
  <a *ngIf="asset_data.url" id="link" [href]="asset_data.url" download="test" hidden target="_blank"></a>

  <button cButton color="primary" class="btn-copy-link" (click)="copyLink(asset_data.url)">Copy link</button>
</c-modal-footer>