import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInOut } from '../../../animations';
import { ModalModule, ButtonModule, CardModule } from '@coreui/angular';
import { TextInputComponent } from '../../forms';
import { IconModule } from '@coreui/icons-angular';
import { AssetService } from 'src/app/_services/asset.service';
import { AssetModel, UploadAssetModel } from 'src/app/_models/asset.model';

@Component({
  selector: 'app-add-assets',
  standalone: true,
  templateUrl: './add-assets.component.html',
  styleUrl: './add-assets.component.scss',
  imports: [CommonModule, ModalModule, ButtonModule, CardModule, TextInputComponent, IconModule],
  animations: [fadeInOut]
})
export class AddAssetsComponent implements OnInit {
  [x: string]: any;
  @Input() assetsToBeUploaded: UploadAssetModel[] = [];
  @Input() modal: any;
  @Input() path?: string;
  @Output() onUploaded = new EventEmitter<AssetModel>();
  @Output() onDismissed = new EventEmitter();
  @Output() onCompleted = new EventEmitter();

  currentStep: string = 'PREVIEW';
  errorMessage: string[] = [];
  inputTagsConfig: { id: string, name: string, placeholder: string, maxLength: number, value: string }[] = [];
  progressArr: { processing: string[], done: string[], error: string[] } = { processing: [], done: [], error: [] };
  progressCounter: number = 0;
  tags: string = '';

  constructor(
    private assetService: AssetService
  ) { }


  ngOnInit(): void {
    this.assetsToBeUploaded.forEach((asset, index) => {

      // Unsupported type file
      if (asset.unsupportedFileType) {
        this.errorMessage[index] = 'Unsupported File Type';
        this.progressArr.error.push(String(index));
        this.checkProgress();
        return;
      }

      // File size over limit (10mb)
      if (asset.exceedSizeLimit) {
        this.errorMessage[index] = `File is too large! Maximum size allowed is 10MB.`;
        this.progressArr.error.push(String(index));
        this.checkProgress();
        return;
      }

      this.inputTagsConfig.push({
        id: `tags-input-${index}`,
        name: `tags-input-${index}`,
        placeholder: 'Add tags, separate each tag with comma. E.g. Coffee, Cold Brew',
        maxLength: 100,
        value: ''
      })
    })
  }

  checkProgress() {
    this.progressCounter += 1;
    if (this.progressCounter === this.assetsToBeUploaded.length && this.progressArr.processing.length === 0) {
      this.currentStep = 'DONE';
      this.onCompleted.emit();
    }
  }

  dismissModal() {
    this.modal.dismiss();
    this.onDismissed.emit();
  }

  goToProcessAssets() {
    this.currentStep = 'PROCESSING'

    this.assetsToBeUploaded.forEach((asset, index) => {
      // Exceed 10MB or Unsupported File Type
      if (asset.exceedSizeLimit || asset.unsupportedFileType) {
        return;
      }

      this.progressArr.processing.push(String(index));

      switch (asset.file_type) {
        case 'IMAGE':
          this.assetService.addImage(this.path ?? undefined, this.assetsToBeUploaded[index].file, this.assetsToBeUploaded[index].tags).subscribe({
            next: res => {
              if (res.data) {
                this.progressArr.processing.splice(this.progressArr.processing.findIndex(id => id === String(index)), 1);
                this.progressArr.done.push(String(index));
                // this.onUploaded.emit({ id: res.data.id, name: this.assetsToBeUploaded[index].file_name, type: 'IMAGE', thumbnail_url: res.data.url });
                this.onUploaded.emit({ id: res.data.id, name: this.assetsToBeUploaded[index].file_name, type: 'IMAGE', url: res.data.url ?? this.assetsToBeUploaded[index].asset_url }); //COME BACK LATER
              } else {
                this.progressArr.processing.splice(this.progressArr.processing.findIndex(id => id === String(index)), 1);
                this.errorMessage[index] = res.error;
                this.progressArr.error.push(String(index));
              }
              this.checkProgress();
            },
            error: res => {
              console.log(res);
            }
          })
          break;

        case 'DOC':
        case 'XLS':
        case 'CSV':
        case 'ZIP':
        case 'TXT':
        case 'PDF':
          this.assetService.addDocument(undefined, this.assetsToBeUploaded[index].file, this.assetsToBeUploaded[index].tags).subscribe({
            next: res => {
              if (res.data) {
                this.progressArr.processing.splice(this.progressArr.processing.findIndex(id => id === String(index)), 1);
                this.progressArr.done.push(String(index));
                this.onUploaded.emit({ id: res.data.id, name: this.assetsToBeUploaded[index].file_name, type: 'DOCUMENT', file_type: this.assetsToBeUploaded[index].file_type!, url: res.data.url });
              } else {
                this.progressArr.processing.splice(this.progressArr.processing.findIndex(id => id === String(index)), 1);
                this.errorMessage[index] = res.error;
                this.progressArr.error.push(String(index));
              }
              this.checkProgress();
            },
            error: res => {
              console.log(res);
              this.progressArr.processing.splice(this.progressArr.processing.findIndex(id => id === String(index)), 1);
              this.errorMessage[index] = res.error;
              this.progressArr.error.push(String(index));
              this.checkProgress();
            }
          })
          break;

        default:
          break;
      }
    });
  }

  isDone(index: number): boolean {
    return this.progressArr.done.includes(String(index));
  }

  isError(index: number): boolean {
    return this.progressArr.error.includes(String(index));
  }

  isProcessing(index: number): boolean {
    return this.progressArr.processing.includes(String(index));
  }

  setFormValue(data: any, index: number) {
    this.assetsToBeUploaded[index].tags = data.value.split(',').filter((item: any) => item !== '').join(',');
  }
}
