import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FullscreenSpinnerComponent } from '../';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent, ImageInputComponent, RadioInputComponent, TextEditorComponent, SelectMenuWithSearchInputComponent, DatetimerangepickerInputComponent } from "../../../components/forms";

@Component({
  selector: 'app-create-person-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, TextInputComponent, ImageInputComponent, RadioInputComponent, TextEditorComponent, SelectMenuWithSearchInputComponent, FormModule, DatetimerangepickerInputComponent],
  templateUrl: './create-person-modal.component.html',
  styleUrl: './create-person-modal.component.scss'
})
export class CreatePersonModalComponent {
  @Input() modal: any;
  @Input() event_session!: string;
  @Output() onChange = new EventEmitter<any>();

  // Form input - Content ID
  inputContentIDConfig = {
    id: 'content-id-input',
    name: 'content-id-input',
    label: 'Content ID',
    sublabel: 'Create a unique ID for this content. Max. 50 characters. E.g. coffee-stories-championship-day-1',
    placeholder: 'Enter here',
    max_length: 150,
    value: 'learning-lesson-xxx',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputPublishPeriodConfig = {
    id: 'publish-period-input',
    name: 'publish-period-input',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Name
  inputNameConfig = {
    id: 'name-input',
    name: 'name-input',
    label: 'Name',
    sublabel: 'Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Icon image
  inputProfileImageConfig = {
    type: 'PROFILE_IMAGE',
    id: 'profile-image-input',
    name: 'profile-image-input',
    label: 'Profile image',
    sublabel: 'Select or upload a profile image',
    value: '',
    disabled: false
  };

  // Form input - Bio Type
  inputTypeConfig = {
    name: 'bio-type-input',
    label: 'Bio type',
    sublabel: 'Specify the bio type',
    inline_options: true,
    value: '',
    disabled: false,
    error_message: '',
    options: [
      { id: 'bio-type-text', label: 'Text' },
      { id: 'bio-type-video', label: 'Video' }
    ]
  };

  // Form input - Market
  inputPersonMarketConfig = {
    id: 'market-input',
    name: 'market-input',
    label: 'Employee market',
    sublabel: '',
    placeholder: 'Select a market (optional)',
    disabled: false,
    error_message: '',
    options: [
      { _id: 'market-1', value: 'Hong Kong' },
      { _id: 'market-2', value: 'Malaysia' },
      { _id: 'market-3', value: 'Japan' }
    ]
  };

  // Form input - Person Title
  inputPersonTitleConfig = {
    id: 'person-title-input',
    name: 'person-title-input',
    label: 'Title (optional)',
    sublabel: 'Specify the title for this person',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input -Body content
  inputBioContentConfig = {
    id: 'bio-content-input',
    name: 'bio-content-input',
    label: 'Bio content',
    sublabel: 'Create the body contents of the bio',
    disabled: false,
    error_message: ''
  };

  // Form input - Title
  inputBioVideoConfig = {
    id: 'bio-video-input',
    name: 'bio-video-input',
    label: 'Video bio URL',
    sublabel: 'Enter the URL of the video bio',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Tags
  inputTagsConfig = {
    id: 'tags-input',
    name: 'tags-input',
    label: 'Tags',
    sublabel: 'Add tagging for the content, may enter multiple. Separate each tag with comma. E.g. Coffee, Cold Brew, Frappuccino',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }
}
