import { FormFieldModel, InputCheckboxModel, InputDateTimeModel, InputImageModel, InputRadioModel, InputRelationshipModel, InputSelectModel, InputTextEditorModel, InputTextModel, InputVideoModel, LineDividerModel, formField, formObjectModel } from "../_models/form-field.model";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { InputField } from "../enum/input-field.enum";
import { isMoment } from "moment";

@Injectable({
    providedIn: 'root',
})

export class FormService {

    constructor(
        private http: HttpClient
    ) { }

    // Add triggers to formArray
    // Line Divider: [containTrigger, containTriggerBy, showSection]
    // Other fields: [trigger, triggerBy, showField, triggeVal] 
    addTriggerSectionProperties(formData: any, field: any, trigger: string, triggerBy: string, triggerVal: string, triggerSection: string) {
        // [containTrigger, trigger]
        if (trigger !== null) {
            formData[formData.length - 1].containTrigger.push(trigger);
            field = {
                ...field,
                trigger: true
            }
        }

        // [containTriggerBy, triggerBy, showField]
        if (triggerBy) {
            if (!formData[formData.length - 1].containTriggerBy.includes(triggerBy.toUpperCase())) {
                formData[formData.length - 1].containTriggerBy.push(triggerBy.toUpperCase());
            }
            field = {
                ...field,
                triggerBy: triggerBy.toUpperCase(),
                showField: false
            }
        }

        // [triggerVal]
        if (triggerVal) {
            field = {
                ...field,
                triggerVal: triggerVal.toUpperCase().split(','),
            }
        }

        // [showSection]
        if (triggerSection && triggerSection.toUpperCase() === 'TRUE') {
            formData[formData.length - 1].showSection = false;
        }

        return field;
    }

    // Construct form field
    constructFormArraySwitchCase(field: any, fieldType2: any, obj: any, formArray: any, isDisabled: boolean, fieldValue?: any, languageId?: number) {
        let insertPoint: any; // for existing formArray.data, to insert more data.
        // Input's field variable
        let fieldVariableGroupVal = field.fieldVariables.find((variable: any) => variable.key === 'group')?.value;
        let fieldVariableSubtitle = field.fieldVariables.find((variable: any) => variable.key === 'subtitle')?.value;
        let fieldVariableContentTypeVal = field.fieldVariables.find((variable: any) => variable.key === 'contentType')?.value;
        let fieldVariableHintVal = field.fieldVariables.find((variable: any) => variable.key === 'hint')?.value;

        // Trigger's field variables
        let fieldVariableTrigger = field.fieldVariables.find((variable: any) => variable.key === 'trigger')?.value;
        let fieldVariableTriggerSection = field.fieldVariables.find((variable: any) => variable.key === 'triggerSection')?.value;
        let fieldVariableTriggerBy = field.fieldVariables.find((variable: any) => variable.key === 'triggerBy')?.value;
        let fieldVariableTriggerVal = field.fieldVariables.find((variable: any) => variable.key === 'triggerVal')?.value;

        switch (fieldType2?.value) {
            case InputField.LINEDIVIDER:
                obj.data = {
                    id: field.variable,
                    title: field.name,
                    subtitle: fieldVariableSubtitle ? fieldVariableSubtitle : ''
                } as LineDividerModel;

                obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                formArray[formArray.length - 1].header.push(obj);
                break;

            case InputField.TEXT:
            case InputField.TEXTAREA:
                obj.data = {
                    id: field.variable,
                    name: field.name,
                    label: field.name,
                    sublabel: field.hint,
                    placeholder: 'Enter here',
                    value: fieldValue || field.defaultValue || '',
                    disabled: isDisabled,
                    error_message: 'Please complete this information',
                    required: field.required,
                    regex: field.regexCheck,
                } as InputTextModel;

                obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                formArray[formArray.length - 1].body.push(obj);
                break;

            case InputField.TEXTEDITOR:
                const textEditorObj = this.findfieldVariableGroupWithinFormArray(formArray, fieldVariableGroupVal); // Find fieldVariableGroup within formArray;

                if (!textEditorObj) {
                    obj.data = {
                        name: fieldVariableGroupVal,
                        groupVariable: []
                    }; // Did not cast because some required properties in the model are not initialized here

                    obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                    formArray[formArray.length - 1].body.push(obj);
                }

                insertPoint = textEditorObj ?? obj;
                if (!(insertPoint.data as InputTextEditorModel).value) {
                    (insertPoint.data as InputTextEditorModel).value = {
                        content: '',
                        duration: 0
                    }
                }

                switch (fieldVariableContentTypeVal) {
                    case 'CONTENT':
                        insertPoint.data = {
                            ...insertPoint.data, // existing data
                            id: `${insertPoint.data.id ? insertPoint.data.id + '-' : ''}` + field.variable,
                            label: fieldVariableGroupVal,
                            sublabel: field.hint,
                            disabled: isDisabled,
                            required: field.required,
                            groupVariable: [
                                ...(insertPoint.data.groupVariable || []), // exisitng data
                                {
                                    name: field.variable,
                                    contentType: fieldVariableContentTypeVal.toLowerCase()
                                }
                            ],
                            value: {
                                content: fieldValue || field.defaultValue || insertPoint.data.value.content,
                                duration: insertPoint.data.value.duration
                            }
                        } as InputTextEditorModel;
                        break;
                    case 'DURATION':
                        insertPoint.data = {
                            ...insertPoint.data, // existing data
                            id: `${insertPoint.data.id ? insertPoint.data.id + '-' : ''}` + field.variable,
                            groupVariable: [
                                ...(insertPoint.data.groupVariable || []), // existing data
                                {
                                    name: field.variable,
                                    contentType: fieldVariableContentTypeVal.toLowerCase()
                                }
                            ],
                            value: {
                                content: insertPoint.data.value.content,
                                duration: fieldValue || field.defaultValue || insertPoint.data.value.duration
                            }
                        } as InputTextEditorModel;
                        break;
                    default:
                        break;
                }
                break;

            case InputField.RADIO:
                let options = field.values.split('\r\n');
                let optionsArr: { id: string, label: string }[] = options.map((option: any, index: any) => ({ id: field.variable + '-option-' + index, label: option }));
                obj.data = {
                    id: field.variable,
                    name: field.name,
                    label: field.name,
                    sublabel: field.hint,
                    inline_options: true,
                    value: fieldValue || field.defaultValue || '',
                    disabled: isDisabled,
                    error_message: 'Please select ONE value',
                    options: optionsArr,
                    required: field.required
                } as InputRadioModel;

                obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                formArray[formArray.length - 1].body.push(obj);
                break;

            case InputField.CHECKBOX:
                let checkboxOptions = field.values.split('\r\n');
                let checkboxOptionsArr: { id: string, label: string, disabled: boolean | false, checked: boolean }[] = checkboxOptions.map((option: any, index: any) => ({ id: field.variable + '-option' + index, label: option, disabled: false, checked: false }));
                obj.data = {
                    id: field.variable,
                    name: field.name,
                    label: field.name,
                    sublabel: field.hint,
                    inline_options: true,
                    disabled: isDisabled,
                    error_message: 'Please select at least ONE value',
                    select_all_option: true,
                    value: fieldValue || field.defaultValue || '',
                    options: checkboxOptionsArr,
                    required: field.required
                } as InputCheckboxModel;

                obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                formArray[formArray.length - 1].body.push(obj);
                break;

            case InputField.SELECT:
            case InputField.SELECTSEARCH:
                let selectOptions = field.values.split('\r\n');
                let selectOptionsArr: { value: string, label: string, disabled: boolean | false, selected: boolean }[] = selectOptions.map((option: any) => ({ value: option, label: option, disabled: false, selected: false }));
                obj.data = {
                    id: field.variable,
                    name: field.name,
                    label: field.name,
                    sublabel: field.hint,
                    disabled: isDisabled,
                    error_message: "Please select a value",
                    value: fieldValue || field.defaultValue || '',
                    options: selectOptionsArr,
                    required: field.required
                } as InputSelectModel;

                obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                formArray[formArray.length - 1].body.push(obj);
                break;

            case InputField.DATETIME:
                // Single DateTime
                if (fieldVariableGroupVal === undefined) {
                    obj.data = {
                        id: field.variable,
                        name: field.name,
                        label: field.name,
                        sublabel: field.hint,
                        placeholder: 'Select date & time',
                        value: fieldValue || field.defaultValue || '',
                        disabled: isDisabled,
                        error_message: 'Please pick a date and time.',
                        picker_mode_single: true,
                        required: field.required,
                    } as InputDateTimeModel;

                    obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                    formArray[formArray.length - 1].body.push(obj);
                    break;
                }

                // Range DateTime
                const fieldVariableRangeSelectorVal = field.fieldVariables.find((variable: any) => variable.key === 'rangeSelector')?.value; // Order of dateTime value
                const dateTimeObj = this.findfieldVariableGroupWithinFormArray(formArray, fieldVariableGroupVal); // Find fieldVariableGroup within formArray

                // Data not exist in formArray
                if (!dateTimeObj) {
                    obj.data = {
                        id: field.variable,
                        name: fieldVariableGroupVal,
                        label: fieldVariableGroupVal,
                        sublabel: field.hint,
                        placeholder: 'Select date & time',
                        value: fieldValue ? [fieldValue] : (field.defaultValue ? [field.defaultValue] : null),
                        disabled: isDisabled,
                        error_message: 'Please pick a date and time.',
                        picker_mode_single: true,
                        required: field.required,
                        groupVariable: [{ name: field.variable, rangeSelector: fieldVariableRangeSelectorVal }]
                    } as InputDateTimeModel;

                    obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                    formArray[formArray.length - 1].body.push(obj);
                    break;
                }

                // Partial data exist in formArray
                dateTimeObj.data = {
                    ...(dateTimeObj.data as InputDateTimeModel), // existing data
                    picker_mode_single: false,
                    id: ((dateTimeObj.data as InputDateTimeModel).id ? (dateTimeObj.data as InputDateTimeModel).id + '-' : '') + field.variable,
                    required: field.required || (dateTimeObj.data as InputDateTimeModel).required,
                    value: [
                        ...((dateTimeObj.data as InputDateTimeModel).value || []),
                        ...(fieldValue ? [fieldValue] : (field.defaultValue ? [field.defaultValue] : []))
                    ],
                    groupVariable: [
                        ...((dateTimeObj.data as InputDateTimeModel).groupVariable || []),
                        {
                            name: field.variable,
                            rangeSelector: fieldVariableRangeSelectorVal
                        }
                    ]
                } as InputDateTimeModel;
                (dateTimeObj.data as InputDateTimeModel).groupVariable!.sort((a: any, b: any) => a.rangeSelector - b.rangeSelector);
                break;

            case InputField.IMAGE:
                obj.data = {
                    type: field.fieldVariables.find((variable: any) => variable.key === 'contentType')?.value,
                    id: field.variable,
                    name: field.name,
                    label: field.name,
                    sublabel: field.hint,
                    value: {
                        url: field.defaultValue ?? fieldValue ?? '',
                        id: ''
                    },
                    disabled: isDisabled,
                    required: field.required
                } as InputImageModel;

                obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                formArray[formArray.length - 1].body.push(obj);
                break;

            case InputField.RELATIONSHIP:
                obj.content = fieldVariableContentTypeVal;

                // Content Selector Type
                switch (fieldVariableContentTypeVal) {
                    case 'SINGLECONTENT':
                        const singleContentRelationshipObj = this.findfieldVariableGroupWithinFormArray(formArray, fieldVariableGroupVal); // Find fieldVariableGroup within formArray;
                        if (!singleContentRelationshipObj) {
                            obj.data = {
                                name: fieldVariableGroupVal,
                                sublabel: fieldVariableHintVal,
                                groupVariable: []
                            } // Did not cast because some required properties in the model are not initialized here

                            obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                            formArray[formArray.length - 1].body.push(obj);
                        }
                        insertPoint = singleContentRelationshipObj ?? obj;

                        // CMS field type
                        switch (field.fieldType) {
                            case 'Select':
                                let options = field.values.split('\r\n');
                                insertPoint.data = {
                                    ...insertPoint.data,
                                    id: `${insertPoint.data.id ? insertPoint.data.id + '-' : ''}` + field.variable,
                                    type: options,
                                    selected_content_type: fieldValue || field.defaultValue || '',
                                    groupVariable: [
                                        ...(insertPoint.data.groupVariable || []),
                                        {
                                            name: field.variable,
                                            contentType: 'select'
                                        }
                                    ]
                                } as InputRelationshipModel;
                                break;

                            case 'Text':
                                insertPoint.data = {
                                    ...insertPoint.data,
                                    id: `${insertPoint.data.id ? insertPoint.data.id + '-' : ''}` + field.variable,
                                    label: fieldVariableGroupVal,
                                    sublabel: field.hint,
                                    disabled: false,
                                    error_message: 'Please select content',
                                    select_button_label: 'Select content',
                                    create_button_label: 'Create content',
                                    required: field.required,
                                    language: languageId,
                                    selected_data: fieldValue ? [{
                                        id: '',
                                        identifier: fieldValue,
                                        name: '',
                                        tags: '',
                                        url: '',
                                        status: ''
                                    }] : [],
                                    groupVariable: [
                                        ...(insertPoint.data.groupVariable || []),
                                        {
                                            name: field.variable,
                                            contentType: 'text'
                                        }
                                    ]
                                } as InputRelationshipModel;
                                break;
                            default:
                                break;
                        }
                        break;

                    case 'DOCUMENT':
                    case 'PERSON':
                    case 'CONTENT':
                        obj.data = {
                            id: field.variable,
                            name: field.name,
                            label: field.name,
                            sublabel: field.hint || fieldVariableHintVal,
                            disabled: isDisabled,
                            required: field.required,
                            error_message: `Please select ${fieldVariableContentTypeVal.toLowerCase()}`,
                            select_button_label: `Select ${fieldVariableContentTypeVal.toLowerCase()}`,
                            create_button_label: `Create ${fieldVariableContentTypeVal.toLowerCase()}`,
                            selected_data: []
                        } as InputRelationshipModel;

                        // Only for CMS Relationship Field type
                        if (fieldVariableContentTypeVal === 'CONTENT' || fieldVariableContentTypeVal === 'PERSON') {
                            (obj.data as InputRelationshipModel).related = {
                                cardinality: field.relationships.cardinality,
                                contentType: field.relationships.velocityVar
                            }
                        }

                        // Insert value for DOCUMENT, PERSON, CONTENT
                        if (fieldValue) {
                            switch (fieldVariableContentTypeVal) {
                                case 'PERSON':
                                    fieldValue.forEach((val: any) => {
                                        (obj.data as InputRelationshipModel).selected_data.push({
                                            id: val.contentId || val.inode || val.identifier,
                                            identifier: val.identifier,
                                            name: val.title,
                                            tags: val.tags,
                                            url: val.coverImage || val.profileImage || '',
                                            status: val.approvalStatus
                                        })
                                    })
                                    break;
                                case 'DOCUMENT':
                                    JSON.parse(fieldValue).forEach((val: any) => {
                                        (obj.data as InputRelationshipModel).selected_data.push({
                                            contentLink: val.id,
                                            id: val.id,
                                            name: val.serverName,
                                            displayName: val.name || '',
                                            url: val.src
                                        })
                                    })
                                    break;
                                case 'CONTENT':
                                    fieldValue.forEach((val: any) => {
                                        (obj.data as InputRelationshipModel).selected_data.push({
                                            id: val.contentId || val.inode || val.identifier,
                                            identifier: val.identifier,
                                            name: val.title,
                                            tags: val.tags,
                                            url: val.coverImage || val.profileImage || '',
                                            status: val.approvalStatus
                                        })
                                    })
                                    break;
                                default:
                                    break;
                            }
                        }

                        obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                        formArray[formArray.length - 1].body.push(obj);
                        break;

                    default:
                        break;
                }
                break;

            case InputField.VIDEO:
                const videoObj = this.findfieldVariableGroupWithinFormArray(formArray, fieldVariableGroupVal); // Find fieldVariableGroup within formArray;
                if (!videoObj) {
                    obj.data = {
                        name: fieldVariableGroupVal,
                        groupVariable: []
                    }; // Does not cast it because some required properties in the model are not initialized here

                    obj = this.addTriggerSectionProperties(formArray, obj, (typeof fieldVariableTrigger === 'string' && fieldVariableTrigger.toUpperCase() === 'TRUE') ? field.variable : null, fieldVariableTriggerBy, fieldVariableTriggerVal, fieldVariableTriggerSection);
                    formArray[formArray.length - 1].body.push(obj);
                }

                insertPoint = videoObj ?? obj;
                if (!(insertPoint.data as InputVideoModel).value) {
                    (insertPoint.data as InputVideoModel).value = {
                        url: '',
                        duration: 0
                    }
                }

                switch (fieldVariableContentTypeVal) {
                    case 'LINK':
                        insertPoint.data = {
                            ...insertPoint.data,
                            id: `${insertPoint.data.id ? insertPoint.data.id + '-' : ''}` + field.variable,
                            label: fieldVariableGroupVal,
                            sublabel: field.hint,
                            placeholder: 'Enter Video URL here',
                            disabled: isDisabled,
                            value: {
                                url: fieldValue || field.defaultValue || insertPoint.data.value.url,
                                duration: insertPoint.data.value.duration
                            },
                            required: field.required,
                            groupVariable: [
                                ...(insertPoint.data.groupVariable || []),
                                {
                                    name: field.variable,
                                    contentType: fieldVariableContentTypeVal.toLowerCase()
                                }
                            ]
                        } as InputVideoModel;
                        break;

                    case 'DURATION':
                        insertPoint.data = {
                            ...insertPoint.data,
                            id: `${insertPoint.data.id ? insertPoint.data.id + '-' : ''}` + field.variable,
                            value: {
                                url: insertPoint.data.value.url,
                                duration: fieldValue || field.defaultValue || insertPoint.data.value.duration,
                            },
                            groupVariable: [
                                ...(insertPoint.data.groupVariable || []),
                                {
                                    name: field.variable,
                                    contentType: fieldVariableContentTypeVal.toLowerCase()
                                }
                            ]
                        } as InputVideoModel;
                        break;
                    default:
                        break;
                }
                break;
            default:
                return;
        }
    }

    // Format form data for submission
    formatFormData(formArray: any) {
        let dataJSON: { [key: string]: string } = {};
        formArray.forEach((section: any) => {
            section.body.forEach((field: any) => {

                switch (field.type) {
                    case 'TEXTEDITOR':
                        const contentVariable = field.data.groupVariable.find((val: any) => val.contentType.toUpperCase() === 'CONTENT')
                        const editorDurationVariable = field.data.groupVariable.find((val: any) => val.contentType.toUpperCase() === 'DURATION')
                        if (contentVariable) {
                            dataJSON[contentVariable.name] = field.data.value.content ?? '';
                        }
                        if (editorDurationVariable) {
                            dataJSON[editorDurationVariable.name] = field.data.value.duration ?? 0;
                        }
                        break;

                    case 'TEXTBUTTON':
                        // Only used in Create, Edit, Check Event (Direct Link); not CMS related
                        break;

                    case 'IMAGE':
                        dataJSON[field.data.id] = field.data.value.url ?? '';
                        break;

                    case 'RELATIONSHIP':
                        // Type of Relationship Content Selector
                        switch (field.content) {
                            case 'SINGLECONTENT':
                                const textVariable = field.data.groupVariable.find((val: any) => val.contentType.toUpperCase() === 'TEXT')
                                const selectVariable = field.data.groupVariable.find((val: any) => val.contentType.toUpperCase() === 'SELECT')

                                if (textVariable) {
                                    dataJSON[textVariable.name] = field.data.selected_data.length > 0 ? field.data.selected_data[0].identifier ?? field.data.selected_data[0].id : '';
                                }
                                if (selectVariable) {
                                    dataJSON[selectVariable.name] = field.data.selected_content_type ?? '';
                                }
                                break;

                            case 'DOCUMENT':
                                const selectedDataArr: { id: string; name: string; serverName: string, src: string; }[] = [];
                                field.data.selected_data.forEach((data: any) => {
                                    selectedDataArr.push({
                                        id: data.id,
                                        name: data.displayName || data.name,
                                        serverName: data.name,
                                        src: data.url !== "" ? data.url : '#'
                                    })
                                });
                                dataJSON[field.data.id] = JSON.stringify(selectedDataArr);
                                break;

                            default:
                                dataJSON[field.data.id] = field.data.selected_data.map((val: any) => `+identifier:${val.identifier}`).join(',');
                                break;
                        }
                        break;

                    case 'VIDEO':
                        const linkVariable = field.data.groupVariable.find((val: any) => val.contentType.toUpperCase() === 'LINK')
                        const durationVariable = field.data.groupVariable.find((val: any) => val.contentType.toUpperCase() === 'DURATION')
                        if (linkVariable) {
                            dataJSON[linkVariable.name] = field.data.value.url ?? '';
                        }
                        if (durationVariable) {
                            dataJSON[durationVariable.name] = field.data.value.duration ?? 0;
                        }
                        break;

                    case 'DATETIME':
                        const groupVariable = field.data.groupVariable;
                        if (groupVariable) { // 2 DateTime field
                            const startVariable = groupVariable.find((val: any) => val.rangeSelector === '0');
                            const endVariable = groupVariable.find((val: any) => val.rangeSelector === '1');

                            var startVariableVal = field.data.value[0] ?? "";
                            if (isMoment(startVariableVal)) {
                                startVariableVal = field.data.value[0].format("yyyy-MM-DDTHH:mm:SS.000[Z]")
                            }
                            var endVariableVal = field.data.value[1] ?? "";
                            if (isMoment(endVariableVal)) {
                                endVariableVal = field.data.value[1].format("yyyy-MM-DDTHH:mm:SS.000[Z]")
                            }

                            dataJSON[startVariable.name] = startVariableVal;
                            dataJSON[endVariable.name] = endVariableVal;
                        } else { // 1 DateTime field
                            dataJSON[field.data.id] = field.data.value ?? '';
                        }
                        break;

                    default:
                        dataJSON[field.data.id] = field.data.value ?? '';
                        break;
                }
            });
        });
        return dataJSON;
    }

    // Find form field that made up of two/more CMS field
    findfieldVariableGroupWithinFormArray(array: formObjectModel<FormFieldModel>[], fieldVariableGroupValue: string) {
        for (let data of array) {
            const foundObject = data.body.find(item => (item.data as any).name === fieldVariableGroupValue);
            if (foundObject) {
                return foundObject;
            }
        }
        return undefined;
    }

    // Show/Hide field based on trigger and triggerVal
    setShowOrHideField(trigger: string, value: string, firstFormArray: formObjectModel<FormFieldModel>[], secondFormArray: formObjectModel<FormFieldModel>[]) {
        const mainHasTriggeredBy = firstFormArray.filter((section: any) => section.containTriggerBy.includes(trigger.toUpperCase()));
        const sidePanelHasTriggeredBy = secondFormArray.filter((section: any) => section.containTriggerBy.includes(trigger.toUpperCase()));

        // Main Form
        if (mainHasTriggeredBy.length > 0) {
            mainHasTriggeredBy.forEach((section: any) => {
                // Only affect declared trigger section
                if (section.showSection !== undefined) {
                    // Config header
                    if (section.header[0] && section.header[0].triggerBy === trigger.toUpperCase()) {
                        section.header[0].data.title = `${value} Section`;
                        section.header[0].data.subtitle = `Set up ${value} section`;
                        section.header[0].showField = true;
                        section.showSection = true;
                    } else {
                        section.header[0].showField = false;
                        section.showSection = false;
                    }

                    // Hide section if no body field
                    const hasMatchedField = section.body.filter((field: any) => field.triggerVal.includes(value.toUpperCase()));
                    section.showSection = hasMatchedField.length > 0;
                }

                // Config body
                section.body.forEach((field: any) => {
                    // Check field triggerBy is undefined or not matched
                    if (!field.triggerBy || field.triggerBy !== trigger.toUpperCase()) {
                        return;
                    }

                    // Check field triggerVal (match the value)
                    if (field.triggerVal.includes(value.toUpperCase())) {
                        field.showField = true;
                    } else {
                        field.showField = false;
                    }
                });
            })
        }

        // Side Panel
        if (sidePanelHasTriggeredBy.length > 0) {
            sidePanelHasTriggeredBy.forEach((section: any) => {
                // Config body
                section.body.forEach((field: any) => {
                    // Check field triggerBy is undefined or not matched
                    if (!field.triggerBy || field.triggerBy !== trigger.toUpperCase()) {
                        return;
                    }

                    // Check field triggerVal
                    if (field.triggerVal.includes(value.toUpperCase())) {
                        field.showField = true;
                    } else {
                        field.showField = false;
                    }
                });
            })
        }

        return { firstFormArray: firstFormArray, secondFormArray: secondFormArray }
    }
}