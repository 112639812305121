// @ts-ignore
import readingTime from '../../../../../node_modules/reading-time/lib/reading-time';

import { Component, ElementRef, EventEmitter, Input, OnInit, Output, SecurityContext, TemplateRef, ViewChild } from '@angular/core';
import { FormModule, ButtonModule, GridModule } from '@coreui/angular';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { NgxSummernoteModule } from 'ngx-summernote';
import { CommonModule } from '@angular/common';
import { FormFieldModel, InputTextEditorModel } from 'src/app/_models/form-field.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { franc } from 'franc';
import { ErrorService } from 'src/app/_services/error.service';
import DOMPurify from 'dompurify';

declare var $: any;
@Component({
  selector: 'app-text-editor',
  standalone: true,
  imports: [FormModule, ButtonModule, GridModule, ReactiveFormsModule, NgxSummernoteModule, CommonModule],
  templateUrl: './text-editor.component.html',
  styleUrl: './text-editor.component.scss'
})
export class TextEditorComponent implements OnInit {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: { content: string, duration: number } }>();
  @ViewChild('summernote', { static: false }) summernote!: ElementRef;
  @ViewChild('modalContent', { static: false }) modalContent!: TemplateRef<any>; summernoteForm = new FormGroup({
    html: new FormControl(),
  })
  data!: InputTextEditorModel;
  summernotePrevVal: string = '';
  text!: string;
  language: string = '';
  totalReadTime: number = 0;

  // Summernote config
  currentContext!: any;
  fontFamilyRegex: RegExp = /font-family\s*:\s*(?:[^;&]+|&[^;]+;)*;/gi;
  noWrapFormControl: FormControl = new FormControl('');
  textEditorConfig = {
    placeholder: 'Enter here',
    tabsize: 2,
    height: 300,
    // uploadImagePath: '/api/upload',
    toolbar: [
      ['misc', ['undo', 'redo', 'codeview']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['font', ['bold', 'italic', 'underline', 'strikethrough', 'superscript', 'subscript', 'clear']],
      ['fontsize', ['fontsize', 'forecolor', 'backcolor']],
      ['insert', ['table', 'picture', 'link', 'video', 'hr']],
      ['customButtons', ['noWrapBtn']],
    ],
    buttons: {
      noWrapBtn: this.noWrapButtonGenerator.bind(this),
    },
    disableDragAndDrop: true,
    tabDisable: true,
    styleWithSpan: true,
    callbacks: {
      onInit: () => {
        const style = document.createElement('style');
        style.innerHTML = `
          .text-nowrap {
            background: yellow;
          }

          .icon-container {
            background-image: url('src/app/icons/icon-overflow.ts');
            width: 24px;
            height: 24px;
            background:red;
          }
        `;
        document.head.appendChild(style);
      },
      onPaste: (e: any) => {
        e.preventDefault();
        const clipboardData = e.originalEvent.clipboardData || (window as any).originalEvent.clipboardData;
        let pastedText = clipboardData.getData('text/html') || clipboardData.getData('text/plain');
        let pastedFiles = clipboardData.files;
        let pastedItems = clipboardData.items;

        // Detect image copypaste
        if ((pastedFiles.length > 0 && pastedFiles[0].type.includes('image')) || (pastedItems.length > 0 && pastedItems[0].type.includes('image'))) {
          this.errorService.openGenericErrorModal('Please insert image using upload feature.');
          this.summernotePrevVal = $(this.summernote.nativeElement).summernote('code');
          return;
        }

        // font-family sanitisation
        if (pastedText) {
          let sanitizedVal = pastedText.replaceAll(this.fontFamilyRegex, '').replaceAll("\n", '');
          sanitizedVal = DOMPurify.sanitize(sanitizedVal,
            {
              ALLOW_DATA_ATTR: false,
              ALLOW_ARIA_ATTR: false,
              FORBID_ATTR: ['style', 'id', 'class'],
            }
          );
          $(this.summernote.nativeElement).summernote('pasteHTML', sanitizedVal);
        }
      },

      onChange: (e: any) => {
        this.data.error = [];

        // Remove pasted image
        if (e.includes('data:image/')) {
          $(this.summernote.nativeElement).summernote('code', this.summernotePrevVal);
          return;
        }
        this.summernotePrevVal = e;

        // Strip HTML tags
        const sanitizedVal = e.match(/(?<=^|>)(?:(?!&nbsp;)[^><]+?)(?=<|$)/g);
        this.text = sanitizedVal ? sanitizedVal.join(' ') : '';

        // Empty input
        if (this.text.length === 0) {
          this.summernotePrevVal = '';
          this.language = '';
          this.totalReadTime = 0;
          return;
        }

        // Detect language (Only first 150 characters)
        if (this.text.length <= 150 || !this.language) {
          this.language = franc(this.text);
        }

        // (Thai) reading-time can't detect individual word, so need to do it manually.
        if (this.language.toUpperCase() === 'THA') {
          this.totalReadTime = Math.ceil(this.text.length / 200 * 60); // 200 wpm to seconds.
          return;
        }

        // (Others)
        if (this.languageWPMCounter(this.language) !== -1) {
          const readTimeCalc = readingTime(this.text, { wordsPerMinute: this.languageWPMCounter(this.language) });
          this.totalReadTime = Math.ceil((Number.parseFloat(readTimeCalc.time) / 1000));
          // console.log(readTimeCalc);
        } else {
          this.totalReadTime = 0;
        }

        this.setNewValue(e);
      },
      onKeydown: (e: any) => {
        if (e.shiftKey && e.key === 'Enter') {
          e.preventDefault();

          const $summernote = $(this.summernote.nativeElement);
          $summernote.summernote('saveRange');
          $summernote.summernote('pasteHTML', '<br>');
          $summernote.summernote('restoreRange');
        }
      }
    }
  }

  setNewValue(rawHTMLData: string) {
    this.data.value = { content: rawHTMLData, duration: this.totalReadTime };
    this.onChange.emit({ formControlName: this.data.id, value: { content: rawHTMLData, duration: this.totalReadTime } });
  }

  constructor(
    private modalService: NgbModal,
    private errorService: ErrorService
  ) { }

  ngOnInit(): void {
    this.data = this.config as InputTextEditorModel;
    this.summernoteForm.controls['html'].setValue(this.data.value?.content);

    // Listen to custom button trigger
    window.addEventListener('message', (event: MessageEvent) => {
      if (event.data.type === 'noWrapButton') {
        this.openModal();
      }
    })
  }

  close() {
    this.modalService.dismissAll();
  }

  confirm() {
    const userText = this.noWrapFormControl.value;
    const underlinedText = `<span class="text-nowrap">${userText}</span>`;

    if (this.currentContext) {
      this.currentContext.invoke('editor.restoreRange'); // Goto saved typing head position
      this.currentContext.invoke('editor.pasteHTML', underlinedText);
      this.currentContext.invoke('editor.pasteHTML', '&nbsp;');
    }
    this.noWrapFormControl.setValue('');
    this.close();
  }

  languageWPMCounter(languageCode: string): number {
    var wpm: number = -1;
    switch (languageCode.toUpperCase()) {
      case 'CMN': // Chinese Mandarin
        wpm = 200;
        break;
      case 'ENG': // English
        wpm = 250;
        break;
      case 'JPN': // Japanese
        wpm = 150;
        break;
      case 'KOR': // Korean
        wpm = 250;
        break;
      case 'VIE': // Vietnamese
        wpm = 200;
        break;
      case 'IND': // Bahasa Indonesia
      case 'ZLM': // Malay (similar)
        wpm = 200;
        break;
      default:
        break;
    }
    return wpm;
  }

  /* ========================== NO WRAP BUTTON ========================== */
  noWrapButtonGenerator(context: any) {
    const ui = $.summernote.ui;
    const button = ui.button({
      contents: '<i class="note-icon-pencil"></i> No-Wrap Text',
      container: '.note-editor',
      className: 'note-btn',
      click: () => {
        context.invoke('editor.saveRange') // Save current typing head position
        this.currentContext = context; // Summernote context
        window.postMessage({ type: 'noWrapButton', data: true });
      },
    });
    return button.render();
  };

  openModal() {
    this.modalService.open(this.modalContent, { centered: true });
    document.getElementById('textInput')!.focus();
  }

  /* ==================================================================== */

}
