import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { ToastrService } from 'ngx-toastr';
import { SelectMenuInputComponent, RadioInputComponent } from "../../forms";

@Component({
  selector: 'app-add-language-modal',
  standalone: true,
  templateUrl: './add-language-modal.component.html',
  styleUrl: './add-language-modal.component.scss',
  imports: [ModalModule, ButtonModule, FormModule, SelectMenuInputComponent, RadioInputComponent]
})
export class AddLanguageModalComponent implements OnInit {
  @Input() modal: any;
  @Input() languageOptions: any;
  @Input() currentLanguage!: string;
  @Output() onConfirm = new EventEmitter<{ newLanguageId: string, prefill: boolean }>();

  inputLanguageConfig = {
    id: 'language-input',
    name: 'language-input',
    label: 'Language',
    value: '',
    disabled: false,
    error_message: '',
    options: [
      {
        value: '1',
        label: 'English',
        disabled: false,
        selected: false
      },
      {
        value: '4852764',
        label: 'Traditional Chinese',
        disabled: false,
        selected: false
      },
      {
        value: '5017431',
        label: 'Japanese',
        disabled: false,
        selected: false
      },
      {
        value: '5028697',
        label: 'Korean',
        disabled: false,
        selected: false
      },
      {
        value: '4561158',
        label: 'Indonesian',
        disabled: false,
        selected: false
      },
      {
        value: '5146990',
        label: 'Vietnamese',
        disabled: false,
        selected: false
      },
      {
        value: '4938438',
        label: 'Thai',
        disabled: false,
        selected: false
      }
    ]
  };

  inputOptionsConfig = {
    name: 'options-input',
    label: 'Options',
    inline_options: false,
    disabled: false,
    error_message: '',
    value: '',
    options: [
      { id: 'option-copy-content', label: `Copy contents from`, checked: true },
      { id: 'option-blank', label: 'Create a blank content' }
    ]
  };

  constructor(
    private router: Router,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.inputLanguageConfig.options = this.languageOptions;
    this.inputOptionsConfig.options[0].label = `Copy contents from ${this.currentLanguage} version`;
    this.inputOptionsConfig.value = `Copy contents from ${this.currentLanguage} version`; // set default value
  }

  dismissModal() {
    this.modal.dismiss();
  }

  confirmAddLanguage() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onConfirm.emit({ newLanguageId: this.inputLanguageConfig.value, prefill: this.inputOptionsConfig.value === this.inputOptionsConfig.options[0].label });
    });
  }
}
