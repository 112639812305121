import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { FormsModule } from '@angular/forms';
import { TextInputComponent } from "../../forms";

@Component({
  selector: 'app-partner-reset-password-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, FormModule, FormsModule, TextInputComponent],
  templateUrl: './partner-reset-password-modal.component.html',
  styleUrl: './partner-reset-password-modal.component.scss'
})
export class PartnerResetPasswordModalComponent implements OnInit {
  @Input() modal: any;
  @Input() email!: string;
  @Output() onSubmit = new EventEmitter<void>();

  inputEmailConfig = {
    id: 'email-input',
    name: 'email-input',
    label: 'Email',
    placeholder: '',
    disabled: true,
    maxLength: 100,
    value: ''
  };

  ngOnInit(): void {
    this.inputEmailConfig.value = this.email;
  }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.onSubmit.emit();
    });
  }
}
