import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { LineDividerComponent } from "../line-divider/line-divider.component";
import { TextInputComponent } from "../text-input/text-input.component";
import { TextareaInputComponent } from "../textarea-input/textarea-input.component";
import { RadioInputComponent } from "../radio-input/radio-input.component";
import { ImageInputComponent } from "../image-input/image-input.component";
import { CheckboxInputComponent } from "../checkbox-input/checkbox-input.component";
import { SelectMenuInputComponent } from "../select-menu-input/select-menu-input.component";
import { DatetimerangepickerInputComponent } from "../datetimerangepicker-input/datetimerangepicker-input.component";
import { ContentSelectorComponent } from "../content-selector/content-selector.component";
import { PersonSelectorComponent } from "../person-selector/person-selector.component";
import { SingleContentSelectorComponent } from "../single-content-selector/single-content-selector.component";
import { DocumentSelectorComponent } from "../document-selector/document-selector.component";
import { FormFieldModel, InputRelationshipModel, formObjectModel } from 'src/app/_models/form-field.model';
import { SelectMenuWithSearchInputComponent } from '../select-menu-with-search-input/select-menu-with-search-input.component';
import { TextEditorComponent } from '../text-editor/text-editor.component';
import { TextInputWithButtonComponent } from '../text-input-with-button/text-input-with-button.component';
import { FormsModule } from '@angular/forms';
import { VideoInputComponent } from '../video-input/video-input.component';

@Component({
  selector: 'app-form',
  standalone: true,
  templateUrl: './form.component.html',
  styleUrl: './form.component.scss',
  imports: [
    CommonModule,
    LineDividerComponent,
    TextInputComponent,
    TextareaInputComponent,
    RadioInputComponent,
    ImageInputComponent,
    CheckboxInputComponent,
    SelectMenuInputComponent,
    DatetimerangepickerInputComponent,
    ContentSelectorComponent,
    PersonSelectorComponent,
    SingleContentSelectorComponent,
    DocumentSelectorComponent,
    SelectMenuWithSearchInputComponent,
    TextEditorComponent,
    TextInputWithButtonComponent,
    VideoInputComponent,
    FormsModule
  ]
})

export class FormComponent {
  @Input() data!: formObjectModel<FormFieldModel>[];
  @Output() onSubmit = new EventEmitter<any>();
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string | Array<Date | string> | InputRelationshipModel["selected_data"] | { url: string, duration: number } | { content: string, duration: number } }>();

  getFormField(array: formObjectModel<FormFieldModel>[], controlName: string) {
    for (let data of array) {
      const foundObject = data.body.find(item => (item.data as any).id === controlName);
      if (foundObject) {
        return foundObject;
      }
    }
    return undefined;
  }

  setFormValue(data: { formControlName: string, value: string | Array<Date | string> | InputRelationshipModel["selected_data"] | { url: string, duration: number } | { content: string, duration: number } }) {
    this.onChange.emit({ formControlName: data.formControlName, value: data.value });
  }
}
