import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { FormFieldModel, InputCheckboxModel } from 'src/app/_models/form-field.model';

@Component({
  selector: 'app-checkbox-input',
  standalone: true,
  imports: [FormModule, CommonModule, FormsModule],
  templateUrl: './checkbox-input.component.html',
  styleUrl: './checkbox-input.component.scss'
})
export class CheckboxInputComponent implements OnInit, OnChanges {
  @Input() config!: FormFieldModel
  @Output() onChange = new EventEmitter<{ formControlName: string, value: string }>();
  data!: InputCheckboxModel;
  selectAll: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['config'].currentValue.options.every((option: any) => option.checked)) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
  }

  ngOnInit(): void {
    this.data = this.config as InputCheckboxModel;
    setTimeout(() => {
      const labelArr: string[] = this.data.value.split(',');
      this.data.options.forEach(option => {
        if (labelArr.includes(option.id)) {
          option.checked = true;
        } else {
          option.checked = false;
        }
      });

      if (this.data.options.length === labelArr.length) {
        this.selectAll = true;
      }
    }, 500);
  }

  toggleAll(): void {
    this.data.options.forEach(option => { option.checked = this.selectAll });
    this.onChange.emit({ formControlName: this.data.id, value: this.getAllCheckedValue() });
  }

  updateSelect(): void {
    this.selectAll = this.data.options.every(option => option.checked);
    this.onChange.emit({ formControlName: this.data.id, value: this.getAllCheckedValue() });
  }

  getAllCheckedValue() {
    const val: string[] = [];
    this.data.options.forEach(option => {
      if (option.checked) {
        val.push(option.id);
      }
    });
    this.data.value = val.join(',');
    return val.join(',');
  }
}

