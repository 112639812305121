import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule } from '@coreui/angular';
import { CommonModule } from '@angular/common';
import { IconModule } from '@coreui/icons-angular';
import { DragDropModule, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FullscreenSpinnerComponent } from '..';

interface selectedContent {
  id: string;
  name: string;
  active_period: string;
  img_url?: string;
}

@Component({
  selector: 'app-manage-content-order-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, IconModule, DragDropModule],
  templateUrl: './manage-content-order-modal.component.html',
  styleUrl: './manage-content-order-modal.component.scss'
})
export class ManageContentOrderModalComponent {
  @Input() modal: any;
  @Input()
  config!: {
    content_name?: string;
    selected_data: selectedContent[];
    disabled?: boolean;
  };

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  submit() {
    this.modal.dismiss();
    this.modal.hidden.subscribe(() => {
      this.openFullscreenSpinnerModal('MANAGE_CONTENT_ORDER');
    });
  }

  contentListCardDrop(event: CdkDragDrop<selectedContent[]>) {
    if (this.config.selected_data) {
      moveItemInArray(this.config.selected_data, event.previousIndex, event.currentIndex);
    }
  }

  openFullscreenSpinnerModal(event_session: string) {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = event_session;
  }
}
