<div class="image-input" [class.cover-image]="data.type === 'COVER_IMAGE'" [class.icon-image]="data.type === 'ICON'"
  [class.fullscreen-banner-image-mobile]="data.type === 'FULLSCREEN_BANNER_IMAGE_MOBILE'"
  [class.fullscreen-banner-image-large]="data.type === 'FULLSCREEN_BANNER_IMAGE_LARGE'"
  [class.banner-image-large]="data.type === 'BANNER_IMAGE_LARGE'"
  [class.banner-image-large-2]="data.type === 'BANNER_IMAGE_LARGE_2'"
  [class.banner-image-mobile]="data.type === 'BANNER_IMAGE_MOBILE'"
  [class.banner-image-mobile-ver]="data.type === 'BANNER_IMAGE_MOBILE_VER'"
  [class.modal-image-mobile]="data.type === 'MODAL_IMAGE_MOBILE'"
  [class.modal-image-large]="data.type === 'MODAL_IMAGE_LARGE'" [class.profile-image]="data.type === 'PROFILE_IMAGE'"
  [class.skyscrapper-image]="data.type === 'SKYSCRAPPER_IMAGE'" [class.flash-card]="data.type === 'FLASH_CARD'">
  <label cLabel [for]="data.id" class="mb-4">
    {{data.label}}<span class="text-danger" *ngIf="data.required">*</span>
    <div *ngIf="data.sublabel" class="form-sublabel">
      {{data.sublabel}}
    </div>
  </label>

  <c-row>
    <c-col lg="6">
      <figure class="image-preview rounded-1 mb-lg-0">
        <button type="button" *ngIf="!data.value.url" class="image-placeholder" (click)="fileInput.click()"
          [disabled]="data.disabled">
          <svg cIcon name="cilImagePlus" size="3xl"></svg>
        </button>
        <div *ngIf="data.value.url" class="selected-image">
          <img [src]="data.value.url" class="selected-image">
        </div>

        <button cButton color="danger" class="btn-circle btn-remove-image"
          *ngIf="data.value.url && data.disabled === false" (click)="onRemoveButtonClick()">
          <svg cIcon name="cilX"></svg>
        </button>
      </figure>
    </c-col>

    <c-col lg="6">
      <div class="h-100 d-flex flex-column justify-content-center">
        <div class="fs-small mb-3 gray-700">
          Format: <strong>JPG, PNG, WEBP</strong><br>
          File size: <strong>Max. 2MB</strong><br>

          <ng-container *ngIf="data.type === 'FLASH_CARD'">
            Aspect ratio: <strong>1 / 2</strong><br>
            Resolution: <strong>Min. 400 x 800px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'FULLSCREEN_BANNER_IMAGE_LARGE'">
            Aspect ratio: <strong>2 / 1</strong><br>
            Resolution: <strong>Min. 2,000 x 1,000px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'FULLSCREEN_BANNER_IMAGE_MOBILE'">
            Aspect ratio: <strong>3 / 3.25</strong><br>
            Resolution: <strong>Min. 900 x 975px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'BANNER_IMAGE_MOBILE'">
            Aspect ratio: <strong>4 / 3</strong><br>
            Resolution: <strong>Min. 800 x 600px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'BANNER_IMAGE_MOBILE_VER'">
            Aspect ratio: <strong>3 / 4</strong><br>
            Resolution: <strong>Min. 600 x 800px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'BANNER_IMAGE_LARGE'">
            Aspect ratio: <strong>8 / 3</strong><br>
            Resolution: <strong>Min. 1600 x 600px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'BANNER_IMAGE_LARGE_2'">
            Aspect ratio: <strong>16 / 7</strong><br>
            Resolution: <strong>Min. 1600 x 700px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'COVER_IMAGE'">
            Aspect ratio: <strong>16 / 9</strong><br>
            Resolution: <strong>Min. 1,200 x 675px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'MODAL_IMAGE_MOBILE'">
            Aspect ratio: <strong>16 / 9</strong><br>
            Resolution: <strong>Min. 1,200 x 675px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'MODAL_IMAGE_LARGE'">
            Aspect ratio: <strong>6 / 7</strong><br>
            Resolution: <strong>Min. 600 x 700px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'ICON'">
            Aspect ratio: <strong>1 / 1</strong><br>
            Resolution: <strong>Min. 600 x 600px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'PROFILE_IMAGE'">
            Aspect ratio: <strong>1 / 1</strong><br>
            Resolution: <strong>Min. 800 x 800px</strong><br>
          </ng-container>

          <ng-container *ngIf="data.type === 'SKYSCRAPPER_IMAGE'">
            Aspect ratio: <strong>5 / 1</strong><br>
            Resolution: <strong>Min. 1600 x 320px</strong><br>
          </ng-container>

          <ng-container *ngIf="!data.type">
            Aspect ratio: <strong>1 / 1</strong><br>
            Resolution: <strong>Min. 800 x 800px</strong><br>
          </ng-container>

        </div>
        <div>
          <input [id]="data.id" type="file" #fileInput hidden (change)="data.error = []; onFileSelected($event)"
            accept="image/jpeg,image/png,image/webp" [disabled]="data.disabled">
          <button *ngIf="data.disabled === false" cButton color="primary" size="sm" class="btn-select-image me-2 mb-2"
            (click)="data.error = []; openSelectAssetModal()">
            Select image
          </button>
          <button *ngIf="data.disabled === false" cButton variant="outline" size="sm"
            class="btn-file-upload-toggle mb-2" (click)="fileInput.click()">
            Upload image
          </button>
        </div>
        <div *ngIf="data.error && data.error.includes('required')" class="form-error mt-2">Please select/upload an
          image.</div>
      </div>

    </c-col>
  </c-row>
</div>