<c-modal-body>
  <div class="d-flex align-items-center justify-content-center w-100 h-100">
    <figure class="mb-0">
      <svg width="100%" height="100%" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <g>
          <circle cx="12" cy="2.5" r="1.5" opacity=".14" />
          <circle cx="16.75" cy="3.77" r="1.5" opacity=".29" />
          <circle cx="20.23" cy="7.25" r="1.5" opacity=".43" />
          <circle cx="21.50" cy="12.00" r="1.5" opacity=".57" />
          <circle cx="20.23" cy="16.75" r="1.5" opacity=".71" />
          <circle cx="16.75" cy="20.23" r="1.5" opacity=".86" />
          <circle cx="12" cy="21.5" r="1.5" />
          <animateTransform attributeName="transform" type="rotate" calcMode="discrete" dur="0.75s"
            values="0 12 12;30 12 12;60 12 12;90 12 12;120 12 12;150 12 12;180 12 12;210 12 12;240 12 12;270 12 12;300 12 12;330 12 12;360 12 12"
            repeatCount="indefinite" />
        </g>
      </svg>
    </figure>
  </div>
</c-modal-body>