import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FormModule } from '@coreui/angular';
import { Subject, debounceTime } from 'rxjs';
import { FormFieldModel, InputVideoModel } from 'src/app/_models/form-field.model';
import { ContentService } from 'src/app/_services/content.service';

@Component({
  selector: 'app-video-input',
  standalone: true,
  imports: [FormsModule, FormModule, CommonModule],
  templateUrl: './video-input.component.html',
  styleUrl: './video-input.component.scss'
})
export class VideoInputComponent {
  @Input() config!: FormFieldModel;
  @Output() onChange = new EventEmitter<{ formControlName: string, value: { url: string, duration: number } }>();
  data!: InputVideoModel;
  modelChanged = new Subject<void>(); // debounce

  // Youtube
  videoUrl!: SafeResourceUrl;
  videoDuration!: number;
  player!: any;
  setPlayerInterval!: any;

  constructor(
    private sanitizer: DomSanitizer,
    private contentService: ContentService
  ) {
    this.modelChanged.pipe(debounceTime(300)).subscribe(() => {
      this.setNewValue(this.data.value.url);
    });
  }

  ngOnInit(): void {
    this.data = this.config as InputVideoModel;
    // this.videoUrl = this.data.value.url;
    this.sanitizeVideoUrl();
    this.videoDuration = this.data.value.duration
  }

  change() {
    this.modelChanged.next();
  }

  loadYouTubeAPI() {
    (window as any).onYouTubeIframeAPIReady = this.onYouTubeIframeAPIReady();
  }

  onYouTubeIframeAPIReady() {
    this.setPlayerInterval = setInterval(() => {
      this.player = new (window as any).YT.Player('youtube-iframe', {
        events: {
          'onReady': (e: any) => this.onPlayerReady(e),
          'onError': (e: any) => this.onChange.emit({ formControlName: this.data.id, value: { url: "", duration: 0 } })
        }
      });
      if (this.player) {
        clearInterval(this.setPlayerInterval);
      }
    }, 1000)
  }

  onPlayerReady(event: any) {
    clearInterval(this.setPlayerInterval);
    this.videoDuration = event.target.getDuration();
    this.data.value.duration = this.videoDuration;
    this.onChange.emit({ formControlName: this.data.id, value: { url: this.data.value.url, duration: this.videoDuration } });
  }

  sanitizeVideoUrl() {
    const vidUrl = this.contentService.formatVideoUrl(this.data.value.url) + '?enablejsapi=1';
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(vidUrl);
  }

  setNewValue(value: string) {
    if (this.contentService.formatVideoUrl(value)) {
      this.sanitizeVideoUrl();
      this.loadYouTubeAPI();
    } else {
      this.data.value.duration = 0;
      this.onChange.emit({ formControlName: this.data.id, value: { url: "", duration: 0 } });
    }
  }
}
