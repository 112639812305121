import { Component, Input } from '@angular/core';
import { ModalModule, ButtonModule, FormModule } from '@coreui/angular';
import { CreatePersonModalComponent, FullscreenSpinnerComponent, SelectContentModalComponent } from '../';
import { CommonModule } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TextInputComponent, TextareaInputComponent, ImageInputComponent, PersonSelectorComponent, DatetimerangepickerInputComponent } from "../../../components/forms";

@Component({
  selector: 'app-create-event-video-modal',
  standalone: true,
  imports: [ModalModule, ButtonModule, CommonModule, TextInputComponent, TextareaInputComponent, ImageInputComponent, PersonSelectorComponent, DatetimerangepickerInputComponent, FormModule],
  templateUrl: './create-event-video-modal.component.html',
  styleUrl: './create-event-video-modal.component.scss'
})
export class CreateEventVideoModalComponent {
  @Input() modal: any;
  @Input()
  event_session!: string;

  // Form input - Content ID
  inputContentIDConfig = {
    id: 'content-id-input',
    name: 'content-id-input',
    label: 'Content ID',
    sublabel: 'Create a unique ID for this content. Max. 50 characters. E.g. coffee-stories-championship-day-1',
    placeholder: 'Enter here',
    max_length: 150,
    value: 'learning-lesson-xxx',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputPublishPeriodConfig = {
    id: 'publish-period-input',
    name: 'publish-period-input',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Date time
  inputDateTimeConfig = {
    id: 'date-time-input',
    name: 'date-time-input',
    label: 'Date & time',
    sublabel: 'Set up the event date period',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Title
  inputTitleConfig = {
    id: 'title-input',
    name: 'title-input',
    label: 'Title',
    sublabel: 'Title of the content. Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Top subtitle
  inputTopSubtitleConfig = {
    id: 'top-subtitle-input',
    name: 'top-subtitle-input',
    label: 'Top subtitle',
    sublabel: 'Top subtitle, before main title/ heading Max. 100 character',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Video URL
  inputVideoURLConfig = {
    id: 'video-url-input',
    name: 'video-url-input',
    label: 'Video URL',
    sublabel: 'Link to the video',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Description
  inputDescriptionConfig = {
    id: 'description-input',
    name: 'description-input',
    label: 'Description',
    sublabel: 'A short paragraph as the introduction. Max 150 characters',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Tags
  inputTagsConfig = {
    id: 'tags-input',
    name: 'tags-input',
    label: 'Tags',
    sublabel: 'Add tagging for the content, may enter multiple. Separate each tag with comma. E.g. Coffee, Cold Brew, Frappuccino',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Bookmark title
  inputBookmarkTitleConfig = {
    id: 'bookmark-title-input',
    name: 'bookmark-title-input',
    label: 'Bookmark title (optional)',
    sublabel: 'Title to display on bookmark. Default to content title. Max. 100 characters.',
    placeholder: 'Enter here',
    max_length: 100,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Cover image
  inputCoverImageConfig = {
    type: 'COVER_IMAGE',
    id: 'cover-image-input',
    name: 'cover-image-input',
    label: 'Cover image',
    sublabel: 'Image displayed in the listing page',
    value: '',
    disabled: false
  };

  // Form input - Bookmart description
  inputBookmarkDescriptionConfig = {
    id: 'bookmark-description-input',
    name: 'bookmark-description-input',
    label: 'Bookmark description (optional)',
    sublabel: 'Description to display on bookmark. Default to content description. Max. 150 characters.',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - Cover image
  inputBookmarkCoverImageConfig = {
    type: 'COVER_IMAGE',
    id: 'bookmark-cover-image-input',
    name: 'bookmark-cover-image-input',
    label: 'Bookmark cover image (optional)',
    sublabel: 'Cover image to display on bookmark. Default to content cover image.',
    value: '',
    disabled: false
  };

  selectedPartners = [
    {
      id: 'jon-appleseed-hk',
      name: 'Jon Appleseed'
    },
    {
      id: 'john-doe-hk',
      name: 'John Doe Jr'
    },
    {
      id: 'selina-teoh-my',
      name: 'Selina Teoh'
    }
  ];

  // Form add host
  inputAddPartnersConfig = {
    id: 'add-host-input',
    label: 'Host / Speaker',
    sublabel: 'Add host / speaker from the list, or create a new host / speaker',
    disabled: false,
    error_message: '',
    select_button_label: 'Select host / speaker',
    create_button_label: 'Create host / speaker',
    selected_data: this.selectedPartners
  };

  // Form input - Video duration
  inputVideoDurationConfig = {
    id: 'video-duration-input',
    name: 'video-duration-input',
    label: 'Video duration (seconds)',
    sublabel: 'Enter the duration of the video.',
    placeholder: 'Enter here',
    value: '',
    disabled: false,
    error_message: ''
  };

  // Form input - cover description
  inputCoverDescriptionConfig = {
    id: 'cover-description-input',
    name: 'cover-description-input',
    label: 'Cover description (optional)',
    sublabel: 'A short paragraph as the introduction in the listing page. Max 150 characters',
    placeholder: 'Enter here',
    max_length: 150,
    value: '',
    disabled: false,
    error_message: ''
  };

  constructor(
    private modalService: NgbModal
  ) { }

  dismissModal() {
    this.modal.dismiss();
  }

  selectPersonClick() {
    this.openSelectContentModal('SELECT_CONTENT');
  }

  createPersonClick() {
    this.openCreatePersonModal('CREATE_EVENT_VIDEO');
  }

  openFullscreenSpinnerModal() {
    const modalRef = this.modalService.open(FullscreenSpinnerComponent, {
      centered: true,
      windowClass: 'fullscreen-spinner-modal',
      scrollable: true,
      fullscreen: true,
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.spinner_session = this.event_session;
  }

  openCreatePersonModal(event_session: string) {
    const modalRef = this.modalService.open(CreatePersonModalComponent, {
      centered: true,
      windowClass: 'create-content-modal',
      modalDialogClass: 'modal-fullscreen-md-down',
      scrollable: true,
      backdrop: 'static',
      keyboard: false,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
  }

  openSelectContentModal(event_session: string) {
    const modalRef = this.modalService.open(SelectContentModalComponent, {
      centered: true,
      windowClass: 'select-content-modal',
      scrollable: true,
      size: 'lg'
    });
    modalRef.componentInstance.modal = modalRef;
    modalRef.componentInstance.event_session = event_session;
  }
}
