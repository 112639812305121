import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ModalModule, ButtonModule, CardModule } from '@coreui/angular';
import { TextInputComponent } from "../../../forms";
import { fadeInOut } from '../../../../animations';

@Component({
  selector: 'app-upload-image-preview',
  standalone: true,
  imports: [ModalModule, ButtonModule, CardModule, TextInputComponent],
  templateUrl: './upload-image-preview.component.html',
  styleUrl: './upload-image-preview.component.scss',
  animations: [fadeInOut]
})
export class UploadImagePreviewComponent {
  @Input() modal: any;
  @Input() imageToBeUploaded!: string;
  @Input() imageFilename!: string;

  @Output() dismissModalClick = new EventEmitter<void>();
  @Output() goToProcessImageClick = new EventEmitter<void>();

  constructor() { }

  inputTagsConfig = {
    id: 'tags-input',
    name: 'tags-input',
    placeholder: 'Add tags, separate each tag with comma. E.g. Coffee, Cold Brew',
    maxLength: 100,
    value: ''
  };

  dismissModal() {
    this.dismissModalClick.emit();
  }

  goToProcessImage() {
    this.goToProcessImageClick.emit();
  }
}
